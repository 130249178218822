import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AppRoutes } from './shared/app.routes ';
import { AuthDrawGuard } from './core/guards/auth-draw/auth.draw.guard';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { NgModule } from '@angular/core';
import { NotAlreadyLoggedInGuard } from './core/guards/non-auth/not-already-logged-in.guard';

const routes: Routes = [
  { path: '', redirectTo: AppRoutes.STARTUP, pathMatch: 'full' },
  {
    path: AppRoutes.STARTUP,
    loadChildren: () =>
      import('./initial-startup/initial-startup.module').then(
        (m) => m.InitialStartupModule
      ),
    canActivate: [NotAlreadyLoggedInGuard],
  },
  {
    path: AppRoutes.IMAGE_TOOL,
    loadChildren: () =>
      import('./image-tool/image-tool.module').then(
        (m) => m.ImageToolPageModule
      ),
    canActivate: [AuthDrawGuard],
  },
  {
    path: AppRoutes.PLAYER_TOOL,
    loadChildren: () =>
      import('./player-tool/player-tool.module').then(
        (m) => m.PlayerToolPageModule
      )
  },
  {
    path: AppRoutes.MY_FOLDERS,
    loadChildren: () =>
      import('./my-folders/my-folders.module').then(
        (m) => m.MyFoldersPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.SELECT_TACTIC_TEMPLATE,
    loadChildren: () =>
      import('./select-tactic-template/select-tactic-template.module').then(
        (m) => m.SelectTacticTemplatePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.LOGIN,
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
    canActivate: [NotAlreadyLoggedInGuard],
  },
  {
    path: AppRoutes.REGISTER,
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterPageModule),
    canActivate: [NotAlreadyLoggedInGuard],
  },
  {
    path: AppRoutes.CANCEL_SAVE,
    loadChildren: () =>
      import('./cancel-save/cancel-save.module').then(
        (m) => m.CancelSavePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.FORGOTTEN_PASSWORD,
    loadChildren: () =>
      import('./forgotten-password/forgotten-password.module').then(
        (m) => m.ForgottenPasswordPageModule
      ),
    canActivate: [NotAlreadyLoggedInGuard],

  },
  {
    path: AppRoutes.TERMS,
    loadChildren: () =>
      import('./terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUsePageModule
      ),
  },
  {
    path: AppRoutes.SETTINGS,
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.CHANGE_PASSWORD,
    loadChildren: () =>
      import('./change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.PREMIUM_REQUIRED,
    loadChildren: () =>
      import('./premium-required/premium-required.module').then(
        (m) => m.PremiumRequiredPageModule
      )
  },
  {
    path: AppRoutes.SPLASH_SCREEN,
    loadChildren: () =>
      import('./splash-screen/splash-screen.module').then(
        (m) => m.SplashScreenPageModule
      ),
  },
  {
    path: AppRoutes.SELECT_DESTINATION,
    loadChildren: () =>
      import('./select-destination/select-destination.module').then(
        (m) => m.SelectDestinationPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.MODAL_DEMO,
    loadChildren: () =>
      import('./modal-demo/modal-demo.module').then(
        (m) => m.ModalDemoPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.MOCK,
    loadChildren: () =>
      import('./mock/mock.module').then((m) => m.MockPageModule),
  },

  {
    path: AppRoutes.ANIMATION_TOOL,
    loadChildren: () =>
      import('./animation-tool/animation-tool.module').then(
        (m) => m.AnimationToolPageModule
      ),
    canActivate: [AuthDrawGuard],
  },
  {
    path: AppRoutes.LOGOUT,
    loadChildren: () => import('./logout/logout.module').then(m => m.LogoutPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.CREATE_FOLDER,
    loadChildren: () => import('./modal-operations/modal-operations.module').then(m => m.CreateFolderPageModule)
  },
  {
    path: AppRoutes.SELECT_FOLDER_TEMPLATE,
    loadChildren: () => import('./select-folder-template/select-folder-template.module').then(m => m.SelectFolderTemplatePageModule)
  },
  {
    path: AppRoutes.FORGOTTEN_PASSWORD_CONFIRM,
    loadChildren: () => import('./forgotten-password-confirm/forgotten-password-confirm.module').then(m => m.ForgottenPasswordConfirmPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
