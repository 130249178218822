import { Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { AdapterInterface } from '../../system/http-base/adapter.interface';

/**
 * Creates Adapter service for decoupling REST API objects with the internal entities
 * @class UserLoginAdapter
 */
@Injectable({
  providedIn: 'root',
})
export class UserLoginAdapter implements AdapterInterface<User> {
  /**
   * Creates a new User object with data comes from the REST API
   * @param {any} item
   * @param {function} [callback]
   * @returns {User}
   */
  adapt(item: any): User {
    const user = new User();
    user.userUid = item.user.user_uid;
    user.firstName = item.user.firstname;
    user.lastName = item.user.lastname;
    user.timeZone = item.user.timeZone;
    user.token = item.token;
    user.languageUid = item.user.language_uid;
    user.profileImageLink = item.user.profile_image_link;

    return user;
  }
}
