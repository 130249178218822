import { AdapterInterface } from '../../system/http-base/adapter.interface';
import { Folder } from '../model/folder.model';
import { Injectable } from '@angular/core';
import { TacticAdapter } from '../../tactic/adapters/tactic.adapter';

/**
 * Creates Adapter service for decoupling REST API objects with the internal entities
 * @class FolderAdapter
 */
@Injectable({
  providedIn: 'root',
})
export class FolderAdapter implements AdapterInterface<Folder> {

  constructor(private tacticAdapter: TacticAdapter) {

  }

  /**
   * Creates a new Folder object with data comes from the REST API
   * @param {any} item
   * @returns {Folder}
   */
  adapt(item: any): Folder {
    const folder = new Folder();
    folder.uid = item.uid;
    folder.created = item.created;
    folder.createdBy = item.created_by;
    folder.createdUtc = item.created_utc;
    folder.appUid = item.app_uid;
    folder.parentUid = item.parent_uid;
    folder.name = item.name;
    folder.deleted = item.deleted;
    folder.modifiedUtc = item.modified_utc;

    if (item.tactics) {
      folder.tactics = item.tactics.map((itemTactic) => this.tacticAdapter.adapt(itemTactic));
    }

    return folder;
  }
}
