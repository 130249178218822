import {
  calcAngle,
  getRandomId,
  getReferenceCircleGroupId,
  getReferenceGroupId
} from 'app/core/services/system/utilities/help.methods';

import { ArrowLineInterface } from 'app/core/drawing/models/arrow-line/arrow.line.interface';
import { ArrowLineThick } from 'app/core/drawing/models/arrow-line/arrow-line-thick/arrow.line.thick';
import { ArrowTypes } from 'app/core/drawing/models/arrow-types/arrowtypes';
import { CanvasEventTypes } from 'app/core/drawing/services/fabric/events/model/canvas/canvas.event.types';
import { ExtensionTypes } from '../models/extension-types/extension.types';
import { WaveLineInterface } from './../../../../../models/wave-line/wave.line.interface';
import { WaveLineLeve1 } from 'app/core/drawing/models/wave-line/wave-line-smoothing-level1/wave.line.smoothing.level.1';
import { WaveLineTypes } from 'app/core/drawing/models/wave-line-types/wave.line.types';
import { fabric } from 'fabric-with-gestures';

export function initWavyLineExtension(): any {
  if (fabric.WavyLine === undefined) {
    fabric.WavyLine = fabric.util.createClass(fabric.BaseLine, {

      type: ExtensionTypes.WAVE_LINE_EXTENSION,

      initialize(coords, options: any = {}) {
        this.callSuper('initialize', coords, options);

        if (!this.id) {
          this.set('id', ExtensionTypes.WAVE_LINE_EXTENSION + '_' + getRandomId());
        }

        if (!this.actionType) {
          this.actionType = WaveLineTypes.WAVE_LINE;
        }

        this.actionLineType = options.waveType.actionLineType;

        if (!this.arrowType) {
          this.arrowType = new ArrowLineThick();
        }

        this.waveType = options.waveType;

        this.subType = ExtensionTypes.WAVE_LINE_EXTENSION;
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          waveType: this.waveType,
          arrowType: this.arrowType,
          referenceGroupId: this.referenceGroupId,
          actionType: this.actionType || WaveLineTypes.WAVE_LINE,
          subType: ExtensionTypes.WAVE_LINE_EXTENSION,
          actionLineType: this.actionLineType,
          id: this.id,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated,
          shouldBringToFront: this.shouldBringToFront
        });
      },

      _render(ctx) {


        if (this.arrowType.type === ArrowTypes.TINY_ARROW || this.arrowType.type === ArrowTypes.TICK_ARROW) {
          this.drawArrow(ctx, this.arrowType.x, this.arrowType.y);
        }

        if (this.arrowType.type === ArrowTypes.ONLY_POINT) {
          this.drawPoint(ctx, this.arrowType.x);
        }

        const p = this.calcLinePoints();

        var point = this.pointOnLine(this.point(p.x2, p.y2), this.point(p.x1, p.y1), 20);
        var point2 = this.pointOnLine(this.point(p.x2, p.y2), this.point(p.x1, p.y1), 10);


        const wavyLine = this.wavyLine(
          this.point(p.x1, p.y1),
          this.point(point.x, point.y)
        );

        const waveType = this.waveType as WaveLineInterface;
        this.drawWavyLine(wavyLine, ctx, waveType.frequency, waveType.flattening || new WaveLineLeve1().flattening);

        ctx.lineTo(point2.x, point2.y);
        ctx.lineTo(p.x2, p.y2);
        ctx.lineWidth = this.strokeWidth;
        ctx.stroke();
      },

      drawArrow: function (ctx, x, y) {
        ctx.save();
        const xDiff = this.x2 - this.x1;
        const yDiff = this.y2 - this.y1;
        const angle = Math.atan2(yDiff, xDiff);
        ctx.translate(xDiff / 2, yDiff / 2);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(-x, y);
        ctx.lineTo(-x, -y);
        ctx.closePath();
        ctx.fillStyle = this.stroke;
        ctx.fill();
        ctx.restore();
      },

      drawPoint: function (ctx, radius) {
        ctx.save();
        const xDiff = this.x2 - this.x1;
        const yDiff = this.y2 - this.y1;
        ctx.translate(xDiff / 2, yDiff / 2);
        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, 2 * Math.PI, true);
        ctx.fillStyle = this.stroke;
        ctx.fill();
        ctx.restore();
      },

      point(x, y) {
        return {
          x,
          y,
        };
      },

      pointOnLine: function (point1, point2, dist) {
        var len = Math.sqrt(((point2.x - point1.x) * (point2.x - point1.x)) + ((point2.y - point1.y) * (point2.y - point1.y)));
        var t = (dist) / len;
        var x3 = ((1 - t) * point1.x) + (t * point2.x),
          y3 = ((1 - t) * point1.y) + (t * point2.y);
        return new fabric.Point(x3, y3);
      },

      wavyLine(start, end, amplitude) {
        return {
          start,
          end,
          amplitude,
          update() {
            if (this.vec === undefined) {
              this.vec = {};
              this.norm = {};
            }
            this.amplitude = 7.5;
            this.vec.x = this.end.x - this.start.x;
            this.vec.y = this.end.y - this.start.y;
            this.length = Math.sqrt(
              (this.start.x - this.end.x) ** 2 + (this.start.y - this.end.y) ** 2
            );
            this.norm.x = this.vec.x / this.length;
            this.norm.y = this.vec.y / this.length;
            this._wavesRatio = 0.04;
            this._waves = this.length * this._wavesRatio;
            this.wavesPhase = this.length / this._waves;
            return this;
          },
        }.update();
      },

      // draws a wavy line
      drawWavyLine(line: any, ctx: any, frequency: number, flattening: number) {
        let x,
          stepSize = 1,
          i,
          y,
          phase,
          dist;
        ctx.beginPath();
        for (i = stepSize; i < line.length; i += stepSize) {
          x = line.start.x + line.norm.x * i; // get point i pixels from start
          y = line.start.y + line.norm.y * i; // get point i pixels from start
          phase = i / line.wavesPhase; // get the wave phase at this point
          // get the distance from the line to the point on the wavy curve
          dist = this._getFlatCoefficient(phase, frequency, flattening) * line.amplitude;
          x -= line.norm.y * dist;
          y += line.norm.x * dist;
          ctx.lineTo(x, y);
        }
        ctx.lineTo(x, y);

      },

      _getFlatCoefficient(rad, frequency = 0.4, flattening = 1) {
        const k = frequency; // to shrink or expand frequency.
        const b = flattening; // flattening coefficient 1 will be more cosinesque, the higher, the squarer
        const peak = 1; // scale from -xxx to xxx

        const t = Math.cos(rad / k); // that's what oscillates
        return peak * (t * Math.sqrt((1 + b * b) / (1 + b * b * t * t)));
      },
    });

    fabric.WavyLine.fromObject = (object, callback) => {
      const { x1, y1, x2, y2 } = object;
      callback(new fabric.WavyLine([x1, y1, x2, y2], object));
    };
  }
}

export function makeWavyLineWithPoints(
  canvas: any, coords: any[], options: any,
  arrowType: ArrowLineInterface = new ArrowLineThick(), arrowOptions?: any) {

  const referenceGroupId = getReferenceGroupId();
  const referenceCircleGroupId = getReferenceCircleGroupId();
  const [x1, y1, x2, y2] = coords;


  function makeCircle(left: number, top: number) {
    const c = new fabric.BaseCircle({
      left,
      top,
    });

    return c;
  }

  const line = new fabric.WavyLine(coords, options);
  line.referenceGroupId = referenceGroupId;

  line.arrowType = arrowType;
  line.dirty = true;

  const startCircle = makeCircle(x1, y1);
  startCircle.subType = ExtensionTypes.START_CIRCLE_EXTENSION;
  startCircle.referenceGroupId = referenceGroupId;
  startCircle.referenceCircleGroupId = referenceCircleGroupId;
  startCircle.referenceId = line.id;

  const endCircle = makeCircle(x2, y2);
  endCircle.subType = ExtensionTypes.END_CIRCLE_EXTENSION;
  endCircle.referenceGroupId = referenceGroupId;
  endCircle.referenceCircleGroupId = referenceCircleGroupId;
  endCircle.referenceId = line.id;

  canvas.add(line, startCircle, endCircle);

  canvas.renderAll();
  canvas.setActiveObject(endCircle);

  startCircle.on(CanvasEventTypes.MOVING, () => {

    line.set({
      x1: startCircle.left,
      y1: startCircle.top,
    });
  });

  endCircle.on(CanvasEventTypes.MOVING, (e: any) => {

    line.set({
      x2: endCircle.left,
      y2: endCircle.top,
    });
  });
}