import { AppConfig } from 'app/config/app.config';
import { ExtensionTypes } from '../models/extension-types/extension.types';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initGroupExtension(): any {
  if (fabric.CustomGroup === undefined) {
    fabric.CustomGroup = fabric.util.createClass(fabric.Group, {

      type: ExtensionTypes.GROUP_PLAYER_EXTENSION,

      initialize(objects, options) {
        this.callSuper('initialize', objects, options);

        if (!this.id) {
          // extends the custom object with additional property
          this.set({ id: getRandomId() });
        }

        if (!this.actionSize) {
          this.actionSize = AppConfig.Canvas.minScaleSize;
        }

        if (!this.subType) {
          this.subType = ExtensionTypes.GROUP_PLAYER_EXTENSION;
        }

        if (!this.isGroup) {
          this.isGroup = true;
        }

        this.set({ noScaleCache: true });
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          isGroup: this.isGroup,
          subType: ExtensionTypes.GROUP_PLAYER_EXTENSION,
          actionType: this.actionType,
          rotateAngle: this.rotateAngle || 0,
          actionName: this.actionName,
          actionSize: this.actionSize,
          changeColor: this.changeColor,
          imageURL: this.imageURL,
          color1: this.color1,
          color2: this.color2,
          isLocked: this.isLocked,
          visibleFrameIndex: this.visibleFrameIndex,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated,
          initHeight: this.initHeight,
          id: this.id,
          name: this.name,
          line: this.line,
          referenceId: this.referenceId,
          referenceGroupId: this.referenceGroupId,
          shouldBringToFront: this.shouldBringToFront
        });
      },
    });

    fabric.CustomGroup.fromObject = (object, callback) => {
      let _enlivenedObjects;
      // Creates corresponding fabric instances from their object representations
      fabric.util.enlivenObjects(object.objects, (enlivenedObjects) => {
        delete object.objects;

        const scale = object.actionSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;

        _enlivenedObjects = enlivenedObjects;
        // Clear svg object
        _enlivenedObjects = _enlivenedObjects.filter((enlivenedObject) => enlivenedObject.type !== 'group');

        fabric.loadSVGFromURL(object.imageURL, (svgObjects, svgOptions) => {
          const player = _enlivenedObjects[0];
          const text = _enlivenedObjects[1];

          svgObjects.forEach((object) => {
            if ((typeof object.fill === 'string' || object.fill instanceof String) && (object.fill.toLowerCase() === '#ff862a'
              || object.fill.toLowerCase() === '#f9ff00'
              || object.fill.toLowerCase() === '#c40000'
              || object.fill.toLowerCase() === '#004200')
              && player.color1.length > 2) {

              object.fill = player.color1;
            }
            if ((typeof object.fill === 'string' || object.fill instanceof String) && (object.fill.toLowerCase() === '#3cb9ce'
              || object.fill.toLowerCase() === '#ffffa1'
              || object.fill.toLowerCase() === '#a9a9aa'
              || object.fill.toLowerCase() === '#f4f4f4'
              || object.fill.toLowerCase() === '#EAC55C'
              || object.fill.toLowerCase() === '#000001')
              && player.color2.length > 2) {
              object.fill = player.color2;
            }
          });

          const groupSvg = fabric.util.groupSVGElements(svgObjects, svgOptions);
          groupSvg.left = player.left;
          groupSvg.top = player.top;
          groupSvg.originX = 'center';
          groupSvg.originY = 'center';
          groupSvg.scale(10);
          groupSvg.objectCaching = true;

          text.set({
            top: player.top - player.getScaledHeight() / 1.4,
            left: player.left
          });

          _enlivenedObjects.push(groupSvg);
          const group = new fabric.CustomGroup(_enlivenedObjects, object);

          group.set({
            scaleX: scale,
            scaleY: scale
          });

          group._calcBounds(true);

          callback(group);
        });
      });
    };
  }
}

export function toggleObjectPathsVisibility(canvas: any, objects: any[]): void {
  objects
    .filter(object => object.subType === ExtensionTypes.GROUP_PLAYER_EXTENSION)
    .forEach(object => {
      if (object.referenceId) {
        const targetPathObject = objects.find(targetPathObject =>
          targetPathObject.id === object.referenceId);

        const targetCircleObject = objects.find(targetCircleObject =>
          targetCircleObject.subType === ExtensionTypes.MIDDLE_PATH_CIRCLE_EXTENSION &&
          targetCircleObject.referenceGroupId === targetPathObject.referenceGroupId);

        targetPathObject.set({ visible: !targetPathObject.visible });
        targetCircleObject.set({ visible: !targetCircleObject.visible });

        canvas.renderAll();
      }
    });
}
