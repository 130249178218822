import { AppConfig } from 'app/config/app.config';
import { Globalization } from '@ionic-native/globalization/ngx';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Manages UI language of the app
 * @class LanguageService
 */
@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  /**
   * Manages UI language using follow service (TranslateService, Globalization, StorageService)
   * @param {TranslateService} translateService
   * @param {Globalization} globalization
   * @param {StorageService} storageService
   */
  constructor(
    private translateService: TranslateService,
    private globalization: Globalization,
    private storageService: StorageService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang(AppConfig.Language.defaultLanguage);
  }

  /**
   * Initializes UI language
   */
  public async initializeLanguage(): Promise<any> {
    // gets locally preffered language from the storage
    const languageFromStorage = await this.storageService.getUserLanguageFromStorage();

    if (!languageFromStorage) {
      // detect the language of the user from the device
      const deviceLanguage = await this.getDeviceLanguage();

      // Set language from device/browser
      const targetLanguage = deviceLanguage || navigator.language;
      await this.setLanguage(targetLanguage);
    } else {
      // If we have stored language inside ionic storage we loaded
      await this.translateService.use(languageFromStorage).toPromise();
    }
  }

  /**
   * Updates user storage data and translation service language
   * @private
   * @param {string} language
   */
  public async setLanguage(language: string): Promise<any> {
    // convert to short language code, en-US to en.
    language = language.substr(0, 2);
    await this.storageService.setUserLanguageToStorage(language);
    await this.translateService.use(language).toPromise();
  }

  /**
   * Gets preferred language fromthe user device
   * @returns {Promise<any>}
   */
  private async getDeviceLanguage(): Promise<any> {
    try {
      await this.globalization.getPreferredLanguage();
    } catch (error) {
      return new Promise((reject) => reject(null));
    }
  }
}
