import { AppConfig } from 'app/config/app.config';
import { ArrowTypes } from 'app/core/drawing/models/arrow-types/arrowtypes';
import { ExtensionTypes } from '../../models/extension-types/extension.types';
import { LineTypes } from 'app/core/drawing/models/line-types/line.types';
import { RectTypes } from 'app/core/drawing/models/rect-types/rect.types';
import { ThicknessTypes } from 'app/core/drawing/models/thickness-types/thickness.line';
import { ThinLine } from 'app/core/drawing/models/thickness-line/thin-line/thin.line';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initRectShapeExtension(): any {
  if (fabric.RectShape === undefined) {
    fabric.RectShape = fabric.util.createClass(fabric.Polygon, {

      type: ExtensionTypes.RECT_SHAPE_EXTENSION,

      initialize(points, options: any = {}) {
        options.strokeWidth = options.strokeWidth || new ThinLine().width;
        options.stroke = options.stroke || 'black';
        options.selectable = true;
        options.objectCaching = false;
        options.dirty = true;

        this.callSuper('initialize', points, options);

        this.set({
          hasBorders: false,
          hasControls: true,
        });

        if (!this.id) {
          // extends the custom object with additional property
          this.set('id', ExtensionTypes.RECT_SHAPE_EXTENSION + '_' + getRandomId());
        }

        if (!this.actionType) {
          this.actionType = RectTypes.RECT;
        }

        if (!this.actionLineType) {
          this.actionLineType = LineTypes.SOLID_LINE;
        }

        if (!this.actionTicknessType) {
          this.actionTicknessType = ThicknessTypes.THIN_LINE;
        }

        if (!this.subType) {
          this.subType = ExtensionTypes.RECT_SHAPE_EXTENSION;
        }

        if (!this.actionSize) {
          this.actionSize = AppConfig.Canvas.minScaleSize;
        }
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          actionType: this.actionType || RectTypes.RECT,
          actionTicknessType: this.actionTicknessType || ThicknessTypes.THIN_LINE,
          actionLineType: this.actionLineType || LineTypes.SOLID_LINE,
          subType: ExtensionTypes.RECT_SHAPE_EXTENSION,
          id: this.id,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated,
          shouldBringToFront: this.shouldBringToFront
        });
      },
    });

    fabric.RectShape.fromObject = (object, callback) => {

      const polygon = new fabric.RectShape(object.points, object);

      function polygonPositionHandler(dim, finalMatrix, fabricObject) {
        var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
          y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);

        if (!fabricObject.canvas) {
          return { x: x, y: y };
        };

        return fabric.util.transformPoint(
          { x: x, y: y },
          fabric.util.multiplyTransformMatrices(
            fabricObject.canvas.viewportTransform,
            fabricObject.calcTransformMatrix()
          )
        );
      }

      function actionHandler(eventData, transform, x, y) {
        var polygon = transform.target,
          currentControl = polygon.controls[polygon.__corner],
          mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
          polygonBaseSize = polygon._getNonTransformedDimensions(),
          size = polygon._getTransformedDimensions(0, 0),
          finalPointPosition = {
            x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
            y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
          };
        polygon.points[currentControl.pointIndex] = finalPointPosition;
        return true;
      }

      function anchorWrapper(anchorIndex, fn) {
        return function (eventData, transform, x, y) {
          var fabricObject = transform.target,
            absolutePoint = fabric.util.transformPoint({
              x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
              y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
            }, fabricObject.calcTransformMatrix()),
            actionPerformed = fn(eventData, transform, x, y),
            newDim = fabricObject._setPositionDimensions({}),
            polygonBaseSize = fabricObject._getNonTransformedDimensions(),
            newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
            newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
          fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
          return actionPerformed;
        }
      }

      var lastControl = polygon.points.length - 1;
      polygon.cornerStyle = 'circle';
      polygon.cornerColor = '#666';
      polygon.controls = polygon.points.reduce(function (acc, point, index) {
        acc['p' + index] = new fabric.Control({
          positionHandler: polygonPositionHandler,
          actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
          actionName: 'modifyPolygon',
          pointIndex: index
        });
        return acc;
      }, {});


      callback(polygon);
    };
  }
}