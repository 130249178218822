import { ILanguages } from './lang.interfaces';

/**
 * Implements all defined languages (DE, EN, ES, FR, IT, CN, HR, HU)
 * @class Languages
 * @implements {ILanguages}
 */
export class Languages implements ILanguages {
  uid: number;
  nameDE: string;
  nameEN: string;
  nameES: string;
  nameFR: string;
  nameIT: string;
  nameCN: string;
  nameHR: string;
  nameHU: string;
  code: string;
  active: number;
}
