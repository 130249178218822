import { FolderItem } from 'app/core/services/build/model/folder.item';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import { PlatformDetectorService } from 'app/core/services/system/platform/platform-detector.service';
import { StorageService } from 'app/core/services/system/storage/storage/storage.service';
import { Subscription } from 'rxjs';
import { SyncAbstractService } from '../../sync.abstract.service';
import { SyncAppFolderService } from './folder/sync-app-folder.service';
import { SyncAppTacticService } from './tactics/sync-app-tactic.service';
import { SyncServiceInterface } from '../../interfaces/sync.service.interface';
import { SyncStagesTypes } from '../../stages/sync.stages.types';
import { SyncSubjectService } from '../../sync.subject.service';
import { TacticItem } from 'app/core/services/build/model/tactic.item';
import { TacticTypes } from 'app/core/services/tactic/model/tactic.types';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SyncAppService extends SyncAbstractService implements SyncServiceInterface {

  /**
   * Stores subscription 
   * @type {Subscription}
   */
  private subscription: Subscription;

  constructor(
    protected storageService: StorageService,
    protected loadingController: LoadingController,
    protected translateService: TranslateService,
    protected syncSubjectService: SyncSubjectService,
    private network: Network,
    private syncAppTacticService: SyncAppTacticService,
    private syncAppFolderService: SyncAppFolderService,
    private platformDetectorService: PlatformDetectorService,
  ) {
    super(loadingController, translateService, storageService, syncSubjectService);

  }

  /**
   * Executes predefined steps for syncing the app version
   * @returns {Promise<void>}
   */
  public async executeSyncSteps(): Promise<void> {
    this.loadingController.getTop().then(v => v ? this.loadingController.dismiss() : null);
    const loading = await this.createLoading();

    loading.present();

    await this.syncAppFolderService.syncFoldersCloudToApp();
    await this.syncAppTacticService.syncTacticsCloudToApp();

    await this.syncAppFolderService.syncFoldersAppToCloud();
    await this.syncAppTacticService.syncTacticsAppToCloud();

    await this.resetLocalMarkedSyncedFolders();
    await this.resetLocalMarkedSyncedTactics();

    loading.dismiss();
  }

  /**
   * Runs synchronization process app version
   * @param {SyncStagesTypes} syncStageType
   * @returns {Promise<void>}
   */
  public async startFullSync(syncStageType: SyncStagesTypes): Promise<void> {
    // @todo remove it navigator.onLine (debugging)
    if (this.network.type === 'wifi' || this.network.type === 'CELL_4G' || navigator.onLine) {
      await this.executeSyncSteps();
    }

    await this.updateFolderItemsBrowser();
    await this.updateFolderItemsApp();
    await this.deleteLocalMarkedDeletedFolders();

    this.emitSyncSubject(syncStageType);
  }

  /**
   * Update each folder with the items (imageCount, animationCount, parentUid, thumbs)
   * @returns {Promise<void>}
   */
  private async updateFolderItemsApp(): Promise<void> {
    const userStorageData = await this.storageService.getUserDataFromStorage();

    for (const folderItem of userStorageData.foldersData) {
      const relatedImagesUids = (await this.getAllImages(folderItem.syncId, TacticTypes.IMAGE));
      const relatedAnimatioinsUids = (await this.getAllImages(folderItem.syncId, TacticTypes.ANIMATION));
      const subFoldersUids = (await this.getAllFolders(folderItem.syncId)).filter(folderUid => folderUid !== folderItem.syncId);

      folderItem.thumbs = this.getTacticsThumbsByFolder(folderItem.uid, folderItem.syncId, userStorageData.tacticsData);
      folderItem.animationCount += relatedAnimatioinsUids.length || 0;
      folderItem.imagesCount += relatedImagesUids.length || 0;
      folderItem.folderCount += subFoldersUids.length || 0;
      folderItem.folderUids = subFoldersUids;
      folderItem.imagesUids = relatedImagesUids.concat(relatedAnimatioinsUids);
    }

    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Returns all child nodes folders uids by given folder uid
   * @param {number} folderUid
   * @returns {Promise<any[]>}
   */
  protected async getAllFolders(folderUid: number): Promise<any[]> {
    let folders = [];
    const userStorageData = await this.storageService.getUserDataFromStorage();

    const foldersUids = userStorageData.foldersData
      .filter((folder: FolderItem) => folder.parentUid > 0 && folder.parentSyncId === folderUid)
      .map((folder: FolderItem) => folder.syncId);

    folders.push(folderUid);

    for (const folderUid of foldersUids) {
      const subFolders = await this.getAllFolders(folderUid);
      if (folders) {
        folders = folders.concat(subFolders);
      }
    }

    return folders;
  }

  /**
   * Returns all tactics uids by given folder uid
   *
   * @private
   * @param {number} folderUid
   * @param {TacticTypes} tacticTypes
   * @returns {Promise<any[]>}
   */
  protected async getAllImages(folderUid: number, tacticTypes: TacticTypes): Promise<any[]> {
    let tactics = [];
    const userStorageData = await this.storageService.getUserDataFromStorage();

    const imagesUids = userStorageData.tacticsData.filter((tactic) =>
      tactic.parentSyncId === folderUid &&
      tactic.type === tacticTypes).map((tactic: TacticItem) => tactic.uid);

    const foldersUids = userStorageData.foldersData
      .filter((folder: FolderItem) => folder.parentUid > 0 && folder.parentSyncId === folderUid)
      .map((folder: FolderItem) => folder.syncId);

    tactics = tactics.concat(imagesUids);

    for (const folderUid of foldersUids) {
      tactics = tactics.concat(await this.getAllImages(folderUid, tacticTypes));
    }

    return tactics;
  }

  /**
   * Returns folder's last three tactic thumbs
   * @param {number} folderUid
   * @returns {string[]}
   */
  private getTacticsThumbsByFolder(folderUid: number, syncId: number, tactics: TacticItem[]): string[] {
    let thumbs: string[] = [];

    if (syncId) {
      thumbs = this.getTacticsThumbsByFolderSyncId(syncId, tactics);
    } else {
      thumbs = this.getTacticsThumbsByFolderUid(folderUid, tactics);
    }

    return thumbs;
  }

  /**
   * Deletes all folders (from storage) that have a mark deleted equal to 1
   * @memberof SyncAppService
   */
  private async deleteLocalMarkedDeletedFolders() {
    const userStorageData = await this.storageService.getUserDataFromStorage();

    userStorageData.foldersData.forEach((folderItem: FolderItem, index, object) => {
      if (folderItem.deleted === 1) {
        object.splice(index, 1);
      }
    });

    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Deletes all folders (from storage) that have a mark deleted equal to 1
   * @memberof SyncAppService
   */
  private async resetLocalMarkedSyncedFolders() {
    const userStorageData = await this.storageService.getUserDataFromStorage();

    userStorageData.foldersData
      .filter((folderItem: FolderItem) => folderItem.syncId > 0)
      .forEach((folderItem: FolderItem) => {
        folderItem.syncId = 0;
      });

    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Deletes all folders (from storage) that have a mark deleted equal to 1
   * @memberof SyncAppService
   */
  private async resetLocalMarkedSyncedTactics() {
    const userStorageData = await this.storageService.getUserDataFromStorage();

    userStorageData.tacticsData
      .filter((tacticItem: TacticItem) => tacticItem.syncId > 0)
      .forEach((tacticItem: TacticItem) => {
        tacticItem.syncId = 0;
      });

    await this.storageService.setUserDataToStorage(userStorageData);
  }
}
