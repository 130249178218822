import { AppConfig } from 'app/config/app.config';
import { CanvasEventTypes } from '../../events/model/canvas/canvas.event.types';
import { DrawSubjectService } from '../../../draw.subject.service';
import { ExtendFigure } from './model/figure-model/figure.model';
import { ExtensionTypes } from '../../fabric/extensions/models/extension-types/extension.types';
import { Injectable } from '@angular/core';
import { MiscTypes } from 'app/core/drawing/models/misc-types/misc.types';
import { fabric } from 'fabric-with-gestures';
import { makeCurvedLineWithPoints } from '../../fabric/extensions/curved-line-extension/curved.line.extension';

@Injectable({
  providedIn: 'root'
})
export class DrawTacticActionsService {
  constructor(private drawSubjectService: DrawSubjectService) {
  }

  public drawTactic(canvas: any, extendFigure: ExtendFigure, forceColor?: boolean): void {
    if (localStorage.getItem(extendFigure.actionType) && !forceColor) {
      const colors = JSON.parse(localStorage.getItem(extendFigure.actionType));
      extendFigure.color1 = colors.color1;
      extendFigure.color2 = colors.color2;
    }

    fabric.loadSVGFromURL(extendFigure.imageURL, (objects, options) => {
      objects.forEach((object) => {
        if (extendFigure.actionType !== MiscTypes.MISC_OBJECTS && (
          object.fill.toLowerCase() === '#c40000' ||
          object.fill.toLowerCase() === '#f9ff00'
        ) && extendFigure.color1.length > 2) {
          object.fill = extendFigure.color1;
        }
      });

      const svg = fabric.util.groupSVGElements(objects, options);
      svg.left = extendFigure.left;
      svg.top = extendFigure.top;
      svg.scale(10);

      svg.cloneAsImage(img => {
        const player = new fabric.Player(img._element);
        player.set({
          left: extendFigure.left,
          top: extendFigure.top,
          originX: 'center',
          originY: 'center',
          color1: extendFigure.color1,
          color2: extendFigure.color2,
        });

        const text = new fabric.BaseText(extendFigure.text,
          {
            fontSize: AppConfig.Canvas.textFontSizeTactics,
            fontFamily: AppConfig.Canvas.fontFamily,
            originX: 'bottom',
            originY: 'center',
            top: extendFigure.top + player.getScaledHeight() / 6,
            left: extendFigure.left
          });

        if (extendFigure.textColor) {
          text.fill = extendFigure.textColor;
        }

        if (extendFigure.isNotCenterText) {
          text.fontSize = AppConfig.Canvas.textFontSize;
          text.fontFamily = AppConfig.Canvas.fontFamily;
          text.top = extendFigure.top - player.getScaledHeight() / 1.4;
          text.originX = 'center';
          text.originY = 'bottom';
        }

        const group = new fabric.CustomShapeGroup([player, text],
          {
            left: extendFigure.left,
            top: extendFigure.top,
            originX: 'center',
            originY: 'center',
            lockUniScaling: true,
            borderColor: AppConfig.Canvas.borderColor,
            cornerColor: AppConfig.Canvas.cornerColor,
          });

        if (extendFigure.id) {
          group.id = extendFigure.id;
        }

        if (extendFigure.referenceId) {
          group.referenceId = extendFigure.referenceId;
        }

        if (extendFigure.referenceGroupId) {
          group.referenceGroupId = extendFigure.referenceGroupId;
        }

        group.actionType = extendFigure.actionType;
        group.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
        });

        group.actionSize = extendFigure.actionSize;
        group.imageURL = extendFigure.imageURL;
        group.angle = extendFigure.angle;
        group.actionName = extendFigure.text;
        group.isLocked = extendFigure.isLocked;
        group.isNotCenterText = extendFigure.isNotCenterText;
        group.color1 = extendFigure.color1;

        const scale = extendFigure.actionSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;

        group.set({
          scaleX: scale,
          scaleY: scale
        });

        makeCurvedLineWithPoints(canvas, {}, group, false);

        if (extendFigure.selectAfterCreate) {
          canvas.setActiveObject(group);
        }

        //  this.drawSubjectService.subjectDraw.next(CanvasEventTypes.DRAW_TACTIC);
      });
    });
  }

  /**
   * @todo separate logic of addTactic, updateTctic into a new one helper method
   * @param {any} canvas
   * @param {ExtendFigure} extendFigure
   * @param {any} callback
   */
  public updateTactic(canvas: any, extendFigure: ExtendFigure, callback: any): void {
    if (localStorage.getItem(extendFigure.actionType)) {
      const colors = JSON.parse(localStorage.getItem(extendFigure.actionType));
      extendFigure.color1 = colors.color1;
      extendFigure.color2 = colors.color2;
    }

    fabric.loadSVGFromURL(extendFigure.imageURL, (objects, options) => {
      objects.forEach((object) => {
        if (extendFigure.actionType !== MiscTypes.MISC_OBJECTS && (
          object.fill.toLowerCase() === '#c40000' ||
          object.fill.toLowerCase() === '#f9ff00'
        ) && extendFigure.color1.length > 2) {
          object.fill = extendFigure.color1;
        }
      });

      const svg = fabric.util.groupSVGElements(objects, options);
      svg.left = extendFigure.left;
      svg.top = extendFigure.top;
      svg.scale(10);

      svg.cloneAsImage(img => {
        const player = new fabric.Player(img._element);
        player.set({
          left: extendFigure.left,
          top: extendFigure.top,
          originX: 'center',
          originY: 'center',
          color1: extendFigure.color1,
          color2: extendFigure.color2,
        });


        const text = new fabric.BaseText(extendFigure.text,
          {
            fontSize: AppConfig.Canvas.textFontSizeTactics,
            fontFamily: AppConfig.Canvas.fontFamily,
            originX: 'bottom',
            originY: 'center',
            top: extendFigure.top + player.getScaledHeight() / 6,
            left: extendFigure.left
          });


        if (extendFigure.isNotCenterText) {
          //text.fontSize = AppConfig.Canvas.textFontSize;
          text.top = extendFigure.top - player.getScaledHeight() / 1.4;
          text.originX = 'center';
          text.originY = 'bottom';
        }

        const group = new fabric.CustomShapeGroup([player, text],
          {
            left: extendFigure.left,
            top: extendFigure.top,
            originX: 'center',
            originY: 'center',
            lockUniScaling: true,
            borderColor: AppConfig.Canvas.borderColor,
            cornerColor: AppConfig.Canvas.cornerColor,
            changeColor: extendFigure.changeColor
          });
        canvas.add(group);

        if (extendFigure.id) {
          group.id = extendFigure.id;
        }

        if (extendFigure.referenceId) {
          group.referenceId = extendFigure.referenceId;
        }

        if (extendFigure.referenceGroupId) {
          group.referenceGroupId = extendFigure.referenceGroupId;
        }

        group.actionType = extendFigure.actionType;

        group.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
        });

        group.actionSize = extendFigure.actionSize;
        group.imageURL = extendFigure.imageURL;
        group.angle = extendFigure.angle;
        group.actionName = extendFigure.text;
        group.isLocked = extendFigure.isLocked;
        group.isNotCenterText = extendFigure.isNotCenterText;
        group.color1 = extendFigure.color1;

        const scale = extendFigure.actionSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;

        group.set({
          scaleX: scale,
          scaleY: scale
        });

        if (extendFigure.selectAfterCreate) {
          canvas.setActiveObject(group);
        }

        if (callback) {
          callback();
        }
      });
    });
  }
}
