import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

/**
 * Provides general way to call pages as like a modal window
 * @class ModalService
 */
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  /**
   * Stores reference to the current open modal
   * @type {HTMLIonModalElement}
   */
  private modal: HTMLIonModalElement;

  /**
   * Keeps modal state
   * @type {boolean}
   */
  private isActive: boolean = false;

  /**
   * Uses build-in service provided by ionic
   * @param {ModalController} modalController
   */
  constructor(public modalController: ModalController) { }

  /**
   * Opens the modalPage argument as like a modal window
   * @param {any} modalPage
   * @returns {Promise<void>}
   */
  public async presentModal(modalPage: any, cssClass?: string, pageParams?: any): Promise<void> {
    if (this.modal) {
      return;
    }

    const modal = await this.modalController.create({
      component: modalPage,
      cssClass,
      componentProps: pageParams
    });
    this.isActive = true;
    this.modal = modal;

    modal.onDidDismiss().then(() => {
      this.modal = null;
    });

    return modal.present();
  }

  /**
   * Returns current modal state
   * @returns {boolean}
   */
  public isModalActive(): boolean {
    return this.isActive;
  }

  public dismiss() {
    this.modalController.dismiss();
  }
}
