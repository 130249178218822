import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AppRoutes } from 'app/shared/app.routes ';
import { Injectable } from '@angular/core';
import { IonicAuthStorageService } from 'app/core/services/system/storage/auth/ionic.storage.auth.service';
import { RouteProperties } from './model/route.properties.model';

/**
 * Manages route access based on the JWT
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   * Uses ionic storage to obtain the token and router to redirect user if is need it
   * @param {IonicAuthStorageService} auth
   * @param {Router} router
   */
  constructor(private auth: IonicAuthStorageService, private router: Router) { }
  /**
   * Uses ActivatedRouteSnapshot to get current route path
   * Note: canActivate is defined to working async, because our storage service is async
   * @param {ActivatedRouteSnapshot} route
   * @returns {(boolean | Promise<any>)}
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<any> {

    return new Promise(async (resolve) => {
      // get metadata of token
      const isTokenExpired: boolean = await this.auth.isTokenExpired();
      // obtain default route from all registered routes
      const defaultRoute =
        this.router.config[0].path || this.router.config[0].redirectTo || '';
      // get route from The URL segment
      const requestedRoute = route.url[0].path || '';
      // get the new route properties
      const newRoute: RouteProperties = this.getRouteForNonExpiredToken(
        requestedRoute,
        defaultRoute,
        isTokenExpired,
      );

      if (newRoute.path !== requestedRoute) {
        this.router.navigate([newRoute.path]);
      }
      resolve(newRoute.enable);
    });
  }

  /**
   * Gets RouteProperties for non expired token
   * @param {string} requestedRoute
   * @param {string} defaultRoute
   * @param {boolean} isTokenExpired
   * @returns {RouteProperties}
   */
  private getRouteForNonExpiredToken(
    requestedRoute: string,
    defaultRoute: string,
    isTokenExpired: boolean
  ): RouteProperties {
    let path = requestedRoute;

    let enable = true;

    if (isTokenExpired) {
      path = defaultRoute;
      enable = false;
    } else if (requestedRoute === defaultRoute) {
      path = AppRoutes.MY_FOLDERS;
      enable = false;
    }

    return { path, enable } as RouteProperties;
  }
}
