import { AppConfig } from 'app/config/app.config';
import { CanvasActionsInterface } from 'app/core/drawing/interfaces/canvas.actions.interface';
import { CanvasActionsService } from '../canvas/actions/canvas.actions.service';
import { CanvasEventTypes } from '../events/model/canvas/canvas.event.types';
import { CanvasOptionsInterface } from 'app/core/drawing/interfaces/canvas.options.interface';
import { CanvasOptionsService } from '../canvas/options/canvas.options.service';
import { DrawEllipseActionsService } from '../object/line-actions/draw-ellipse-actions.service';
import { DrawInterface } from '../../../interfaces/draw.interface';
import { DrawRectActionsService } from '../object/line-actions/draw-rect-actions.service';
import { DrawSubjectService } from '../../draw.subject.service';
import { DrawTacticActionsService } from '../object/line-actions/draw-tactic-actions.service';
import { DrawTextActionsService } from '../object/line-actions/draw-text-actions.service';
import { EventsHandlerService } from '../events/events-handler/events-handler.service';
import { ExtendFigure } from '../object/line-actions/model/figure-model/figure.model';
import { FrameEventTypes } from '../events/model/frame/frame.event.types';
import { FrameSubjectService } from '../../frame.subject.service';
import { Injectable } from '@angular/core';
import { LoadTypes } from 'app/core/drawing/models/load-types/load.types';
import { ObjectActionsInterface } from 'app/core/drawing/interfaces/object.actions.interface';
import { ObjectActionsService } from '../object/actions/object-actions.service';
import { PlatformDetectorService } from 'app/core/services/system/platform/platform-detector.service';
import { Subscription } from 'rxjs';
import { TacticShapeTypes } from 'app/core/drawing/models/tactic-shape-types/tactic.shape.types';
import { fabric } from 'fabric-with-gestures';
import { initFabricExtensions } from './extensions/extensions';

/**
 * @todo move canvas options, object options in separate services (CanvasAction, ObjectActions)
 * Represents basic drawing service based on the draw plugin fabricjs
 * @class DrawingFabricService
 * @implements {DrawInterface}
 */
@Injectable({
  providedIn: 'root'
})
export class DrawingFabricService implements DrawInterface,
  CanvasActionsInterface, CanvasOptionsInterface, ObjectActionsInterface {

  /**
   * Stores subscription 
   * @type {Subscription}
   */
  private subscription: Subscription;


  /**
   * Extends canvas object with additional canvass properties
   */
  private canvasWrapper =
    {
      canvas: null, canvasOffset: 0, canvasProperties:
      {
        backgroundPath: '',
        maxViewPortZoom: 1, viewportTransform: null,
        frameDuration: AppConfig.Canvas.defaultFrameDuration
      }
    };

  private gridGroup: any;

  public panState: boolean = false;

  /**
   * Uses addtional services to separate whole implementation into sub services
   * @param {EventsCanvasService} eventsHandlerService
   */
  constructor(
    private platformDetectorService: PlatformDetectorService,
    private drawSubjectService: DrawSubjectService,
    private frameSubjectService: FrameSubjectService,
    private objectActionsService: ObjectActionsService,
    private canvasOptionsService: CanvasOptionsService,
    private eventsHandlerService: EventsHandlerService,
    private canvasActionsService: CanvasActionsService,
    private drawRectActionsService: DrawRectActionsService,
    private drawEllipseActionsService: DrawEllipseActionsService,
    private drawTextActionsService: DrawTextActionsService,
    private drawTacticActionsService: DrawTacticActionsService) {
  }

  /**
   * Animates current passed element by passed options
   * @param {any} element
   * @param {any} options
   * @param {any} callback
   */
  public animate(element: any, options: any, callback: any) {
    this.objectActionsService.animate(element, options, callback, this.canvasWrapper.canvas);
  }

  // @todo move to canvas CanvasOptionsService
  public findObject(id: string): any {
    return this.getAllObjects().find(object => object.id === id);
  }

  /**
   * Returns instance of the canvas
   * @returns {any}
   */
  public getCanvas(): any {
    return this.canvasWrapper.canvas;
  }

  /**
   * Returns instance of the canvas
   * @returns {any}
   */
  public getCanvasWrapper(): any {
    return this.canvasWrapper;
  }

  /**
   * Returns current active object
   * @param {any} canvas
   * @returns {any}
   */
  public getActiveObject(): any {
    return this.objectActionsService.getActiveObject(this.canvasWrapper.canvas);
  }

  /**
   * @todo add to interface
   * Returns current active object
   * @param {any} canvas
   * @returns {any}
   */
  public setActiveObject(object: any): any {
    return this.objectActionsService.setActiveObject(this.canvasWrapper.canvas, object);
  }

  /**
   * Adds new element to the passed canvas
   * @param {any} data
   * @param {any} canvas
   */
  public addObject(data): void {
    this.objectActionsService.addObject(data, this.canvasWrapper.canvas);
  }

  /**
   * Removes all objects from canvas
   * @param {any} canvas
   */
  public async removeAllObjects(): Promise<void> {
    await this.objectActionsService.removeAllObjects(this.canvasWrapper.canvas);
  }

  //@todo add to interface
  public drawStraightLine() {
    this.enablePan(false);
    this.drawSubjectService.subjectDraw.next(CanvasEventTypes.STRAIGHT_LINE_START_DRAWING);
  }

  //@todo add to interface
  public drawCurvedLine() {
    this.enablePan(false);
    this.drawSubjectService.subjectDraw.next(CanvasEventTypes.CURVE_LINE_START_DRAWING);
  }

  //@todo add to interface
  public drawRect() {
    this.enablePan(false);
    this.drawRectActionsService.drawRect(this.canvasWrapper.canvas);
  }

  //@todo add to interface
  public drawEllipse() {
    this.enablePan(false);
    this.drawEllipseActionsService.drawEllipse(this.canvasWrapper.canvas);
  }

  //@todo add to interface
  public drawFreeLine(): void {
    this.enablePan(false);
    this.drawSubjectService.subjectDraw.next(CanvasEventTypes.FREE_LINE_START_DRAWING);
  }

  //@todo add to interface
  public drawText(): void {
    this.enablePan(false);
    this.drawTextActionsService.drawText(this.canvasWrapper.canvas);
  }

  /**
   * Adds multiple objects to canvas
   * @param {[]} data
   * @param {any} canvas
   */
  public addObjects(data) {
    this.objectActionsService.addObjects(data, this.canvasWrapper.canvas);
  }

  public getSelectedObjectPosition(): any {
    const object = this.objectActionsService.getActiveObject(this.canvasWrapper.canvas);
    const canvasOffset = document.getElementById("canvas").getBoundingClientRect();

    var x, y;
    if (object) {

      if (object.getBoundingRect().left > document.body.clientWidth - 370) {
        x = document.body.clientWidth - 370;
      }
      else {
        x = object.getBoundingRect().left;
      }

      object.setCoords();

      if (-canvasOffset.height / 2 + object.getBoundingRect().top + object.getBoundingRect().height + 10 > this.canvasWrapper.canvas.height - canvasOffset.height / 2 - 60) {
        y = this.canvasWrapper.canvas.height - canvasOffset.height / 2 - 60;
      }
      else {
        y = -canvasOffset.height / 2 + object.getBoundingRect().top + object.getBoundingRect().height + 10;
      }

      if(y < -canvasOffset.height / 2 + object.getBoundingRect().top + object.getBoundingRect().height){
          y = -canvasOffset.height / 2 + object.getBoundingRect().top - object.getScaledHeight()/2 - 30;
      }

      return { x: x, y: y };
    }
  }

  public discardActiveObject(): void {
    this.canvasWrapper.canvas.discardActiveObject();
    this.canvasWrapper.canvas.renderAll();
  }

  /**
   * Makes initialization of the canvas with basic configuraion stored in the config file
   * @param {ElementRef<any>} nativeElement
   * @param {boolean} saveOnEveryAction
   * @returns {Promise<void>}
   */
  public init(nativeElement: any, canvasOffset: number): void {
    if (nativeElement) {
      this.initCanvas(nativeElement, canvasOffset);
      this.setBackgroundImage(AppConfig.Canvas.backgroundPathEmpty);
      this.setsDefaultBackGroundImage();
      this.onEvents();
      this.preinitPan();
      this.eventsHandlerService.onCanvasEvents(this.canvasWrapper);

      initFabricExtensions();
    }
  }

  /**
   * Hooks to specific fabricjs events and emits to all subscribers of the subjectDraw
   */
  public onEvents(): void {
    this.canvasActionsService.onEvents(this.canvasWrapper);
  }

  /**
   * Triggers events specified by event parameter
   */
  public triggerEvent(event: CanvasEventTypes): void {
    this.canvasActionsService.triggerEvent(this.drawSubjectService.subjectDraw, event);
  }

  /**
   * Resizes canvas based on the current screen size
   */
  public resizeCanvas(): void {
    this.canvasOptionsService.resizeCanvas(this.canvasWrapper);
  }

  /**
   * Sets zoom to the entire canvas
   * @param {number} [zoomValue=1]
   */
  public setZoom(zoomValue: number): void {
    this.canvasOptionsService.setZoom(zoomValue, this.canvasWrapper);

    const panstate = !(this.canvasWrapper.canvas.get('zoomDefault') === this.canvasWrapper.canvas.getZoom());
    this.initPan(panstate);
    this.panState = panstate;
  }

  public getZoom(): number {
    return this.canvasWrapper.canvas.getZoom();
  }

  public getZoomPercentage(): number {
    let zoom = this.canvasWrapper.canvas.get('zoomDefault') / this.canvasWrapper.canvas.get('widthDefault');
    zoom *= this.canvasWrapper.canvas.width;
    return this.canvasWrapper.canvas.getZoom() / zoom * 100;
  }

  public setZoomPercentage(percentage: number): number {
    return ((this.canvasWrapper.canvas.getZoom() + percentage) / this.canvasWrapper.canvas.get('zoomDefault')) * 100;
  }

  public setZoomToFit(): void {
    this.canvasOptionsService.setZoomToFit(this.canvasWrapper);
    this.initPan(false);
    this.panState = false;
  }

  public enablePan(dragMode: boolean): void {
    // @todo for remove 
    // const allowPan: boolean = this.canvasWrapper.canvas.get('zoomDefault') > this.canvasWrapper.canvas.getZoom() ? false : true;
    this.initPan(dragMode);
    this.panState = dragMode;
  }

  public setGridVisibility(visibility: boolean): void {

    if (visibility) {
      this.showGrid();
    } else {
      this.hideGrid();
    }
  }

  /**
   * Exports all stored data of the canvas (to json), which includes canvas and all stored object inside of it
   * @returns {string}
   */
  public export(): string {
    return this.canvasActionsService.export(this.canvasWrapper);
  }

  /**
   * Imports json file to the canvas (canvas, all objects)
   * @param {string} jsonData
   */
  public async import(
    jsonData: string, element?: any, frameIndex?: number,
    loadTypes?: LoadTypes, callback?: any, shouldVisible?: boolean, frameEventType?: FrameEventTypes): Promise<void> {

    this.canvasActionsService.import(jsonData, this.canvasWrapper, frameIndex, loadTypes, (object: any) => {
      if (callback) {

        callback(object);
      }

      // @todo using of event to call this function, must be decoupled from here
      // must be private, now image tool also called it explicitly
      const objects = this.getCanvas().getObjects();

      // explicitly locked all objects
      if (shouldVisible === false) {
        objects.forEach(object => {
          object.set({ lockMovementX: true, lockMovementY: true, hasControls: false, selectable: false });
        });
      }

      // hides if there is any visible active control points from other objects
      objects.filter(object =>
        object.referenceCircleGroupId
      ).forEach(object => {
        object.set({ visible: false });
      });
      // @todo should remove? this.resizeCanvas();
    }, shouldVisible, frameEventType);
  }

  public getAllObjects(): any {
    return this.canvasActionsService.getAllObjects(this.canvasWrapper);
  }

  /**
   * Sets background of the canvas (svg from the config file)
   */
  public setBackgroundImage(url: string, shouldResizeCanvas: boolean = true): void {
    this.canvasOptionsService.setBackgroundImage(url, this.canvasWrapper, shouldResizeCanvas);
  }

  /**
   * Returns entire canvas as a image
   * @returns {string}
   */
  public getSnapShot(): string {
    return this.canvasOptionsService.getSnapShotByQuality(1, this.canvasWrapper);
  }

  /**
   * Creates multisection by passed objects
   * @param {any[]} objects
   */
  public createMultiSelection(objects: any[]): void {
    this.canvasActionsService.createMultiSelection(this.canvasWrapper, objects);
  }

  /**
   * Returns entire canvas as a image compressed with specified quality parameter
   * @param {number} [quality=0.4]
   * @returns {string}
   */
  public getSnapShotByQuality(quality: number = 1, bbox: any = {}): string {
    return this.canvasOptionsService.getSnapShotByQuality(quality, this.canvasWrapper, bbox);
  }

  /**
   * Creates temp canvas load current base64 generated from the original canvas
   * returns new resized base64 image
   * @param {string} base64Str
   * @param {number} [maxWidth=120]
   * @param {number} [maxHeight=87]
   * @returns {Promise<string>}
   */
  public async resizeImage(
    base64Str: string,
    width = AppConfig.Image.Thumb.maxWidth,
    height = AppConfig.Image.Thumb.maxHeight): Promise<string> {
    return await this.canvasOptionsService.resizeImage(base64Str, width, height);
  }

  /**
   * Iniitalizes fabricj instance of the canvas
   * @param {ElementRef<any>} element
   */
  private initCanvas(element: any, canvasOffset: number): void {
    this.canvasWrapper.canvas = new fabric.Canvas(element, {
      selection: true,
      imageSmoothingEnabled: true,
      controlsAboveOverlay: true,
      preserveObjectStacking: true,
      allowTouchScrolling: true,
      lockUniScaling: true,
      perPixelTargetFind: true,
      targetFindTolerance: 10
    });
    this.canvasWrapper.canvasOffset = canvasOffset;

    fabric.Group.prototype.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    });
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerSize = 8;

    this.panState = false;
    this.onTouch();
    this.initPan(this.panState);
    this.hideGrid();
  }

  /**
   * Updates black canvas back with new stored background from existing canvas or with default one
   */
  private setsDefaultBackGroundImage(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.drawSubjectService.subjectNative.subscribe((object: any) => {
      // after loading of canvas if there is no differebce between black init background and current canvas
      // sets default background
      if (object.event === CanvasEventTypes.FULLY_LOADED) {
        if (object.canvasWrapper.canvasProperties.backgroundPath === AppConfig.Canvas.backgroundPathEmpty) {
          this.setBackgroundImage(AppConfig.Canvas.backgroundPath, false);
        }
      }
    });
  }

  // @todo move to canvas options service
  private initPan(dragMode) {
    if (dragMode) {
      this.canvasWrapper.canvas.getObjects().filter(
        object => object.referenceCircleGroupId
      ).forEach(object => {
        object.set({ visible: false });
      });

      this.canvasWrapper.canvas.discardActiveObject();
      this.canvasWrapper.canvas.defaultCursor = 'move';
      this.canvasWrapper.canvas.selection = false;

    } else {
      this.canvasWrapper.canvas.defaultCursor = 'default';
      this.canvasWrapper.canvas.selection = true;

      if (this.gridGroup) {
        this.hideGrid();
        this.showGrid();
      }
    }
  }

  // @todo move into events service, using plarform service to determine is it desktop version, 
  // and only then hook on these events
  private onTouch(): void {
    if (this.platformDetectorService.isWebBrowser()) {
      return;
    }

    let pausePanning, zoomStartScale, currentY, currentX, xChange, yChange, lastY, lastX;

    const canvas = this.canvasWrapper.canvas;
    // todo 570 read from cinfig file
    canvas.on({
      // @todo using constant
      'touch:gesture': (e: any) => {
        if (!this.panState) {
          return;
        }

        if (e.e.touches && e.e.touches.length === 2) {
          pausePanning = true;
          const point = new fabric.Point(e.self.x, e.self.y);

          if (e.self.state === 'start') {
            zoomStartScale = canvas.getZoom();
          }

          const delta = zoomStartScale * e.self.scale;
          canvas.zoomToPoint(point, delta);

          this.drawSubjectService.subjectDraw.next(CanvasEventTypes.CANVAS_ZOOMED);
          pausePanning = false;
        }
      },
      // @todo using constant
      'object:selected': () => {
        pausePanning = true;
      },
      // @todo using constant
      'selection:cleared': () => {
        pausePanning = false;
      },
      // @todo using constant
      'touch:drag': (e: any) => {
        if (!this.panState) {
          return;
        }

        if (!pausePanning && undefined !== e.self.x && undefined !== e.self.x) {
          currentX = e.self.x;
          currentY = e.self.y;
          xChange = currentX - lastX;
          yChange = currentY - lastY;

          if ((Math.abs(currentX - lastX) <= 50) && (Math.abs(currentY - lastY) <= 50)) {
            const delta = new fabric.Point(xChange, yChange);
            canvas.relativePan(delta);
          }

          lastX = e.self.x;
          lastY = e.self.y;
        }
      }
    });
  }

  // @todo move to canvas options service
  public preinitPan(): void {
    if (!this.platformDetectorService.isWebBrowser()) {
      return;
    }

    const STATE_IDLE = 'idle';
    const STATE_PANNING = 'panning';

    let lastClientX;
    let lastClientY;

    let state = STATE_IDLE;

    this.canvasWrapper.canvas.on('mouse:up', () => {
      state = STATE_IDLE;
    });

    this.canvasWrapper.canvas.on('mouse:down', (e) => {
      const dragMode = this.panState;

      if (dragMode) {
        state = STATE_PANNING;
        lastClientX = e.e.clientX;
        lastClientY = e.e.clientY;
      } else {
        state = STATE_IDLE;
      }
    });

    this.canvasWrapper.canvas.on(CanvasEventTypes.MOUSE_MOVE, (e) => {
      const dragMode = this.panState;
      if (dragMode && state === STATE_PANNING && e && e.e) {

        let deltaX = 0;
        let deltaY = 0;
        if (lastClientX) {
          deltaX = e.e.clientX - lastClientX;
        }
        if (lastClientY) {
          deltaY = e.e.clientY - lastClientY;
        }

        lastClientX = e.e.clientX;
        lastClientY = e.e.clientY;

        const delta = new fabric.Point(deltaX, deltaY);
        this.canvasWrapper.canvas.relativePan(delta);
      }
    });
  }

  // @todo move to canvas options service
  private showGrid() {
    if (this.gridGroup) {
      return;
    }

    const ratio = AppConfig.Canvas.canvasWidth / this.canvasWrapper.canvas.width;
    const height = this.canvasWrapper.canvas.height * ratio;
    const width = this.canvasWrapper.canvas.width * ratio;
    let line: any;
    const rect = [];
    const size = 60;

    const gridoption = {
      stroke: '#0000ff',
      opacity: 0.7,
      strokeWidth: 0.5
    };
    const gridLines = [];

    for (let i = 0; i < Math.ceil(width / size); ++i) {
      rect[0] = i * size;
      rect[1] = 0;
      rect[2] = i * size;
      rect[3] = height;
      line = null;
      line = new fabric.Line(rect, gridoption);
      line.selectable = false;
      gridLines.push(line);
      line.sendToBack();
    }

    for (let i = 0; i < Math.ceil(height / size); ++i) {
      rect[0] = 0;
      rect[1] = i * size;
      rect[2] = width;
      rect[3] = i * size;
      line = null;
      line = new fabric.Line(rect, gridoption);
      line.selectable = false;
      gridLines.push(line);
      line.sendToBack();
    }

    this.gridGroup = new fabric.BaseGroup(gridLines, {
      selectable: false,
      evented: false,
      excludeFromExport: true
    });

    this.gridGroup.addWithUpdate();
    this.canvasWrapper.canvas.add(this.gridGroup);
    this.canvasWrapper.canvas.renderAll();
  }

  // @todo move to canvas options service
  public hideGrid(): void {
    if (this.gridGroup) {
      this.canvasWrapper.canvas.remove(this.gridGroup)
    }

    this.gridGroup = null;
    this.canvasWrapper.canvas.renderAll();
  }

  /**
   * @todo remove from here
   *
   * @param {any} svgPath
   * @param {string} mainText
   * @param {boolean} isPlayerTop
   */
  public drawTactic(svgPath: any, mainText: string, isNotCenterText: boolean): void {
    if (!localStorage.getItem('object_actionSize')) {
      localStorage.setItem('object_actionSize', String(AppConfig.Canvas.iconDefaultSize));
    }

    const center = { x: AppConfig.Canvas.canvasWidth / 2, y: 380 };

    const extendFigure = new ExtendFigure();
    extendFigure.imageURL = svgPath;
    extendFigure.actionType = TacticShapeTypes.CIRCLE_TACTIC;
    extendFigure.left = center.x;
    extendFigure.top = center.y;
    extendFigure.angle = 0;
    extendFigure.isNotCenterText = isNotCenterText;
    extendFigure.text = mainText;
    extendFigure.actionSize = parseInt(localStorage.getItem('object_actionSize'));
    extendFigure.selectAfterCreate = false;

    this.drawTacticActionsService.drawTactic(this.canvasWrapper.canvas, extendFigure);
  }
}
