import { Folder } from 'app/core/services/folder/model/folder.model';
import { FolderItem } from 'app/core/services/build/model/folder.item';
import { FolderItemToFolderAdapter } from 'app/core/services/folder/adapters/item.folder.adapter.';
import { FolderService } from 'app/core/services/folder/folder.service';
import { FolderToFolderItemAdapter } from './../../../../folder/adapters/folder.item.adapter';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { StorageService } from 'app/core/services/system/storage/storage/storage.service';
import { SyncSubjectService } from '../../../sync.subject.service';
import { TranslateService } from '@ngx-translate/core';
import { UserStorageData } from 'app/core/services/system/storage/storage/model/user.storage.data.model';

/**
 * Provides sync process for folders
 * @class SyncAppFolderService
 */
@Injectable({
  providedIn: 'root'
})
export class SyncAppFolderService {

  /**
   * Uses extra services to provide synchronization from and to server
   * @param {StorageService} storageService
   * @param {LoadingController} loadingController
   * @param {TranslateService} translateService
   * @param {SyncSubjectService} syncSubjectService
   * @param {FolderService} folderService
   * @param {FolderToFolderItemAdapter} folderToFolderItemAdapter
   * @param {FolderItemToFolderAdapter} folderItemToFolderAdapter
   */
  constructor(
    protected storageService: StorageService,
    protected loadingController: LoadingController,
    protected translateService: TranslateService,
    protected syncSubjectService: SyncSubjectService,
    private folderService: FolderService,
    private folderToFolderItemAdapter: FolderToFolderItemAdapter,
    private folderItemToFolderAdapter: FolderItemToFolderAdapter) { }

  /**
   * Updating/Adding different version of folders from backend to the app
   * @returns {Promise<void>}
   */
  public async syncFoldersCloudToApp(): Promise<void> {
    const existingLocalFolders = [];

    const userStorageData = await this.storageService.getUserDataFromStorage();

    this.getAllLocalExistingFolders(userStorageData)
      .forEach((folderitem: FolderItem) => {
        existingLocalFolders.push([folderitem.uid, folderitem.modified]);
      });

    const remoteFolders = await this.folderService.getDiffCloudToApp(existingLocalFolders).toPromise();

    remoteFolders.forEach((remoteFolder: Folder) => {
      let targetLocalFolder = userStorageData.foldersData.find((localFolder => localFolder.uid === remoteFolder.uid));
      if (!targetLocalFolder) {
        const localFolder = this.folderToFolderItemAdapter.adapt(remoteFolder);
        userStorageData.foldersData.push(localFolder);
      } else {
        targetLocalFolder.syncId = 0;
        targetLocalFolder.parentSyncId = 0;
        targetLocalFolder = this.folderToFolderItemAdapter.adapt(remoteFolder);
      }
    });

    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Updating/Adding different version of folders from app to the backend
   * @returns {Promise<void>}
   */
  public async syncFoldersAppToCloud(): Promise<void> {
    const userStorageData = await this.storageService.getUserDataFromStorage();
    const syncedIds = [];
    let remoteFolder: Folder;

    for (const localFolderItemParent of this.getAllLocalNotSyncedFolders(userStorageData)) {

      if (syncedIds.indexOf(localFolderItemParent.syncId) === -1) {
        const localFolder = this.folderItemToFolderAdapter.adapt(localFolderItemParent);

        if (!localFolderItemParent.uid) {
          remoteFolder = await this.folderService.addFolder(localFolder).toPromise();
        } else {
          remoteFolder = await this.folderService.updateFolder(localFolder).toPromise();
        }

        localFolderItemParent.uid = remoteFolder.uid;
        localFolderItemParent.modified = remoteFolder.modifiedUtc;

        syncedIds.push(localFolderItemParent.syncId);
      }

      if (localFolderItemParent.folderUids) {
        for (const syncId of localFolderItemParent.folderUids) {

          if (syncedIds.indexOf(syncId) === -1) {
            const localFolderItemChild = userStorageData.foldersData
              .find((localChildFolder => localChildFolder.syncId > 0 && localChildFolder.syncId === syncId));

            localFolderItemChild.parentUid = localFolderItemParent.uid;
            const localFolder = this.folderItemToFolderAdapter.adapt(localFolderItemChild);

            if (!localFolderItemChild.uid) {
              remoteFolder = await this.folderService.addFolder(localFolder).toPromise();
            } else {
              remoteFolder = await this.folderService.updateFolder(localFolder).toPromise();
            }

            localFolderItemChild.uid = remoteFolder.uid;
            localFolderItemChild.modified = remoteFolder.modifiedUtc;

            syncedIds.push(syncId);
          }
        }
      }
    }

    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Creates (send to serve) all local folders that still not exist on serve
   * @returns {Promise<void>}
   */
  private async syncLocalNonExistingFolders(): Promise<void> {
    const userStorageData = await this.storageService.getUserDataFromStorage();
    const syncedIds = [];

    for (const localFolderParent of this.getAllLocalNotSyncedFolders(userStorageData)) {

      if (syncedIds.indexOf(localFolderParent.syncId) === -1) {
        const folder = this.folderItemToFolderAdapter.adapt(localFolderParent);

        let newCreatedFolder: Folder;
        if (!localFolderParent.uid) {
          newCreatedFolder = await this.folderService.addFolder(folder).toPromise();
        } else {
          newCreatedFolder = await this.folderService.updateFolder(folder).toPromise();
        }

        syncedIds.push(localFolderParent.syncId);
      }

      if (localFolderParent.folderUids && localFolderParent.folderUids.length > 0) {
        await Promise.all(localFolderParent.folderUids.map(async (syncId) => {

          if (syncedIds.indexOf(syncId) === -1) {
            const localFolderIndex = userStorageData.foldersData
              .findIndex((localChildFolder => localChildFolder.syncId > 0 && localChildFolder.syncId === syncId));

            const localFolderChild = userStorageData.foldersData[localFolderIndex] as FolderItem;

            if (localFolderChild.uid !== localFolderParent.uid) {
              localFolderChild.parentUid = localFolderParent.uid;
            }

            const folder = this.folderItemToFolderAdapter.adapt(localFolderChild);

            let newCreatedFolder: Folder;
            if (!localFolderChild.uid) {
              newCreatedFolder = await this.folderService.addFolder(folder).toPromise();
            } else {
              newCreatedFolder = await this.folderService.updateFolder(folder).toPromise();
            }

            localFolderChild.uid = newCreatedFolder.uid;
            localFolderChild.modified = newCreatedFolder.modifiedUtc;

            syncedIds.push(syncId);
          }
        }));
      }
    }

    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Returns all not synced folders (without uid)
   * @param {UserStorageData} userStorageData
   * @returns {FolderItem[]}
   */
  private getAllLocalNotSyncedFolders(userStorageData: UserStorageData): FolderItem[] {
    return userStorageData.foldersData.filter((folderItem: FolderItem) => folderItem.syncId > 0);
  }

  /**
   * Returns all not folders that have uid
   * @param {UserStorageData} userStorageData
   * @returns {FolderItem[]}
   */
  private getAllLocalExistingFolders(userStorageData: UserStorageData): FolderItem[] {
    return userStorageData.foldersData.filter((folderItem: FolderItem) => folderItem.uid > 0);
  }
}
