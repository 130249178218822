import { UserInterface } from './user.interface';
/**
 * Describes all user fields, end-user accessible as well systems
 * @class User
 */
export class User implements UserInterface {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  languageUid: number;
  timeZone: number;
  userUid: number;
  profileImageLink: string;
  token: string;
  premiumUser: boolean;
}
