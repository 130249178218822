import { AdapterInterface } from '../../system/http-base/adapter.interface';
import { Injectable } from '@angular/core';
import { Tactic } from '../model/tactic.model';
import { TacticItem } from '../../build/model/tactic.item';

/**
 * Creates Adapter service for decoupling REST API objects with the internal entities
 * @class TacticAdapter
 */
@Injectable({
  providedIn: 'root',
})
export class TacticItemToTacticAdapter implements AdapterInterface<Tactic> {
  /**
   * Creates a new Tactic object with data comes from the REST API
   * @param {any} item
   * @returns {Tactic}
   */
  adapt(tacticItem: TacticItem): Tactic {
    const tactic = new Tactic();
    // @todo get from destination folderUid
    tactic.uid = tacticItem.uid;
    tactic.tacticsFolderUid = tacticItem.folderUid;
    tactic.created = new Date(tacticItem.created).getTime() ,
    tactic.modifiedUtc = tacticItem.modified ,
    tactic.deleted = tacticItem.deleted || 0;
    tactic.name = tacticItem.name;
    tactic.tacticTypes = tacticItem.type;
    tactic.link = tacticItem.link;
    tactic.linkMp4 = tacticItem.linkMp4;
    tactic.linkThumb = tacticItem.linkThumb;
    tactic.image = tacticItem.image;
    tactic.data = JSON.stringify(tacticItem.data);

    return tactic;
  }
}
