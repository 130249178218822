import { AppConfig } from 'app/config/app.config';
import { ExtensionTypes } from 'app/core/drawing/services/fabric/fabric/extensions/models/extension-types/extension.types';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initExtraCircleExtension(): any {
  if (fabric.BaseExtraCircle === undefined) {
    fabric.BaseExtraCircle = fabric.util.createClass(fabric.Circle, {
      type: ExtensionTypes.EXTRA_END_POINT_CIRCLE_EXTENSION,

      initialize(options: any = {}) {
        this.callSuper('initialize', options);

        if (!this.id) {
          this.set('id', ExtensionTypes.EXTRA_END_POINT_CIRCLE_EXTENSION + '_' + getRandomId());
        }

        if (!this.subType) {
          this.subType = ExtensionTypes.EXTRA_END_POINT_CIRCLE_EXTENSION;
        }

        this.radius = 5;
        this.strokeWidth = 5;
        this.fill = 'black';
        this.stroke = 'black';
        this.originY = 'center';
        this.originX = 'center';
        this.fill = 'black';
        this.originX = 'center';
        this.originY = 'center';
        this.selectable = false;
        this.hasControls = false;
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          id: this.id,
          referenceGroupId: this.referenceGroupId,
          referenceId: this.referenceId,
          subType: this.subType || ExtensionTypes.EXTRA_END_POINT_CIRCLE_EXTENSION,
          name: this.name,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated
        });
      },
    });

    fabric.BaseExtraCircle.fromObject = (object, callback) => {
      callback(new fabric.BaseExtraCircle(object));
    };
  }
}

export function updateExtraPointCircle(object: any, options: any): void {
  object.set({ left: options.left, top: options.top });
}