import { HttpClient, HttpParams } from '@angular/common/http';

import { AppConfig } from 'app/config/app.config';
import { HttpBaseService } from '../system/http-base/http-base.service';
import { Injectable } from '@angular/core';
import { IonicAuthStorageService } from '../system/storage/auth/ionic.storage.auth.service';
import { LanguageService } from './../general/language/lang.service';
import { Languages } from '../general/language/model/lang.models';
import { Observable } from 'rxjs';
import { StorageService } from '../system/storage/storage/storage.service';
import { SyncFactoryService } from '../sync/factory/sync-factory.service';
import { SyncStagesTypes } from '../sync/stages/sync.stages.types';
import { TranslationService } from '../system/translation/translation.service';
import { UploadService } from '../general/upload/upload.service';
import { User } from './model/user.model';
import { UserAdapter } from './adapters/user.adapter';
import { UserInterface } from './model/user.interface';
import { UserLoginAdapter } from './adapters/user.login.adapter';
import { UserRegisterAdapter } from './adapters/user.register.adapter';

/**
 * Provides managing of user accounts (creation, update) data through REST API
 * @class UserService
 * @extends {HttpBaseService}
 */
@Injectable({
  providedIn: 'root',
})
export class UserService extends HttpBaseService {
  /**
   * Provides main path for this particular service
   * @type {string}
   * @memberof UserService
   */
  url: string;

  /**
   * Extends HttpBaseService which is responsible for communication with the REST API
   * @param {HttpClient} http
   * @param {UserRegisterAdapter} userRegisterAdapter
   * @param {UserLoginAdapter} userLoginAdapter
   * @param {UserAdapter} userAdapter
   * @param {StorageService} storageService
   * @param {TranslationService} translationService
   */
  constructor(
    private http: HttpClient,
    private userRegisterAdapter: UserRegisterAdapter,
    private userLoginAdapter: UserLoginAdapter,
    private userAdapter: UserAdapter,
    private ionicAuthStorageService: IonicAuthStorageService,
    private storageService: StorageService,
    private languageService: LanguageService,
    private translationService: TranslationService,
    private uploadService: UploadService,
    private syncFactoryService: SyncFactoryService
  ) {
    super();
    this.url = `${this.baseUrl}/tactics/user`;
  }

  /**
   * Post request to reset user's password
   * @param {User} user
   * @returns {Observable<User>}
   */
  public resetPassword(user: User): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .post(`${this.url}/renew-password`, {
          username: user.email
        }).subscribe((data) => {
          observer.next(data);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Post request creates a new user
   * @param {User} user
   * @returns {Observable<User>}
   */
  public register(user: User): Observable<User> {
    return new Observable<User>((observer) => {
      this.http
        .post(this.url, {
          email: user.email,
          password: user.password,
          firstname: user.firstName,
          lastname: user.lastName,
          timezone: user.timeZone,
          languageUid: user.languageUid,
        }).subscribe((data) => {
          observer.next(this.userRegisterAdapter.adapt(data));
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Post request returns a Bearer Token that is needed for future requests
   * @param {User} user
   * @returns {Observable<User>}
   */
  public login(user: UserInterface): Observable<UserInterface> {
    return new Observable<UserInterface>((observer) => {
      this.http
        .post(`${this.url}/login`, {
          username: user.email,
          password: user.password,
        }).subscribe(async (data) => {
          const userAdapted = this.userLoginAdapter.adapt(data);
          await this.setTokenToStorage(userAdapted);
          await this.setLanguageToStorage(userAdapted);
          await this.setAvatarToStorage(userAdapted);
          await this.setPremiumUser(userAdapted);
          await this.updateUserPremiumState().toPromise();

          const syncService = await this.syncFactoryService.getSyncService();
          await syncService.startFullSync(SyncStagesTypes.init);

          observer.next(userAdapted);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Get request returns all user data
   * @returns {Observable<UserInterface>}
   */
  public getUser(showCachedVersion?: boolean): Observable<UserInterface> {
    let params = {};

    if (showCachedVersion) {
      params = new HttpParams()
        .set('showCachedVersion', 'true');
    }

    return new Observable<User>((observer) => {
      this.http.get(this.url, { params }).subscribe((data) => {
        observer.next(this.userAdapter.adapt(data));
        observer.complete();
      }, (error) => { observer.error(error); });
    });
  }

  /**
   * Put request updates user data
   * @param {User} user
   * @returns {Observable<User>}
   */
  public updateUser(user: UserInterface): Observable<UserInterface> {
    return new Observable<UserInterface>((observer) => {
      this.http
        .put(this.url, {
          username: user.email,
          firstname: user.firstName,
          lastname: user.lastName,
          timezone: user.timeZone,
          password: user.password,
          languageUid: user.languageUid,
        }).subscribe(async (data) => {
          const userAdapted = await this.getUser().toPromise();
          await this.setLanguageToStorage(userAdapted);
          observer.next(userAdapted);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Post request updates user password
   * @param {string} oldPassword
   * @param {string} newPassword
   * @returns {Observable<any>}
   */
  public changePass(oldPassword: string, newPassword: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .post(`${this.url}/change-password`, {
          old_password: oldPassword,
          new_password: newPassword,
        }).subscribe((data) => {
          observer.next(data);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Get request gets a new token
   * @returns {Observable<string>}
   */
  public getRefreshToken(): Observable<string> {
    return new Observable<any>((observer) => {
      this.http
        .get(`${this.baseUrl}/tactics/user/regresh-token`).subscribe(async (data: string) => {
          await this.ionicAuthStorageService.setToken(data);
          observer.next(data);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Get request gets actual user subscription, updates local storage
   * @returns {Observable<string>}
   */
  public updateUserPremiumState(): Observable<string> {
    return new Observable<any>((observer) => {
      this.http
        .get(`${this.baseUrl}/tactics/user/subscription`).subscribe(async (data: any) => {
          const userStorageData = await this.storageService.getUserDataFromStorage();
          userStorageData.premiumUser = data.status as boolean;
          await this.storageService.setUserDataToStorage(userStorageData);
          observer.next(data);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Get request destroy current created token
   * @returns {Observable<any>}
   */
  public logout(): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .get(`${this.baseUrl}/tactics/user/logout`).subscribe((data) => {
          observer.next(data);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }


  /**
   * Uploads user's avatar image
   * @param {FormData} formData
   * @returns {Observable<any>}
   * @memberof UserService
   */
  public uploadAvatar(formData: FormData): Observable<any> {
    return new Observable((observer) => {
      this.http.post(`${this.url}/image`, formData).subscribe(async (data: any) => {
        const user = new User();
        user.profileImageLink = data.link;
        await this.setAvatarToStorage(user);
        observer.next(data.link);
        observer.complete();
      }, (error) => { observer.error(error); });
    });
  }

  /**
   * Gets predefined static timezones
   * @returns {Observable<any>}
   */
  public getTimeZones(): Observable<any> {
    return new Observable<any>((observer) => {
      const timeZones = [
        { uid: 1, name: 'Line Islands Time (14)' },
        { uid: 2, name: 'West Samoa Time (13)' },
        { uid: 3, name: 'Fiji Time (12)' },
        { uid: 4, name: 'Australian Eastern Time (11)' },
        { uid: 5, name: 'Australian Eastern Time (Brisbane, Sydney) (10)' },
        { uid: 6, name: 'Adelaide (9.5)' },
        { uid: 7, name: 'Japan Standard Time (9)' },
        { uid: 8, name: 'Kuala Lumpur, Singapur, Beijing, Perth, Taipeh (8)' },
        { uid: 9, name: 'Bangkok, Jakarta (7)' },
        { uid: 10, name: 'Dakka, Astana (6)' },
        { uid: 11, name: 'Katmandu (5.75)' },
        { uid: 12, name: 'Chennai, Mumbai, New Dehli (5.5)' },
        { uid: 13, name: 'Pakistan Standard Time (5)' },
        { uid: 14, name: 'Kabul (4.5)' },
        { uid: 15, name: 'Moscow Standard Time (4)' },
        { uid: 16, name: 'Eastern European Summer Time (3)' },
        { uid: 17, name: 'Eastern European Time (Helsinki, Athen) (2)' },
        { uid: 18, name: 'Berlin, Roma, Madrid, Paris (1)' },
        { uid: 19, name: 'Dublin, Edingburgh, Lisbon, London (0)' },
        { uid: 20, name: 'East Greenland Time (-1)' },
        { uid: 21, name: 'Western Greenland Summer Time (-2)' },
        { uid: 22, name: 'Buenos Aires, Brasilia, Salvador, Santiago (-3)' },
        { uid: 23, name: 'Newfoundland (-3.5)' },
        { uid: 24, name: 'Atlantic Time (Canada) (-4)' },
        { uid: 25, name: 'Caracas Time (-4.5)' },
        { uid: 26, name: 'Eastern Standard Time (USA & Canada) (-5)' },
        { uid: 27, name: 'Central Standard Time (USA & Canada) (-6)' },
        { uid: 28, name: 'Mountain Standard Time (USA & Canada) (-7)' },
        { uid: 29, name: 'Pacific Standard Time (USA & Canada) (-8)' },
        { uid: 30, name: 'Alaska Standard Time (-9)' },
        { uid: 31, name: 'Hawaii-Aleutian Standard Time (-10)' },
        { uid: 32, name: 'Samoa Standard Time (-11)' },
        { uid: 33, name: 'Yankee Time Zone (-12)' }
      ];
      observer.next(timeZones);
      observer.complete();
    });
  }

  /**
   * Sets user's token to the storage
   * @param {UserInterface} user
   */
  private async setTokenToStorage(user: UserInterface) {
    await this.ionicAuthStorageService.setToken(user.token);
  }

  /**
   * Sets user's premium subscription flag
   * @param {UserInterface} user
   */
  private async setPremiumUser(user: UserInterface) {
    const userStorageData = await this.storageService.getUserDataFromStorage();
    userStorageData.premiumUser = user.premiumUser;
    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Sets user's prefered language to the storage and update the globalization of the app
   * @param {UserInterface} user
   */
  private async setLanguageToStorage(user: UserInterface) {
    const userStorageData = await this.storageService.getUserDataFromStorage();
    const targetLanguage: Languages = await this.languageService.getLanguageByUid(user.languageUid);

    if (targetLanguage) {
      userStorageData.languageCode = targetLanguage.code;
    }

    await this.storageService.setUserDataToStorage(userStorageData);
    await this.translationService.setLanguage(targetLanguage.code);
  }

  /**
   * Sets user's avatar to the storage as like base64 string
   * @param {UserInterface} user
   */
  private async setAvatarToStorage(user: UserInterface) {
    const userStorageData = await this.storageService.getUserDataFromStorage();

    userStorageData.avatarImage = await this.uploadService.getFileToBase64(user.profileImageLink) || AppConfig.DefaultAvatar.link;

    await this.storageService.setUserDataToStorage(userStorageData);
  }
}
