export enum AppRoutes {
    LOGIN = 'login',
    LOGOUT = 'logout',
    REGISTER = 'register',
    IMAGE_TOOL = 'image-tool',
    PLAYER_TOOL = 'player-tool',
    ANIMATION_TOOL = 'animation-tool',
    MY_FOLDERS = 'my-folders',
    STARTUP = 'startup',
    USER_FILES = 'user-files',
    SELECT_TACTIC_TEMPLATE = 'select-tactic-template',
    SELECT_FOLDER_TEMPLATE = 'select-folder-template',
    CANCEL_SAVE = 'cancel-save',
    FORGOTTEN_PASSWORD = 'forgotten-password',
    FORGOTTEN_PASSWORD_CONFIRM = 'forgotten-password-confirm',
    TERMS = 'terms-of-use',
    SETTINGS = 'settings',
    CHANGE_PASSWORD = 'change-password',
    PREMIUM_REQUIRED = 'premium-required',
    SPLASH_SCREEN = 'splash-screen',
    SELECT_DESTINATION = 'select-destination',
    MODAL_DEMO = 'modal-demo',
    MOCK = 'mock',
    CREATE_FOLDER = 'create-folder'
}
