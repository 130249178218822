export enum MiscTypes {
    // OBJECTS_OUTDOOR = 'OBJECTS_OUTDOOR',
    // OBJECTS_INDOOR = 'OBJECTS_INDOOR',
    BALLS = 'BALLS',
    GOALS = 'GOALS',
    GOALS_MEDIUM = 'GOALS_MEDIUM',
    GOALS_SMALL = 'GOALS_SMALL',
    BAZOOKA = 'BAZOOKA',
    EQUIPMENT_COLORS = 'EQUIPMENT_COLORS',
    EQUIPMENT_DUMMIES = 'EQUIPMENT_DUMMIES',
    EQUIPMENT_GENERAL = 'EQUIPMENT_GENERAL',
    EQUIPMENT_INDOOR = 'EQUIPMENT_INDOOR',
    TACTICS = 'TACTICS',
    MISC_OBJECTS = 'MISC_OBJECTS',
    BUNDESLIGA = 'BUNDESLIGA',
    UEFA_CL = 'UEFA_CL',
    RESWITCH = 'RESWITCH',
    SKILLSHIRTZ = 'SKILLSHIRTZ'
}
