import { ArrowLineThick } from 'app/core/drawing/models/arrow-line/arrow-line-thick/arrow.line.thick';
import { ArrowTypes } from 'app/core/drawing/models/arrow-types/arrowtypes';
import { CircleTypes } from 'app/core/drawing/models/circle-types/circle.types';
import { ExtensionTypes } from 'app/core/drawing/services/fabric/fabric/extensions/models/extension-types/extension.types';
import { LineTypes } from 'app/core/drawing/models/line-types/line.types';
import { PathTypes } from 'app/core/drawing/models/path-types/path.types';
import { ThicknessTypes } from 'app/core/drawing/models/thickness-types/thickness.line';
import { ThinLine } from 'app/core/drawing/models/thickness-line/thin-line/thin.line';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initPathExtension(): any {
  if (fabric.BasePath === undefined) {
    fabric.BasePath = fabric.util.createClass(fabric.Path, {

      type: ExtensionTypes.PATH_EXTENSION,

      initialize(path = null, options: any = {}, noVisible?: boolean) {
        options.strokeWidth = options.strokeWidth || new ThinLine().width;
        options.stroke = options.stroke || 'black';
        options.selectable = true;
        options.lockScalingX = true;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.lockScalingY = true;
        options.lockUniScaling = true;
        options.lockRotation = true;
        options.hasBorders = false;
        options.hasControls = false;

        this.callSuper('initialize', path, options);

        if (!this.id) {
          this.set('id', ExtensionTypes.PATH_EXTENSION + '_' + getRandomId());
        }

        if (!this.actionType) {
          this.actionType = PathTypes.PATH;
        }

        if (!this.actionLineType) {
          this.actionLineType = LineTypes.SOLID_LINE;
        }

        if (!this.arrowType) {
          this.arrowType = new ArrowLineThick();
        }

        if (!this.actionTicknessType) {
          this.actionTicknessType = ThicknessTypes.THIN_LINE;
        }

        if (!this.subType) {
          this.subType = ExtensionTypes.PATH_EXTENSION;
        }

        if (noVisible !== undefined) {
          this.noVisible = true;
          this.visible = false;
        }
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          arrowType: this.arrowType,
          referenceGroupId: this.referenceGroupId,
          noVisible: this.noVisible,
          actionTicknessType: this.actionTicknessType || ThicknessTypes.THIN_LINE,
          actionType: this.actionType || PathTypes.PATH,
          actionLineType: this.actionLineType || LineTypes.SOLID_LINE,
          visibleControlPointsIndex: this.visibleControlPointsIndex || 0,
          id: this.id,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated
        });
      },
    });

    fabric.BasePath.fromObject = (object: any, callback: any) => {
      const { path } = object;
      callback(new fabric.BasePath(path, object, object.noVisible));
    };
  }
}

export function setPathDimension(pathObject: any, visibility = true): void {
  const dims = pathObject._calcDimensions();
  pathObject.set({
    width: dims.width,
    height: dims.height,
    left: dims.left,
    top: dims.top,
    pathOffset: {
      x: dims.width / 2 + dims.left,
      y: dims.height / 2 + dims.top
    },
    objectCaching: false,
    dirty: true,
    // we hide path until last frame is shown
    visible: visibility,
  });

  pathObject.setCoords();
}