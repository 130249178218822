import { AppConfig } from 'app/config/app.config';
import { TacticTypes } from '../../tactic/model/tactic.types';

export class TacticItem {

    constructor(type?: TacticTypes) {
        this.type = type;
    }

    public globalDuration: number = AppConfig.Canvas.defaultFrameDuration;
    public name: string;
    public uid: number;
    public tacticUid: number;
    public type: TacticTypes;
    public folderUid: number;
    public created: Date;
    public modified: number;
    public linkThumb: string;
    public thumbData: string;
    public link: string;
    public linkData: string;
    public linkMp4: string;
    public image: string;
    public syncId = 0;
    public parentSyncId = 0;
    public deleted = 0;
    public data = [];
}
