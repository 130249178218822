import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { ToastService } from 'app/core/services/general/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';

/**
 * Handles all http exceptions triggered inside the application.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {

  /**
   * Stores subscription 
   * @type {Subscription}
   */
  private subscription: Subscription;

  /**
   * Provides mapping between error status and the translation keys
   */
  private errorStates = { 500: 'SERVER_ERROR', 0: 'NETWORK_ERROR' };

  constructor(private translate: TranslateService, private toastService: ToastService) {

  }

  /**
   * Identifies and handles Server/Network errors
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          if (this.errorStates[error.status]) {
            if (this.subscription) {
              this.subscription.unsubscribe();
            }

            this.subscription = this.translate.get(this.errorStates[error.status]).subscribe((translationText) => {
              this.toastService.presentErrorToast(translationText);
            });
          }
        }

        return throwError(error);
      })
    );
  }
}