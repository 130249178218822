import { Folder } from './model/folder.model';
import { FolderAdapter } from './adapters/folder.adapter';
import { FolderTypes } from 'app/core/services/folder/model/folder.types';
import { HttpBaseService } from '../system/http-base/http-base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Provides managing (creation, obtaining) folder structure data through REST API
 * @class FolderService
 * @extends {HttpBaseService}
 */
@Injectable({
  providedIn: 'root',
})
export class FolderService extends HttpBaseService {
  /**
   * Extends HttpBaseService which is responsible for communication with the REST API
   * @param {HttpClient} http
   * @param folderAdapter
   */
  constructor(private http: HttpClient, private folderAdapter: FolderAdapter) {
    super();
  }

  /**
   * Get request gets all public/templates tactics
   * @returns {Observable<Tactic[]>}
   */
  public getTemplates(): Observable<Folder[]> {
    return new Observable<Folder[]>((observer) => {
      this.http
        .get(`${this.baseUrl}/tactics/templates`).subscribe((data: Array<any>) => {
          let folders = data.map((item) => this.folderAdapter.adapt(item));
          folders = folders.filter((folder) => folder.deleted === 0);

          observer.next(folders);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Get request gets all folders of an user
   * @returns {Observable<Folder>}
   */
  getTacticFolders(): Observable<Folder[]> {
    return new Observable<Folder[]>((observer) => {
      this.http
        .post(`${this.baseUrl}/tactics/tacticfolders`, []).subscribe((data: Array<any>) => {
          let folders = data.map((item) => this.folderAdapter.adapt(item));
          folders = folders.filter((folder) => folder.deleted === 0);

          observer.next(folders);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Post request gets the data of all different folders
   * @param {any} body
   * @returns {Observable<Folder>}
   */
  getDiffCloudToApp(body: any): Observable<Folder[]> {
    return new Observable<Folder[]>((observer) => {
      this.http
        .post(`${this.baseUrl}/tactics/tacticfolders`, body).subscribe((data: Array<any>) => {
          let folders = data.map((item) => this.folderAdapter.adapt(item));
          folders = folders.filter((folder) => folder.deleted === 0);

          // @todo remove this after rest api is updated
          folders = folders.filter((folder) => folder.uid !== 1);

          observer.next(folders);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * DEPRECATED
   * Post request gets the uids of all different folders
   * @param {any} body
   * @returns {Observable<any>}
   */
  __getDiffAppToCloud(body: any): Observable<number[]> {
    return new Observable<any>((observer) => {
      this.http
        .post(`${this.baseUrl}/tactics/tacticfolders/diffAppToCloud`, body).subscribe((data: any) => {
          const foldersUids = data.map((uid: number) => uid);

          observer.next(foldersUids);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Post requets gets a new folder
   * @param {Folder} folder
   * @returns {Observable<number>}
   */
  addFolder(folder: Folder): Observable<Folder> {
    return new Observable<Folder>((observer) => {
      this.http
        .post(`${this.baseUrl}/tactics/tacticfolder`, {
          app_uid: folder.appUid || 0,
          parent_uid: folder.parentUid || 0,
          name: folder.name,
          type: folder.type,
          deleted: 0
        }).subscribe((data: any) => {
          observer.next(this.folderAdapter.adapt(data.folder));
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Post requets gets a new folder
   * @param {Folder} folder
   * @returns {Observable<number>}
   */
  addDefaultFolder(): Observable<Folder> {
    return new Observable<Folder>((observer) => {
      this.http
        .post(`${this.baseUrl}/tactics/tacticfolder`, {
          app_uid: 0,
          parent_uid: 0,
          name: 'My folder',
          type: FolderTypes.PRIVATE,
          deleted: 0
        }).subscribe((data: any) => {
          observer.next(this.folderAdapter.adapt(data.folder));
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Put request updates one tactic folder.
   * @param {Folder} folder
   * @returns {Observable<number>}
   */
  updateFolder(folder: Folder): Observable<Folder> {
    return new Observable<Folder>((observer) => {
      this.http
        .put(`${this.baseUrl}/tactics/tacticfolder`, {
          server_uid: folder.uid,
          app_uid: folder.appUid || 0,
          parent_uid: folder.parentUid || 0,
          name: folder.name,
          deleted: folder.deleted,
          type: folder.type
        }).subscribe((data: any) => {
          observer.next(this.folderAdapter.adapt(data.folder));
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Deletes passed folder
   * @param {Folder} folder
   * @returns {Observable<number>}
   */
  deleteFolder(folder: Folder): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .put(`${this.baseUrl}/tactics/tacticfolder`, {
          server_uid: folder.uid,
          app_uid: folder.appUid || 0,
          parent_uid: folder.parentUid || 0,
          name: folder.name,
          deleted: 1,
          type: folder.type
        }).subscribe((data: any) => {
          observer.next(data);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }
}
