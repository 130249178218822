import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { IonicAuthStorageService } from 'app/core/services/system/storage/auth/ionic.storage.auth.service';
import { switchMap } from 'rxjs/operators';

/**
 * Handles all http requests triggered inside the application and apply to the http header the stored token.
 * @class TokenInterceptorService
 */
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  /**
   * Contains a interface to obtains the stored token
   * @param {IonicAuthStorageService} auth
   */
  constructor(private auth: IonicAuthStorageService) { }

  /**
   * Identifies and handles a given JSONP request.
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Converts Promise to Observable,
    // because ionic storage get and set methods returns Promises but intercept must return Observable.
    return from(this.auth.getToken()).pipe(
      switchMap((token: string) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        return next.handle(request);
      })
    );
  }
}
