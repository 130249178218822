import { Injectable } from '@angular/core';
import { Languages } from '../model/lang.models';
import { AdapterInterface } from 'app/core/services/system/http-base/adapter.interface';

/**
 * Creates Adapter service for decoupling REST API objects with the internal entities
 * @class LanguageAdapter
 */
@Injectable({
    providedIn: 'root',
  })
  export class LanguageAdapter implements AdapterInterface<Languages> {
    /**
     * Creates a new Languages object with data comes from the REST API
     * @param {any} item
     * @returns {Languages}
     */
    adapt(item: any): Languages {
      const lang = new Languages();
      lang.uid = item.uid;
      lang.code = item.code;
      lang.active = item.active;
      lang.nameCN = item.name_cn;
      lang.nameDE = item.name_de;
      lang.nameEN = item.name_en;
      lang.nameES = item.name_es;
      lang.nameFR = item.name_fr;
      lang.nameHR = item.name_hr;
      lang.nameHU = item.name_hr;
      lang.nameIT = item.name_it;
      
      return lang;
    }
  }