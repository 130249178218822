import { ExtensionTypes } from '../models/extension-types/extension.types';
import { fabric } from 'fabric-with-gestures';

export function initPlayerExtension(): any {
  if (fabric.Player === undefined) {
    fabric.Player = fabric.util.createClass(fabric.Image, {

      type: ExtensionTypes.PLAYER,

      initialize(element: any, options: any) {
        this.callSuper('initialize', element, options);
      },


      toObject() {
        var color1, color2;
        if (this.group.color1)
          color1 = this.group.color1;
        else {
          color1 = this.color1;
        }

        if (this.group.color2)
          color2 = this.group.color2;
        else {
          color2 = this.color2;
        }
        return fabric.util.object.extend(this.callSuper('toObject'), {
          src: this.group.imageURL,
          color1: color1,
          color2: color2,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated
        });
      },
    });

    // loading objects from json data
    fabric.Player.fromObject = (objectSaved, callback) => {
      fabric.loadSVGFromURL(objectSaved.src, (objects: any, options: any) => {
        objects.forEach((object) => {

          if ((typeof object.fill === 'string' || object.fill instanceof String) && (object.fill.toLowerCase() === '#ff862a'
            || object.fill.toLowerCase() === '#f9ff00'
            || object.fill.toLowerCase() === '#c40000'
            || object.fill.toLowerCase() === '#004200')
            && objectSaved.color1 && objectSaved.color1.length > 2) {

            object.fill = objectSaved.color1;
          }
          if ((typeof object.fill === 'string' || object.fill instanceof String) && (object.fill.toLowerCase() === '#3cb9ce'
            || object.fill.toLowerCase() === '#ffffa1'
            || object.fill.toLowerCase() === '#a9a9aa'
            || object.fill.toLowerCase() === '#f4f4f4'
            || object.fill.toLowerCase() === '#eac55c'
            || object.fill.toLowerCase() === '#000001')
            && objectSaved.color2 && objectSaved.color2.length > 2) {
            object.fill = objectSaved.color2;
          }
        });

        const svg = fabric.util.groupSVGElements(objects, options);
        svg.scale(10);

        svg.cloneAsImage(img => {
          objectSaved.width = img.width;
          objectSaved.height = img.height;
          const player = new fabric.Player(img._element, objectSaved);

          callback(player);
        });
      });
    };
  }
}
