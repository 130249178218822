import { Folder } from 'app/core/services/folder/model/folder.model';
import { FolderItem } from 'app/core/services/build/model/folder.item';
import { FolderService } from 'app/core/services/folder/folder.service';
import { FolderToFolderItemAdapter } from 'app/core/services/folder/adapters/folder.item.adapter';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { PlatformDetectorService } from 'app/core/services/system/platform/platform-detector.service';
import { StorageService } from 'app/core/services/system/storage/storage/storage.service';
import { Subscription } from 'rxjs';
import { SyncAbstractService } from '../../sync.abstract.service';
import { SyncServiceInterface } from '../../interfaces/sync.service.interface';
import { SyncStagesTypes } from '../../stages/sync.stages.types';
import { SyncSubjectService } from '../../sync.subject.service';
import { Tactic } from 'app/core/services/tactic/model/tactic.model';
import { TacticItem } from 'app/core/services/build/model/tactic.item';
import { TacticService } from 'app/core/services/tactic/tactic.service';
import { TacticToTacticItemAdapter } from 'app/core/services/tactic/adapters/tactic.item.adapter';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SyncBrowserService extends SyncAbstractService implements SyncServiceInterface {

  /**
   * Stores subscription 
   * @type {Subscription}
   */
  private subscription: Subscription;

  constructor(
    protected storageService: StorageService,
    protected loadingController: LoadingController,
    protected translateService: TranslateService,
    protected syncSubjectService: SyncSubjectService,
    private folderService: FolderService,
    private tacticService: TacticService,
    private folderToFolderItemAdapter: FolderToFolderItemAdapter,
    private tacticToTacticItemAdapter: TacticToTacticItemAdapter,
    private platformDetectorService: PlatformDetectorService,
  ) {
    super(loadingController, translateService, storageService, syncSubjectService);
  }

  /**
   * Executes predefined steps for syncing the app version
   */
  public async executeSyncSteps(): Promise<void> {
    this.loadingController.getTop().then(v => v ? this.loadingController.dismiss() : null);
    const loading = await this.createLoading();

    loading.present();

    await this.syncFoldersCloudToApp();
    await this.syncTacticsCloudToApp();
    await this.updateFolderItemsBrowser();

    loading.dismiss();
  }

  /**
   * Runs synchronization process web version
   * @param {SyncStagesTypes} syncStageType
   * @returns {Promise<void>}
   */
  public async startFullSync(syncStageType: SyncStagesTypes): Promise<void> {
    return new Promise(async (resolve) => {
      await this.executeSyncSteps();
      resolve();
    }).then(() => {
      this.emitSyncSubject(syncStageType);
    });
  }

  /**
   * Updates userStorage data (FolderItem) with new "fresh" data comes from the api (using from browser version)
   * @returns {Promise<void>}
   */
  private async syncFoldersCloudToApp(): Promise<void> {
    const foldersItems: FolderItem[] = [];

    const userStorageData = await this.storageService.getUserDataFromStorage();
    const folders = await this.folderService.getTacticFolders().toPromise();

    folders.forEach((folder: Folder) => {
      const folderItem = this.folderToFolderItemAdapter.adapt(folder);
      foldersItems.push(folderItem);
    });

    userStorageData.foldersData = foldersItems;
    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Updates userStorage data (TacticItem) with new "fresh" data comes from the api (using from browser version)
   * @returns {Promise<void>}
   */
  private async syncTacticsCloudToApp(): Promise<void> {
    const tacticsItems: TacticItem[] = [];

    const userStorageData = await this.storageService.getUserDataFromStorage();
    const tactics = await this.tacticService.getAllTactic().toPromise();

    tactics.forEach(async (tactic: Tactic) => {
      const tacticItem = await this.tacticToTacticItemAdapter.adapt(tactic);
      tacticsItems.push(tacticItem);
    });

    userStorageData.tacticsData = tacticsItems;
    await this.storageService.setUserDataToStorage(userStorageData);
  }
}
