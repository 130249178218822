import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CacheInterceptorService } from './core/interceptors/cache/cache.interceptor';
import { Chooser } from '@ionic-native/chooser/ngx';
import { ErrorInterceptorService } from './core/interceptors/error/error-interceptor.service';
import { File } from '@ionic-native/file/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { JwtModule } from '@auth0/angular-jwt';
import { LoaderInterceptorService } from './core/interceptors/loader/loader-interceptor.service';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { Network } from '@ionic-native/network/ngx';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SyncModule } from './shared/sync.module';
import { TokenInterceptorService } from './core/interceptors/token/token-interceptor.service';
import { createTranslateLoader } from './core/translate-loader/translate.loader';
import { DoubleTapPreventDirective } from './core/directives/double-tap-prevent/double-tap-prevent.directive';
import { ScrollBouncePreventDirective } from './core/directives/scroll-bounce-prevent/scroll-bounce-prevent.directive';

export const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptorService,
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent, MainMenuComponent, DoubleTapPreventDirective, ScrollBouncePreventDirective],
  entryComponents: [],
  imports: [
    SyncModule.forRoot(),
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        whitelistedDomains: ['*'],
        tokenGetter: () => '',
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Globalization,
    interceptorProviders,
    Chooser,
    File,
    Network,
    SocialSharing
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
