/**
 * Returns current timestamp plus random value as a uniq id
 */
export function getRandomId(): string {
  return new Date().getTime() + '_' + Math.random();
}

/**
 * Returns uniq id with 'group_reference_id'_ prefix
 * @returns {string}
 */
export function getReferenceGroupId(): string {
  return 'group_reference_id_' + getRandomId();
}

/**
 * Returns uniq id with 'group_circle_reference_id_'_ prefix
 * @returns {string}
 */
export function getReferenceCircleGroupId(): string {
  return 'group_circle_reference_id_' + getRandomId();
}


/**
 * Returns angle based on 4 poinst in the coordinate system
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @returns {number}
 */
export function calcAngle(x1: number, y1: number, x2: number, y2: number): number {
  let angel = Math.atan(Math.abs(y2 - y1) / Math.abs(x2 - x1)) * 180 / Math.PI;

  if (x1 > x2) {
    if (y1 < y2) {
      angel = 270 - angel;
    } else {
      angel = angel - 90;
    }
  } else {
    if (y1 < y2) {
      angel = angel - 270;
    } else {
      angel = 90 - angel;
    }
  }

  return angel;
}

/**
 *  * Returns angle based on deltas in the coordinate system
 * @param {number} deltaX
 * @param {number} deltaY
 * @returns {number}
 */
export function calcAngleByDeltas(deltaX: number, deltaY: number): number {
  const rad = Math.atan2(deltaY, deltaX);
  const deg = rad * (180 / Math.PI);

  return deg;
}
