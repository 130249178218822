import { ExtensionTypes } from '../models/extension-types/extension.types';
import { FreeLineTypes } from 'app/core/drawing/models/free-line-types/free.line.types';
import { ThicknessTypes } from 'app/core/drawing/models/thickness-types/thickness.line';
import { fabric } from 'fabric-with-gestures';

export function initFreeLineExtension(): any {
  if (fabric.FreeDrawingDashedLine === undefined) {


    fabric.Object.prototype.freeLineTool = function () {

    };

    fabric.FreeDrawingDashedLine = fabric.util.createClass(fabric.PencilBrush,
      {
        type: ExtensionTypes.FREE_LINE_TOOL_EXTENSION,

        initialize(canvas, options: any) {
          this.decimate = 5;
          this.callSuper('initialize', canvas);

          if (!this.actionType) {
            this.actionType = FreeLineTypes.FREE_LINE;
          }

          if (!this.subType) {
            this.subType = ExtensionTypes.FREE_LINE_TOOL_EXTENSION;
          }

          if (!this.actionTicknessType) {
            this.actionTicknessType = ThicknessTypes.NORMAL_LINE;
          }
        },

        toObject() {
          return fabric.util.object.extend(this.callSuper('toObject'), {
            actionTicknessType: this.actionTicknessType,
            actionType: FreeLineTypes.FREE_LINE,
            subType: ExtensionTypes.FREE_LINE_TOOL_EXTENSION,
            loaded: true,
            deleted: this.deleted,
            updated: this.updated,
            shouldBringToFront: this.shouldBringToFront
          });
        },
      });
  }

  fabric.FreeDrawingDashedLine.fromObject = (object, callback) => {
    callback(new fabric.FreeDrawingDashedLine(object));
  };
}