import {AppConfig} from "../../../../../../../config/app.config";
import { ArrowLineThick } from 'app/core/drawing/models/arrow-line/arrow-line-thick/arrow.line.thick';
import { ArrowTypes } from "../../../../../models/arrow-types/arrowtypes";
import { ExtensionTypes } from 'app/core/drawing/services/fabric/fabric/extensions/models/extension-types/extension.types';
import { FreeLineTypes } from 'app/core/drawing/models/free-line-types/free.line.types';
import { LineTypes } from 'app/core/drawing/models/line-types/line.types';
import { ThicknessTypes } from 'app/core/drawing/models/thickness-types/thickness.line';
import { ThinLine } from 'app/core/drawing/models/thickness-line/thin-line/thin.line';
import { WaveLineInterface } from "../../../../../models/wave-line/wave.line.interface";
import { WaveLineLeve1 } from "../../../../../models/wave-line/wave-line-smoothing-level1/wave.line.smoothing.level.1";
import { WaveLineTypes } from 'app/core/drawing/models/wave-line-types/wave.line.types';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initBaseFreeLineExtension(): any {

    if (fabric.BaseFreeLine === undefined) {

        fabric.BaseFreeLine = fabric.util.createClass(fabric.Polyline, {

            type: ExtensionTypes.FREE_LINE_EXTENSION,

            initialize(points: any, options: any = {}, noVisible?: boolean) {


                options.strokeWidth = options.strokeWidth || new ThinLine().width;
                options.stroke = options.stroke || 'black';
                options.selectable = true;
                options.fill = '';
                options.originX = 'left';
                options.originY = 'top';
                options.borderColor = AppConfig.Canvas.borderColor;
                options.objectCaching = false;
                options.dirty = true;


                this.callSuper('initialize', points, options);

                this.set({
                    hasBorders: true,
                    hasControls: false,
                });

                if (!this.id) {
                    this.set('id', ExtensionTypes.FREE_LINE_EXTENSION + '_' + getRandomId());
                }

                if (!this.actionType) {
                    this.actionType = FreeLineTypes.FREE_LINE;
                }

                if (!this.actionLineType) {
                    this.actionLineType = LineTypes.SOLID_LINE;
                }

                if (!this.arrowType) {
                    this.arrowType = new ArrowLineThick();
                }

                if (!this.actionTicknessType) {
                    this.actionTicknessType = ThicknessTypes.THIN_LINE;
                }

                if (!this.subType) {
                    this.subType = ExtensionTypes.FREE_LINE_EXTENSION;
                }

                this.waveType = options.waveType;

                if (noVisible !== undefined) {
                    this.noVisible = true;
                    this.visible = false;
                }
            },

            _render: function (ctx) {

                if (this.arrowType.type === ArrowTypes.TINY_ARROW || this.arrowType.type === ArrowTypes.TICK_ARROW) {
                    this.drawArrow(ctx, this.arrowType.x, this.arrowType.y);
                }

                if (this.arrowType.type === ArrowTypes.ONLY_POINT) {
                    this.drawPoint(ctx, this.arrowType.x);
                }

                if (this.actionLineType === LineTypes.SOLID_LINE ||
                    this.actionLineType === LineTypes.DASHED_TINY ||
                    this.actionLineType === LineTypes.DASHED_WAIDER) {

                    this.drawLine(ctx);
                }

                if (this.actionLineType === WaveLineTypes.WAVE_LINE || this.actionLineType === WaveLineTypes.WAVE_NARROW_LINE) {
                    this.drawWaveLine(ctx);
                }
            },

            drawLine: function (ctx) {

                ctx.beginPath();
                if (this.strokeDashArray) {
                    ctx.setLineDash(this.strokeDashArray);
                }

                var x = this.pathOffset.x,
                    y = this.pathOffset.y;

                for (var i = 0; i <= this.points.length - 1; i++) {

                    var currentX = this.points[i].x - x;
                    var currentY = this.points[i].y - y;

                    ctx.lineTo(currentX, currentY);
                }
                ctx.lineWidth = this.strokeWidth;
                ctx.fillStyle = this.stroke;
                ctx.stroke();
                this._renderStroke(ctx);
            },

            drawWaveLine: function (ctx) {

                var freq;
                if (this.actionLineType === WaveLineTypes.WAVE_LINE) freq = 30;
                if (this.actionLineType === WaveLineTypes.WAVE_NARROW_LINE) freq = 15;

                var x = this.pathOffset.x,
                    y = this.pathOffset.y;

                var lastX = this.points[0].x - x,
                    lastY = this.points[0].y - y;

                var endX = this.points[this.points.length - 1].x - x,
                    endY = this.points[this.points.length - 1].y - y,
                    iPoint=1;
                for (var i = this.points.length - 1; i > 0; i--) {
                    var currentX = this.points[i].x - x;
                    var currentY = this.points[i].y - y;

                    var dist = Math.sqrt(Math.pow((endX - currentX), 2) + Math.pow((endY - currentY), 2));
                    if(dist >= 5) break;
                    iPoint++;
                }

                for (var i = 1; i < this.points.length - iPoint; i++) {

                    var currentX = this.points[i].x - x;
                    var currentY = this.points[i].y - y;

                    var dist = Math.sqrt(Math.pow((lastX - currentX), 2) + Math.pow((lastY - currentY), 2));
                    if (dist >= freq) {
                        var a = Math.atan2((currentY - lastY), (currentX - lastX));
                        this.drawSine(ctx, a, lastX, lastY, dist);
                        lastX = currentX;
                        lastY = currentY;
                    }
                }

                ctx.save();
                ctx.beginPath();
                ctx.lineTo(lastX, lastY);
                ctx.lineTo(this.points[this.points.length - 1].x - x, this.points[this.points.length - 1].y - y);
                ctx.lineWidth = this.strokeWidth;
                ctx.fillStyle = this.stroke;
                ctx.stroke();

                ctx.restore();
            },

            drawSine: function (ctx, angle, tx, ty, freq) {
                var width = freq;
                var amplitude = 5;
                var frequency = 1;
                var step = 0.5;
                var i;

                ctx.save();
                //  ctx.rotate(angle)
                //ctx.strokeRect(tx, ty, 1, 1);
                ctx.restore();

                ctx.save();
                ctx.translate(tx, ty);
                ctx.rotate(angle);
                ctx.beginPath();

                ctx.moveTo(0, 0);
                var c = width / Math.PI / (frequency * 2);

                for (i = 0; i <= width+0.3; i += step) {
                    var x = amplitude * Math.sin(i / c);
                    ctx.lineTo(i, x);
                }
                ctx.lineWidth = this.strokeWidth;
                ctx.fillStyle = this.stroke;
                ctx.stroke();

                ctx.restore();
            },

            pointOnLine: function(point1, point2, dist) {
                var len = Math.sqrt(((point2.x - point1.x) * (point2.x - point1.x)) + ((point2.y - point1.y) * (point2.y - point1.y)));
                var t = (dist) / len;
                var x3 = ((1 - t) * point1.x) + (t * point2.x),
                    y3 = ((1 - t) * point1.y) + (t * point2.y);
                return new fabric.Point(x3, y3);
            },

            drawArrow: function (ctx, x, y) {

                ctx.save();
                var xOffset = this.pathOffset.x,
                    yOffset = this.pathOffset.y;


                const xDiff = this.points[this.points.length - 1].x - xOffset;
                const yDiff = this.points[this.points.length - 1].y - yOffset;

                const angle = Math.atan2((this.points[this.points.length - 1].y - this.points[this.points.length - 2].y), (this.points[this.points.length - 1].x - this.points[this.points.length - 2].x));
                ctx.translate(xDiff, yDiff);
                ctx.rotate(angle);
                ctx.beginPath();
                ctx.moveTo(x, 0);
                ctx.lineTo(-x, y);
                ctx.lineTo(-x, -y);
                ctx.closePath();
                ctx.fillStyle = this.stroke;
                ctx.fill();
                ctx.restore();
            },

            drawPoint: function (ctx, radius) {
                ctx.save();
                var x = this.pathOffset.x,
                    y = this.pathOffset.y;


                const xDiff = this.points[this.points.length - 1].x - x;
                const yDiff = this.points[this.points.length - 1].y - y;


                ctx.translate(xDiff, yDiff);
                ctx.beginPath();
                ctx.arc(0, 0, radius, 0, 2 * Math.PI, true);
                ctx.fillStyle = this.stroke;
                ctx.fill();
                ctx.restore();
            },

            point(x, y) {
                return {
                    x,
                    y,
                };
            },

            toObject() {


                return fabric.util.object.extend(this.callSuper('toObject'), {
                    arrowType: this.arrowType,
                    waveType: this.waveType,
                    strokeDashArray: this.strokeDashArray,
                    referenceGroupId: this.referenceGroupId,
                    actionTicknessType: this.actionTicknessType || ThicknessTypes.THIN_LINE,
                    actionType: this.actionType || FreeLineTypes.FREE_LINE,
                    actionLineType: this.actionLineType || LineTypes.SOLID_LINE,
                    subType: this.subType,
                    points: this.points,
                    id: this.id,
                    loaded: true,
                    deleted: this.deleted,
                    updated: this.updated
                });
            },
        });

        fabric.BaseFreeLine.fromObject = (object: any, callback: any) => {

            const polygon = new fabric.BaseFreeLine(object.points, object);

            callback(polygon);
        };
    }
}