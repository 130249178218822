import { AdapterInterface } from '../../system/http-base/adapter.interface';
import { Injectable } from '@angular/core';
import { Tactic } from '../model/tactic.model';
import { TacticAdapter } from './tactic.adapter';

/**
 * Creates Adapter service for decoupling REST API objects with the internal entities
 * @class TacticDetailsAdapter
 */
@Injectable({
  providedIn: 'root',
})
export class TacticDetailsAdapter implements AdapterInterface<Tactic> {
  constructor(private tacticAdapter: TacticAdapter) {}
  /**
   * @param {any} item
   * @returns {Tactic}
   */
  adapt(item: any): Tactic {
    const tactic: Tactic = this.tacticAdapter.adapt(item);
    let tacticDetails = new Tactic();
    tacticDetails.modifiedUtc = item.modified;
    tacticDetails.modifiedBy = item.modified_by;
    tacticDetails.data = item.data;
    tacticDetails = Object.assign(tactic, tacticDetails);

    return tacticDetails;
  }
}
