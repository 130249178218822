import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawSubjectService {

  /**
   * Uses for emitting when some action is happened render, drop etc.
   * @type {Subject<any>}
   */
  public subjectDraw: Subject<any>;


  /**
   * Uses for native actions like loading of data
   * @type {Subject<any>}
   */
  public subjectNative: Subject<any>;

  constructor(
  ) {
    this.subjectDraw = new Subject();
    this.subjectNative = new Subject();
  }
}
