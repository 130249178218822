import { FolderItem } from '../build/model/folder.item';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { StorageService } from '../system/storage/storage/storage.service';
import { SyncStagesTypes } from './stages/sync.stages.types';
import { SyncSubjectService } from './sync.subject.service';
import { TacticItem } from '../build/model/tactic.item';
import { TacticTypes } from '../tactic/model/tactic.types';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export abstract class SyncAbstractService {
  constructor(
    protected loadingController: LoadingController,
    protected translateService: TranslateService,
    protected storageService: StorageService,
    protected syncSubjectService: SyncSubjectService
  ) {
  }

  /**
   * Emits subject object by stage type
   * @param {SyncStagesTypes} [syncStageType=SyncStagesTypes.sync]
   */
  protected emitSyncSubject(syncStageType: SyncStagesTypes = SyncStagesTypes.sync) {
    this.syncSubjectService.subjectSynchronization.next(syncStageType);
  }

  /**
   * Creates and returns ionic's component of loading
   * @returns {Promise<HTMLIonLoadingElement>}
   */
  public async createLoading(): Promise<HTMLIonLoadingElement> {
    const translationText: string = await this.translateService.get('SYNC_IN_PROGRESS').toPromise();

    const loading = await this.loadingController.create({
      mode: 'md',
      message: translationText,
      duration: 30000
    });

    return loading;
  }

  /**
   * Update each folder with the items (imageCount, animationCount, parentUid, thumbs)
   * @returns {Promise<void>}
   */
  protected async updateFolderItemsBrowser(): Promise<void> {
    const userStorageData = await this.storageService.getUserDataFromStorage();

    for (const folderItem of userStorageData.foldersData) {
      const relatedImagesUids = (await this.getAllImages(folderItem.uid, TacticTypes.IMAGE));
      const relatedAnimatioinsUids = (await this.getAllImages(folderItem.uid, TacticTypes.ANIMATION));
      const subFoldersUids = (await this.getAllFolders(folderItem.uid)).filter(folderUid => folderUid !== folderItem.uid);

      folderItem.thumbs = this.getTacticsThumbsByFolderUid(folderItem.uid, userStorageData.tacticsData);
      folderItem.animationCount = relatedAnimatioinsUids.length || 0;
      folderItem.imagesCount = relatedImagesUids.length || 0;
      folderItem.folderCount = subFoldersUids.length || 0;
      folderItem.folderUids = subFoldersUids;
      folderItem.imagesUids = relatedImagesUids.concat(relatedAnimatioinsUids);
    }

    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Returns all child nodes folders uids by given folder uid
   * @param {number} folderUid
   * @returns {Promise<any[]>}
   */
  protected async getAllFolders(folderUid: number): Promise<any[]> {
    let folders = [];
    const userStorageData = await this.storageService.getUserDataFromStorage();

    const foldersUids = userStorageData.foldersData
      .filter((folder: FolderItem) => folder.parentUid > 0 && folder.parentUid === folderUid)
      .map((folder: FolderItem) => folder.uid);

    folders.push(folderUid);

    for (const folderUid of foldersUids) {
      const subFolders = await this.getAllFolders(folderUid);
      if (folders) {
        folders = folders.concat(subFolders);
      }
    }

    return folders;
  }

  /**
   * Returns all tactics uids by given folder uid
   *
   * @private
   * @param {number} folderUid
   * @param {TacticTypes} tacticTypes
   * @returns {Promise<any[]>}
   */
  protected async getAllImages(folderUid: number, tacticTypes: TacticTypes): Promise<any[]> {
    let tactics = [];
    const userStorageData = await this.storageService.getUserDataFromStorage();

    const imagesUids = userStorageData.tacticsData.filter((tactic) =>
      tactic.folderUid === folderUid &&
      tactic.type === tacticTypes).map((tactic: TacticItem) => tactic.uid);

    const foldersUids = userStorageData.foldersData
      .filter((folder: FolderItem) => folder.parentUid > 0 && folder.parentUid === folderUid)
      .map((folder: FolderItem) => folder.uid);

    tactics = tactics.concat(imagesUids);

    for (const folderUid of foldersUids) {
      tactics = tactics.concat(await this.getAllImages(folderUid, tacticTypes));
    }

    return tactics;
  }

  /**
   * Returns folder's last three tactic thumbs
   * @param {number} folderUid
   * @returns {string[]}
   */
  protected getTacticsThumbsByFolderUid(folderUid: number, tactics: TacticItem[]): string[] {
    const thumbs: string[] = [];

    tactics.filter((tactic) => tactic.folderUid === folderUid)
      .sort((a, b) => a.uid > b.uid ? 1 : -1)
      .forEach((tactic: TacticItem) => {
        if (tactic.linkThumb && tactic.linkThumb.match(/\.(jpeg|jpg|gif|png|mp4)$/) && thumbs.length < 3) {
          thumbs.push(`${tactic.linkThumb}`);
        }
      });

    return thumbs;
  }

  /**
   * Returns folder's last three tactic thumbs
   * @param {number} folderSyncId
   * @returns {string[]}
   */
  protected getTacticsThumbsByFolderSyncId(folderSyncId: number, tactics: TacticItem[]): string[] {
    const thumbs: string[] = [];

    tactics.filter((tactic) => tactic.folderUid === folderSyncId)
      .sort((a, b) => a.uid > b.uid ? 1 : -1)
      .forEach((tactic: TacticItem) => {
        if (tactic.linkThumb && tactic.linkThumb.match(/\.(jpeg|jpg|gif|png|mp4)$/) && thumbs.length < 3) {
          thumbs.push(`${tactic.linkThumb}`);
        }
      });

    return thumbs;
  }
}