import { Injectable } from '@angular/core';
import { ObjectActionsInterface } from 'app/core/drawing/interfaces/object.actions.interface';

/**
 * Represents interface for adding/removing/updating of element
 * @class ObjectActionsService
 * @implements {ObjectActionsInterface}
 */
@Injectable({
  providedIn: 'root'
})
export class ObjectActionsService implements ObjectActionsInterface {

  /**
   * Adds new element to the passed canvas
   * @param {any} data
   * @param {any} canvas
   */
  public addObject(data, canvas: any): void {
    canvas.add(data);
  }

  /**
   * Removes all objects from canvas
   * @param {any} canvas
   */
  public async removeAllObjects(canvas): Promise<void> {
    return new Promise((resolve) => {
      canvas.remove(...canvas.getObjects());
      setTimeout(() => {
        resolve();
      }, 150);
    });
  }

  /**
   * Adds multiple objects to canvas
   * @param {[]} data
   * @param {any} canvas
   */
  public addObjects(data: [], canvas: any): void {
    data.forEach(element => {
      canvas.add(element);
    });
  }

  /**
   * Returns current active object
   * @param {any} canvas
   * @returns {any}
   */
  public getActiveObject(canvas: any): any {
    return canvas.getActiveObject();
  }

  public setActiveObject(canvas: any, object) {
    return canvas.setActiveObject(object);
  }

  /**
   * Animates current passed element by passed options
   * @param {any} element
   * @param {any} canvas
   * @param {any} options
   * @param {any} callback
   */
  public animate(element, options: any, callback: any, canvas: any): void {
    element.animate(options,
      {
        duration: options.duration || 200,
        onChange: canvas.renderAll.bind(canvas),
        onComplete: callback
      }
    );
  }
}
