import { AppConfig } from 'app/config/app.config';
import { ExtensionTypes } from '../models/extension-types/extension.types';
import { TacticShapeTypes } from 'app/core/drawing/models/tactic-shape-types/tactic.shape.types';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initGroupShapeExtension(): any {
  if (fabric.CustomShapeGroup === undefined) {
    fabric.CustomShapeGroup = fabric.util.createClass(fabric.Group, {
      type: ExtensionTypes.GROUP_SHAPE_EXTENSION,

      initialize(objects, options) {
        this.callSuper('initialize', objects, options);

        if (!this.id) {
          this.set({ id: getRandomId() });
        }

        if (!this.subType) {
          this.subType = ExtensionTypes.GROUP_SHAPE_EXTENSION;
        }

        if (!this.actionType) {
          this.actionType = TacticShapeTypes.CIRCLE_TACTIC;
        }

        if (!this.isGroup) {
          this.isGroup = true;
        }
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          isGroup: this.isGroup,
          subType: ExtensionTypes.GROUP_SHAPE_EXTENSION,
          actionType: this.actionType,
          rotateAngle: this.rotateAngle || 0,
          actionName: this.actionName,
          actionSize: this.actionSize,
          imageURL: this.imageURL,
          visibleFrameIndex: this.visibleFrameIndex,
          // visibleControlPointsIndex: this.visibleControlPointsIndex,
          color1: this.color1,
          color2: this.color2,
          isLocked: this.isLocked,
          initHeight: this.initHeight,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated,
          id: this.id,
          name: this.name,
          line: this.line,
          referenceId: this.referenceId,
          referenceGroupId: this.referenceGroupId,
          isNotCenterText: this.isNotCenterText,
          shouldBringToFront: this.shouldBringToFront
        });
      },
    });

    fabric.CustomShapeGroup.fromObject = (object, callback) => {
      let _enlivenedObjects;
      // Creates corresponding fabric instances from their object representations
      fabric.util.enlivenObjects(object.objects, (enlivenedObjects) => {
        delete object.objects;
        _enlivenedObjects = enlivenedObjects;

        const group = new fabric.CustomGroup(_enlivenedObjects, object);
        const scale = object.actionSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;

        group.set({
          scaleX: scale,
          scaleY: scale
        });

        callback(group);
      });
    };
  }
}

export function toggleGroupShapePathsVisibility(canvas: any, objects: any[]): void {
  objects
    .filter(object =>
      object.subType === ExtensionTypes.GROUP_SHAPE_EXTENSION)
    .forEach(object => {
      if (object.referenceId) {
        const targetPathObject = objects.find(targetPathObject =>
          targetPathObject.id === object.referenceId);

        const targetCircleObject = objects.find(targetCircleObject =>
          targetCircleObject.subType === ExtensionTypes.MIDDLE_PATH_CIRCLE_EXTENSION &&
          targetCircleObject.referenceGroupId === targetPathObject.referenceGroupId);

        targetPathObject.set({ visible: !targetPathObject.visible });
        targetCircleObject.set({ visible: !targetCircleObject.visible });

        canvas.renderAll();
      }
    });
}
