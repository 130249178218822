import {
  calcAngle,
  calcAngleByDeltas,
  getReferenceCircleGroupId,
  getReferenceGroupId
} from 'app/core/services/system/utilities/help.methods';

import { AppConfig } from 'app/config/app.config';
import { ArrowLineInterface } from 'app/core/drawing/models/arrow-line/arrow.line.interface';
import { ArrowLineNone } from 'app/core/drawing/models/arrow-line/arrow-line-none/arrow.line.none';
import { ArrowLineThick } from 'app/core/drawing/models/arrow-line/arrow-line-thick/arrow.line.thick';
import { ArrowTypes } from 'app/core/drawing/models/arrow-types/arrowtypes';
import { CanvasEventTypes } from 'app/core/drawing/services/fabric/events/model/canvas/canvas.event.types';
import { ExtensionTypes } from '../models/extension-types/extension.types';
import { fabric } from 'fabric-with-gestures';
import { repositionTriangle } from '../triangle-extension/triangle.extension';

// @add this creator to draw-line.actions.services
export function makeDoubleLine(
  canvas: any, coords: any[],
  options: any, arrowType: ArrowLineInterface = new ArrowLineThick(),
  arrowOptions?: any) {

  const referenceGroupId = getReferenceGroupId();
  const referenceCircleGroupId = getReferenceCircleGroupId();
  const [x1, y1, x2, y2] = coords;

  const line1 = new fabric.BaseLine(coords, options);
  line1.referenceGroupId = referenceGroupId;
  line1.doubleLine = true;
  line1.arrowType = arrowType || new ArrowLineNone();

  const line2 = new fabric.BaseLine(coords, options);
  line2.referenceGroupId = referenceGroupId;
  line2.doubleLine = true;
  line2.mainDoubleLine = true;

  function makeCircle(left, top) {
    const c = new fabric.BaseCircle({
      left,
      top,
      originX: 'center',
      originY: 'center'
    });
    c.hasControls = c.hasBorders = false;
    return c;
  }

  const rect = new fabric.RectShapeHidden({
    top: y2,
    left: x2,
    width: Math.sqrt(
      (x1 - x2) ** 2 +
      (y1 - y2) ** 2
    ),
    fill: '',
    height: 10,
    angle: calcAngleByDeltas(x1 - x2, y1 - y2),
  });
  rect.referenceGroupId = referenceGroupId;

  const rectCoords = getRectCenteredCoords(rect);

  const startCircle = makeCircle(rectCoords.c1CenterX, rectCoords.c1CenterY);
  startCircle.referenceId = line1.id;
  startCircle.referenceIdTwo = line2.id;
  startCircle.referenceIdThree = rect.id;
  startCircle.subType = ExtensionTypes.START_LINE_DOUBLE_CIRCLE_EXTENSION;
  startCircle.referenceGroupId = referenceGroupId;
  startCircle.referenceCircleGroupId = referenceCircleGroupId;

  const endCircle = makeCircle(rectCoords.c2CenterX, rectCoords.c2CenterY);
  endCircle.referenceId = line1.id;
  endCircle.referenceIdTwo = line2.id;
  endCircle.referenceIdThree = rect.id;
  endCircle.subType = ExtensionTypes.END_LINE_DOUBLE_CIRCLE_EXTENSION;
  endCircle.referenceGroupId = referenceGroupId;
  endCircle.referenceCircleGroupId = referenceCircleGroupId;

  const triangle = new fabric.BaseTriangle({
    fill: arrowOptions && arrowOptions.fill,
    width: arrowType.width,
    height: arrowType.height,
    visible: arrowType.type === ArrowTypes.TICK_ARROW || arrowType.type === ArrowTypes.TINY_ARROW,
  });
  triangle.referenceId = line1.id;
  triangle.referenceGroupId = referenceGroupId;

  const extraEndPoint = new fabric.BaseExtraCircle({
    left: rectCoords.c1CenterX,
    top: rectCoords.c1CenterY,
    visible: arrowType.type === ArrowTypes.ONLY_POINT
  });
  extraEndPoint.referenceGroupId = referenceGroupId;

  canvas.add(line1, line2, rect, triangle, extraEndPoint, startCircle, endCircle);

  moveLine(startCircle, endCircle, rect, triangle, line1, line2, canvas);
  repositionLines(line1, line2, rect);

  canvas.renderAll();
  canvas.setActiveObject(endCircle);

  const event = new CustomEvent(CanvasEventTypes.OBJECTS_ADDED_WITH_OBJECT,
    { detail: [line1, line2, rect, triangle, extraEndPoint, startCircle, endCircle] });
  document.dispatchEvent(event);

  startCircle.on(CanvasEventTypes.MOVING, (e: any) => {
    const { left, top } = e.target;
    extraEndPoint.set({ left, top });
    onMoveCircle();
  });

  endCircle.on(CanvasEventTypes.MOVING, onMoveCircle);

  function onMoveCircle() {
    moveLine(startCircle, endCircle, rect, triangle, line1, line2, canvas);
  }
}

export function moveLine(c1, c2, rect, triangle, line1, line2, canvas) {
  const deltaX = c2.left - c1.left;
  const deltaY = c2.top - c1.top;
  const deg = calcAngleByDeltas(deltaX, deltaY);
  const length = Math.sqrt(deltaX ** 2 + deltaY ** 2);

  rect.rotate(deg);
  repositionRect(rect, c1.left, c1.top, deg, length);

  const rectCoords = getRectCenteredCoords(rect);

  repositionTriangle(triangle, {
    left: c2.left,
    top: c2.top
  }, {
    left: c1.left,
    top: c1.top
  });

  repositionLines(line1, line2, rect);
  canvas.renderAll();
}

export function repositionLines(line1, line2, rect) {
  const coords = rect.aCoords;
  line1.set({
    x1: coords.bl.x,
    y1: coords.bl.y,
    x2: coords.br.x,
    y2: coords.br.y,
  });
  line2.set({
    x1: coords.tr.x,
    y1: coords.tr.y,
    x2: coords.tl.x,
    y2: coords.tl.y,
  });
}

export function repositionRect(rect, x, y, deg, length, shift = 7) {
  /* using of shift example:
   7 / 90 = 0,077
   deg = 55
   55 * 0.077 = "3.85" is used shift for axis x, y
   using of always shift / 90 it is required because 90 degree is a 
   turning point of relation between x shift and y shift
   if we have +7 points shift by x and the degree is 90 shift by y must be +0 points shift */

  const _shiftStep = shift / 90;
  const isNegativeDeg = deg < 0;

  /* degrees always must be positive number */
  deg = Math.abs(deg);

  if (deg <= 90)
    /* shift to the top */
    /* 7 to 0 */
    y -= (90 - deg) * _shiftStep;
  else {
    // keep the same relation between deg and shift
    deg = 180 - deg;
    /*  shift to the bottom */
    /* 0 to 7 */
    y += (90 - deg) * _shiftStep;
  }

  if (isNegativeDeg)
    /* shift to the left */
    /* 0 to 7 */
    x -= deg * _shiftStep;
  /* shift to the right */
  /* 7 to 0 */ else x += deg * _shiftStep;

  rect.left = x;
  rect.top = y;
  rect.width = length;
  rect.setCoords();
}

/**
 * Filters and returns a group of object separated by ids
 * @param {any} currentObject
 * @param {string} referenceId
 * @param {any} objects
 */
export function getRelatedDoubleLineObjects(currentObject: any, referenceId: string, objects: any) {
  const filteredObjects = objects.filter((element: any) => {
    return element.referenceGroupId === currentObject.referenceGroupId;
  });

  const triangle = filteredObjects.find((element: any) => {
    return element.subType === ExtensionTypes.TRIANGLE_EXTENSION;
  });

  const line1 = filteredObjects.find((element: any) => {
    return !element.mainDoubleLine;
  });

  const line2 = filteredObjects.find((element: any) => {
    return element.mainDoubleLine;
  });

  const rect = filteredObjects.find((element: any) => {
    return element.subType === ExtensionTypes.RECT_SHAPE_HIDDEN_EXTENSION;
  });

  return { triangle, line1, line2, rect };
}

/**
 * Returns centered coordinates of the rectangle
 * @param {any} rect
 * @returns {any}
 */
export function getRectCenteredCoords(rect: any): any {
  return {
    c1CenterX: (rect.getCoords()[0].x + rect.getCoords()[3].x) / 2,
    c1CenterY: (rect.getCoords()[0].y + rect.getCoords()[3].y) / 2,
    c2CenterX: (rect.getCoords()[1].x + rect.getCoords()[2].x) / 2,
    c2CenterY: (rect.getCoords()[1].y + rect.getCoords()[2].y) / 2
  }
}