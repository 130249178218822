import { Component, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';

import { AppRoutes } from 'app/shared/app.routes ';
import { ModalService } from 'app/core/services/general/modal/modal.service';

/**
 * Represents terms page of the application
 * @class TermsOfUsePage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.page.html',
  styleUrls: ['./terms-of-use.page.scss'],
})
export class TermsOfUsePage implements OnInit {

  /**
   * Provides access to all defined routes/paths
   */
  public appRoutes = AppRoutes;

  /**
   * Uses to show/hide related view elements based on that is page called like a modal or as regular page
   * @type {boolean}
   */
  public isModal: boolean = false;

  constructor(
    private modalController: ModalController,
    private modalService: ModalService,
    private menuController: MenuController
  ) { }

  ngOnInit() {
    this.isModal = this.modalService.isModalActive();
  }

  /**
   * Uses for closing the page/modal itself (if the page is called like a modal page)
   */
  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  /**
   * Handles cancelation
   */
  public onCancel() {
    this.menuController.open();
  }
}