import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appScrollBouncePrevent]",
})
export class ScrollBouncePreventDirective {

  @HostListener("touchmove", ["$event"])
  clickEvent(event) {
    if (event.target.classList.contains("app-drag-element")) {
      event.preventDefault();
    }
  }
}
