import { CanvasEventTypes } from '../../../events/model/canvas/canvas.event.types';
import { ExtensionTypes } from '../models/extension-types/extension.types';
import { fabric } from 'fabric-with-gestures';
import { getReferenceGroupId } from 'app/core/services/system/utilities/help.methods';

export const makeCurvedLineWithPoints = (canvas: any, options: any = {}, endPoint: any, visibility: boolean) => {
  const referenceGroupId = getReferenceGroupId();

  const path = new fabric.BasePath(`M
  ${endPoint.left} ${endPoint.top} Q
  ${endPoint.left}, ${endPoint.top},
  ${endPoint.left}, ${endPoint.top}`, {
    fill: '',
    stroke: options.color || 'black',
    objectCaching: false,
    strokeWidth: Number(options.width || 0.7),
    strokeDashArray: options.dash || [5, 5],
    selectable: false,
    opacity: 0.30,
    visible: visibility
  }, true);
  path.referenceGroupId = referenceGroupId;
  canvas.add(path);

  const middlePoint = new fabric.BaseCircle({
    left: endPoint.left,
    top: endPoint.top,
    visible: visibility
  }, true);
  middlePoint.hasControls = false;
  middlePoint.referenceId = path.id;
  middlePoint.subType = ExtensionTypes.MIDDLE_PATH_CIRCLE_EXTENSION;
  middlePoint.referenceGroupId = referenceGroupId;
  canvas.add(middlePoint);

  endPoint.referenceId = path.id;
  endPoint.referenceGroupId = referenceGroupId;
  canvas.add(endPoint);

  middlePoint.on(CanvasEventTypes.MOVING, (e) => {
    const { left, top } = e.target;
    path.path[1][1] = left;
    path.path[1][2] = top;
    endPoint.set({ x1: left, y1: top });
  });

  endPoint.on(CanvasEventTypes.MOVING, (e) => {
    const { left, top } = e.target;
    path.path[1][3] = left;
    path.path[1][4] = top;
    endPoint.set({ x1: left, y1: top });
  });
};
