import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { IonicAuthStorageService } from 'app/core/services/system/storage/auth/ionic.storage.auth.service';
import { RouteProperties } from '../auth/model/route.properties.model';
import { AppRoutes } from 'app/shared/app.routes ';

/**
 * Manages route access based on the JWT
 * @class NotAlreadyLoggedInGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root'
})
export class NotAlreadyLoggedInGuard implements CanActivate {

  /**
   * Uses ionic storage to obtain the token and router to redirect user if is need it
   * @param {IonicAuthStorageService} auth
   * @param {Router} router
   */
  constructor(private auth: IonicAuthStorageService, private router: Router) { }
  /**
   * Uses ActivatedRouteSnapshot to get current route path
   * Note: canActivate is defined to working async, because our storage service is async
   * @param {ActivatedRouteSnapshot} route
   * @returns {(boolean | Promise<any>)}
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<any> {
    return new Promise(async (resolve) => {
      // get metadata of token
      const isTokenExpired: boolean = await this.auth.isTokenExpired();
      // obtain default route from all registered routes
      const defaultRoute =
        this.router.config[0].path || this.router.config[0].redirectTo || '';
      // get route from The URL segment
      const requestedRoute = route.url[0].path || '';
      // get the new route properties
      const newRoute: RouteProperties = this.getRouteForExpiredToken(
        defaultRoute,
        requestedRoute,
        isTokenExpired
      );

      if (newRoute.path !== requestedRoute) {
        this.router.navigate([newRoute.path]);
      }
      resolve(newRoute.enable);
    });
  }

  /**
   * Gets RouteProperties for expired token
   * @param {string} defaultRoute
   * @param {string} requestedRoute
   * @param {boolean} isTokenExpired
   * @returns {RouteProperties}
   */
  private getRouteForExpiredToken(
    defaultRoute: string,
    requestedRoute: string,
    isTokenExpired: boolean
  ): RouteProperties {
    let path = requestedRoute;
    let enable = true;

    if (!isTokenExpired) {
      path = AppRoutes.MY_FOLDERS;
      enable = false;
    }

    return { path, enable } as RouteProperties;
  }
}
