/**
 * Represent all custom defined canvas extensions
 */
export enum ExtensionTypes {
    PLAYER = 'Player',
    BASE_TEXT = 'text',
    TEXT_EXTENSION = 'baseText',
    BASE_GROUP_EXTENSION = 'baseGroup',
    LINE_EXTENSION = 'baseLine',
    CURVE_LINE_EXTENSION = 'baseCurveLine',
    CIRCLE_SHAPE_EXTENSION = 'CircleShape',
    RECT_SHAPE_EXTENSION = 'RectShape',
    RECT_SHAPE_HIDDEN_EXTENSION = 'RectShapeHidden',
    TRIANGLE_EXTENSION = 'baseTriangle',
    WAVE_LINE_EXTENSION = 'WavyLine',
    GROUP_PLAYER_EXTENSION = 'CustomGroup',
    GROUP_SHAPE_EXTENSION = 'CustomShapeGroup',
    PATH_EXTENSION = 'basePath',
    CIRCLE_EXTENSION = 'baseCircle',
    START_CIRCLE_EXTENSION = 'baseStartCircle',
    END_CIRCLE_EXTENSION = 'baseEndCircle',
    MIDDLE_PATH_CIRCLE_EXTENSION = 'basePathCircle',
    END_PATH_CIRCLE_EXTENSION = 'baseEndPathCircle',
    EXTRA_END_POINT_CIRCLE_EXTENSION = 'BaseExtraCircle',
    START_PATH_CIRCLE_EXTENSION = 'baseStartPathCircle',
    START_LINE_DOUBLE_CIRCLE_EXTENSION = 'baseStartLineDouble',
    END_LINE_DOUBLE_CIRCLE_EXTENSION = 'baseEndLineDouble',
    FREE_LINE_TOOL_EXTENSION = 'freeDrawingLine',
    FREE_LINE_EXTENSION = 'baseFreeLine',
}