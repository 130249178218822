import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

/**
 * Provides centralized interface for controlling different toast "states"
 * @class ToastService
 */
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  /**
   * Stores max milliseconds to wait before hiding the toast.
   *
   * @private
   * @memberof ToastService
   */
  private maxDurationMilisec = 12000;
  /**
   * Provides way to check is there undismissed toast
   */
  public toast = null;

  constructor(
    private toastController: ToastController
  ) {
  }

  /**
   * Provides predefined toast for showing failed operation
   * @param {string} message
   */
  async presentErrorToast(message: string): Promise<any> {
    if (this.toast) {
      this.toast.dismiss();
      this.toast = null;
    }

    this.toast = await this.toastController.create({
      duration: this.maxDurationMilisec,
      message,
      color: 'danger',
      buttons: [{
        icon: 'close'
      }],
    });

    return await this.toast.present();
  }

  /**
   * Provides predefined toast for showing success operation
   * also a callback to inform the listener that toast is dismissed
   * @param {string} message
   * @param {any} [callback]
   */
  async presentSuccessToast(message: string, callback?: any, duration = 800): Promise<any> {
    if (this.toast) {
      this.toast.dismiss();
      this.toast = null;
    }

    this.toast = await this.toastController.create({
      message,
      color: 'success',
      duration,
    });

    if (callback) {
      this.toast.onDidDismiss().then(() => {
        callback();
      });
    }

    return await this.toast.present();
  }

  public dismiss(): void {
    if (this.toast) {
      setTimeout(() => {
        if (this.toast) {
          this.toast.dismiss();
        }
      }, 1000);
    }
  }
}
