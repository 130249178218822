import {HttpBaseService} from '../../system/http-base/http-base.service';
import {Injectable} from '@angular/core';
import {TacticItem} from '../../build/model/tactic.item';
import {TacticTypes} from '../../tactic/model/tactic.types';
import {ToastService} from '../toast/toast.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * Provides general interface to upload an image file
 * @class UploadService
 * @extends {HttpBaseService}
 */
@Injectable({
  providedIn: 'root',
})
export class UploadService extends HttpBaseService {
  /**
   * Extends HttpBaseService which is responsible for communication with the REST API
   * @param {HttpClient} http
   */
  constructor(
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Represents base convertor based on type of the data url/base64
   * @param {string} name
   * @param {string} data
   * @param {string} type
   * @returns {Promise<FormData>}
   */
  public async getFormDataBy(name: string, data: string, type: string = 'image/jpg'): Promise<FormData> {
    if (data && data.startsWith('http')) {
      return await this.getFormDataByUrl(name, data, type);
    } else {
      return await this.getFormDataByBase64(name, data);
    }
  }

  /**
   * Returns content of the provided file
   * @param {any} file
   * @returns {Promise<string>}
   */
  public async getFileContent(file: any): Promise<string> {
    if (!file) {
      return null;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.onerror = async () => {
        const errorImportingText: string = await this.translateService.get('ERROR_IMPORTING_FILE').toPromise();
        this.toastService.presentErrorToast(errorImportingText);
        return reject(this);
      };

      reader.readAsText(file);
    });
  }

  /**
   * Converts and returns file base64 string to formData object
   * @param {string} base64String
   * @param {string} url
   * @param {string} [type='image/jpeg']
   * @returns {Promise<FormData>}
   */
  private async getFormDataByBase64(name: string, base64String: string): Promise<FormData> {
    if (!base64String) {
      return null;
    }

    const type = this.getBase64MimeType(base64String);
    const fileExtension = this.getBase64MimeType(base64String).split('/')[1];

    return new Promise(async (resolve) => {
      fetch(base64String)
        .then(async (res) => {
          const blob = await res.blob();

          const file = new File([blob], `${name}.${fileExtension}`, { type });
          const formData = new FormData();
          formData.append('file', file, `${name}.${fileExtension}`);

          resolve(formData);
        });
    });
  }

  /**
   * Converts and returns uri to formData object
   * @param {string} name
   * @param {string} urls
   * @param {string} [type='image/jpeg']
   * @returns {Promise<FormData>}
   */
  private async getFormDataByUrl(name: string, url: string, type = 'image/jpg'): Promise<FormData> {
    if (!name || !url) {
      return null;
    }

    const fileExtension = url.slice((Math.max(0, url.lastIndexOf('.')) || Infinity) + 1);

    return new Promise(async (resolve) => {
      const base64String = await this.getFileToBase64(url);
      fetch(base64String)
        .then(async (res) => {
          const blob = await res.blob();

          const file = new File([blob], `${name}.${fileExtension}`, { type });
          const formData = new FormData();
          formData.append('file', file, `${name}.${fileExtension}`);

          resolve(formData);
        });
    });
  }

  /**
   * Loads image and returns as like base64 string
   * @param {string} imageUrl
   * @returns {Promise<any>}
   */
  public async getFileToBase64(imageUrl: string): Promise<any> {
    try {
      if (!imageUrl.match(/\.(jpeg|jpg|gif|png|mp4)$/)) {
        return imageUrl;
      }

      const res = await fetch(imageUrl);
      const blob = await res.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          resolve(reader.result);
        }, false);

        reader.onerror = () => {

        };

        reader.readAsDataURL(blob);
      });
    } catch (error) {
    }
  }

  /**
   * Promt user to the save file
   * @param tactic
   */
  public async promptToDownloadMedia(tactic: TacticItem): Promise<void> {
    let type: string;
    let fileExtension: string;
    let file: string;

    if (tactic.type === TacticTypes.IMAGE) {
      fileExtension = tactic.link.slice((Math.max(0, tactic.link.lastIndexOf('.')) || Infinity) + 1);
      type = 'image/' + fileExtension;
      file = await this.getFileToBase64(tactic.link);
    } else {
      fileExtension = tactic.linkMp4.slice((Math.max(0, tactic.linkMp4.lastIndexOf('.')) || Infinity) + 1);
      type = 'video/mp4';
      file = await this.getFileToBase64(tactic.linkMp4);
    }

    const link = document.createElement('a');
    link.href = file;
    link.setAttribute('download', `${tactic.name}.${fileExtension}`);
    link.click();
    link.remove();
  }

  /**
   * Promt user to the save file
   * @param {string} name
   * @param type
   * @param {string} data
   */
  public promptToDownload(name: string, type: string, data: any): void {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = name;
    const blob = new Blob([data], {
      type
    });
    link.href = window.URL.createObjectURL(blob);

    link.click();
    link.remove();
  }

  /**
   * Promt user to the save image base64 file
   * @param {string} name
   * @param {string} data
   * @param {any} element
   */
  public promptToDownloadImageBase64(name: string, type: string = 'image/png', data: any): void {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = name;
    const blob = new Blob([data], {
      type
    });
    link.href = data;

    link.click();
    link.remove();
  }

  /**
   * Creates formData based on the file
   * @param {any} file
   * @returns {Promise<any>}
   */
  public async getImageFormData(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const formData = new FormData();
        const imgBlob = new Blob([reader.result], {
          type: file.type
        });
        formData.append('file', imgBlob, file.name);
        resolve(formData);
      };

      reader.onerror = () => {
        return reject(this);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  private getBase64MimeType(encoded: string): string {
    let result: string;

    const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }
}
