import { calcAngle, getReferenceCircleGroupId, getReferenceGroupId } from 'app/core/services/system/utilities/help.methods';

import { AppConfig } from 'app/config/app.config';
import { CanvasEventTypes } from '../../events/model/canvas/canvas.event.types';
import { ExtensionTypes } from '../../fabric/extensions/models/extension-types/extension.types';
import { Injectable } from '@angular/core';
import { fabric } from 'fabric-with-gestures';

@Injectable({
  providedIn: 'root'
})
export class DrawLinesActionsService {

  public drawStraightLine(
    canvas: any, drawCenter = true,
    options?: any): any {

    const referenceGroupId = getReferenceGroupId();
    const referenceCircleGroupId = getReferenceCircleGroupId();

    let offset: any = {};
    let center: any = {};

    if (drawCenter) {
      center = { x: canvas.getCenter().left, y: canvas.getCenter().top };
      offset = { x: center.x + 100, y: center.y - 100 };
    } else if (options) {
      center.x = options.x1;
      center.y = options.y1;
      offset.x = options.x2;
      offset.y = options.y2;
    }

    const line = new fabric.BaseLine([center.x, center.y, offset.x, offset.y], options);
    line.referenceGroupId = referenceGroupId;
    line.doubleLine = false;

    const startCircle = new fabric.BaseCircle({
      left: center.x,
      top: center.y,
    });
    startCircle.referenceId = line.id;
    startCircle.subType = ExtensionTypes.START_CIRCLE_EXTENSION;
    startCircle.hasControls = false;
    startCircle.referenceGroupId = referenceGroupId;
    startCircle.referenceCircleGroupId = referenceCircleGroupId;

    const endCircle = new fabric.BaseCircle({
      left: offset.x,
      top: offset.y,
    });
    endCircle.hasControls = false;
    endCircle.subType = ExtensionTypes.END_CIRCLE_EXTENSION;
    endCircle.referenceId = line.id;
    endCircle.referenceGroupId = referenceGroupId;
    endCircle.referenceCircleGroupId = referenceCircleGroupId;

    canvas.add(line, startCircle, endCircle);

    canvas.renderAll();
    canvas.setActiveObject(endCircle);

    startCircle.on(CanvasEventTypes.MOVING, (e: any) => {
      line.set({
        x1: startCircle.left,
        y1: startCircle.top,
      });
    });

    endCircle.on(CanvasEventTypes.MOVING, (e: any) => {
      line.set({
        x2: endCircle.left,
        y2: endCircle.top,
      });
    });

    return line;
  }

  /************************************************************************************************
   * Methods for drawing curv lines
   ************************************************************************************************
   */

  public drawCurvedLine(canvas: any, options?: any): void {
    const referenceGroupId = getReferenceGroupId();
    const referenceCircleGroupId = getReferenceCircleGroupId();

    const point1 = options && options.point1 || { x: options.x1, y: options.y1 };
    const point2 = options && options.point2 || { x: options.x2, y: options.y2 };
    const point3 = options && options.point3 || { x: (point1.x + point2.x)/2, y: (point1.y + point2.y)/2 };

    const line = new fabric.BaseCurveLine([point1.x, point1.y, point2.x, point2.y], options);

    line.cpx = point3.x;
    line.cpy = point3.y;

    line.referenceGroupId = referenceGroupId;

    const p1 = this._makeCurvePoint(point3.x, point3.y, null, line, null);
    p1.subType = ExtensionTypes.MIDDLE_PATH_CIRCLE_EXTENSION;
    p1.referenceId = line.id;
    p1.referenceGroupId = referenceGroupId;
    p1.referenceCircleGroupId = referenceCircleGroupId;
    p1.hasControls = false;

    const p0 = this._makeCurveCircle(point1.x, point1.y, line, p1, null);
    p0.referenceId = line.id;
    p0.subType = ExtensionTypes.START_PATH_CIRCLE_EXTENSION;
    p0.referenceGroupId = referenceGroupId;
    p0.referenceCircleGroupId = referenceCircleGroupId;
    p0.hasControls = false;

    const p2 = this._makeCurveCircle(point2.x, point2.y, null, p1, line);
    p2.referenceId = line.id;
    p2.subType = ExtensionTypes.END_PATH_CIRCLE_EXTENSION;
    p2.referenceGroupId = referenceGroupId;
    p2.referenceCircleGroupId = referenceCircleGroupId;
    p2.hasControls = false;
    p2.hasBorder = false;

    canvas.add(line);
    canvas.add(p1);
    canvas.add(p0);
    canvas.add(p2);

    canvas.renderAll();

    canvas.setActiveObject(p1);

    p2.on(CanvasEventTypes.MOVING, (e: any) => {
      const { left, top } = e.target;

      line.set({
          x2: left,
          y2: top,
      });
    });

    p0.on(CanvasEventTypes.MOVING, (e: any) => {
      const { left, top } = e.target;
        line.set({
            x1: left,
            y1: top,
        });

    });

    p1.on(CanvasEventTypes.MOVING, (e: any) => {
      const { left, top } = e.target;

      p1.set({ x1: left, y1: top, objectCaching: false });

      line.set({
          cpx: left,
          cpy: top,
          objectCaching: false
      });

      canvas.renderAll();
    });

    return line;
  }

  private _makeCurveCircle(left, top, line1, line2, line3) {
    const c = new fabric.BaseCircle({
      left,
      top
    });

    c.hasBorders = c.hasControls = false;

    c.line1 = line1;
    c.line2 = line2;
    c.line3 = line3;

    return c;
  }

  private _makeCurvePoint(left, top, line1, line2, line3) {
    const c = new fabric.BaseCircle({
      left,
      top
    });

    c.hasBorders = c.hasControls = false;

    c.line1 = line1;
    c.line2 = line2;
    c.line3 = line3;

    return c;
  }
}
