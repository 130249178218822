/**
 * Represents fabricjs events types
 */
export enum CanvasEventTypes {
  INIT = 'init',
  DROP = 'drop',
  MOVING_OBJECT = 'object:moving',
  SCALING_OBJECT = 'object:scaling',
  MOVING = 'moving',
  AFTER_RENDER = 'after-render',
  MOUSE_UP = 'mouse:up',
  // it triggers when make a multiselection
  MOUSE_UP_SELECTION = 'mouseup',
  // is not a fabricjs event
  //@todo move to object.event.types
  MULTI_SELECTION_CREATED = 'multiselectioncreated',
  MOUSE_OVER = 'mouse:over',
  MOUSE_OUT = 'mouse:out',
  MOUSE_DOWN = 'mouse:down',
  MOUSE_DOUBLE_CLICK = 'mouse:dblclick',
  MOUSE_MOVE = 'mouse:move',
  BEFORE_SELECTION_CLEARED = 'before:selection:cleared',
  TOUCH_GESTURE = 'touch:gesture',
  TOUCH_DRAG = 'touch:drag',
  SELECTION_CREATED = 'selection:created',
  SELECTION_CLEAR = 'selection:cleared',
  SELECTION_UPDATED = 'selection:updated',
  OBJECT_SELECTED = 'selected',
  OBJECT_MOVED = 'object:moved',
  OBJECT_REMOVED = 'object:removed',
  OBJECT_ROTATE = 'object:rotated',
  OBJECT_ROTATING = 'object:rotating',
  OBJECT_UPDATED_WITH_OBJECT = 'objectUpdatedWithObject',
  OBJECT_REMOVED_WITH_OBJECT = 'objectRemovedWithObject',
  OBJECT_MODIFIED = 'object:modified',
  OBJECT_SCALED = 'object:scaled',
  START_DRAWING = 'start:drawing',
  WINDOW_RESIZED = 'window:resized',
  FULLY_LOADED = 'fullyLoaded',
  BACK_UPDATED = 'backUpdated',
  CANVAS_ZOOMED = 'canvas:zoomed',
  GRID_HIDE = 'grid:hide',
  OBJECT_ADDED = 'object:added',
  OBJECT_ADDED_WITH_OBJECT = 'objectAddedWithObject',
  OBJECTS_ADDED_WITH_OBJECT = 'objectsAddedWithObject',
  DRAW_TACTIC = 'drawTactic',

  STRAIGHT_LINE_START_DRAWING = 'straight:line:start:drawing',
  STRAIGHT_LINE_END_DRAWING = 'straight:line:end:drawing',

  CURVE_LINE_START_DRAWING = 'curve:line:start:drawing',
  CURVE_LINE_END_DRAWING = 'curve:line:end:drawing',

  FREE_LINE_END_DRAWING = 'free:line:end:drawing',
  FREE_LINE_START_DRAWING = 'free:line:start:drawing',
}
