/**
 * Represents frame events types
 */
export enum FrameEventTypes {
  DELETE_FRAME = 'deleteFrame',
  END_FRAME = 'endFrame',
  PLAY_FRAME = 'playFrame',
  ADD_FRAME = 'addFrame',
  RESET_FRAME = 'resetFrame',
  UPDATE_FRAME = 'updateFrame'
}