import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { IonicAuthStorageService } from 'app/core/services/system/storage/auth/ionic.storage.auth.service';

/**
 * Manages route access based on the JWT
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class AuthDrawGuard implements CanActivate {
  /**
   * Uses ionic storage to obtain the token and router to redirect user if is need it
   * @param {IonicAuthStorageService} auth
   * @param {Router} router
   */
  constructor(private auth: IonicAuthStorageService, private router: Router) { console.log(router) }
  /**
   * Uses ActivatedRouteSnapshot to get current route path
   * Note: canActivate is defined to working async, because our storage service is async
   * @param {ActivatedRouteSnapshot} route
   * @returns {(boolean | Promise<any>)}
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<any> {
    return new Promise(async (resolve) => {
      // get metadata of token
      const isTokenExpired: boolean = await this.auth.isTokenExpired();
      // obtain default route from all registered routes
      const defaultRoute =
        this.router.config[0].path || this.router.config[0].redirectTo || '';

      if (isTokenExpired && Object.keys(route.params).length !== 0) {
        this.router.navigate([defaultRoute]);
      }
      resolve(true);
    });
  }
}
