import { AdapterInterface } from '../../system/http-base/adapter.interface';
import { Folder } from '../model/folder.model';
import { FolderItem } from '../../build/model/folder.item';
import { FolderTypes } from '../model/folder.types';
import { Injectable } from '@angular/core';

/**
 * Creates FolderItemToFolderAdapter service for decoupling REST API objects with the internal entities
 * @class FolderAdapter
 */
@Injectable({
  providedIn: 'root',
})
export class FolderItemToFolderAdapter implements AdapterInterface<Folder> {
  /**
   * Creates a new Folder object with data comes from internal presentational model
   * @param {FolderItem} item
   * @returns {Folder}
   */
  adapt(item: FolderItem): Folder {
    const folder = new Folder();
    folder.uid = item.uid;
    folder.parentUid = item.parentUid || 0;
    folder.name = item.name;
    folder.deleted = item.deleted || 0;
    folder.type = FolderTypes.PRIVATE;

    return folder;
  }
}
