import { Chooser, ChooserResult } from '@ionic-native/chooser/ngx';

import { AppRoutes } from 'app/shared/app.routes ';
import { Component } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { MenuController } from '@ionic/angular';
import { ModalService } from 'app/core/services/general/modal/modal.service';
import { StorageService } from './../core/services/system/storage/storage/storage.service';
import { TermsOfUsePage } from 'app/terms-of-use/terms-of-use.page';
import { ToastService } from 'app/core/services/general/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UploadService } from 'app/core/services/general/upload/upload.service';
import { User } from 'app/core/services/user/model/user.model';
import { UserService } from './../core/services/user/user.service';
import { UserStorageData } from 'app/core/services/system/storage/storage/model/user.storage.data.model';

/**
 * Represents Main menu page (navigation slider)
 * @class MainMenuComponent
 */
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
  /**
   * Provides access to all defined routes/paths
   */
  public appRoutes = AppRoutes;

  /**
   * Stores data comming from REST API/storage
   * @type {User}
   */
  public user: User;

  /**
   * Consist obtained user data from the storage
   * @type {UserStorageData}
   */
  public userStorageData: UserStorageData;

  /**
   * Provides supporting of navigation between different user's pages and uploading of avatar
   * @param {UserService} userService
   * @param {MenuController} menuController
   * @param {Chooser} chooser
   * @param {StorageService} storageService
   * @param {File} file
   * @param {TranslateService} translate
   * @param {ToastService} toastService
   * @param {UploadService} uploadService
   */
  constructor(
    private userService: UserService,
    private menuController: MenuController,
    private chooser: Chooser,
    private storageService: StorageService,
    private file: File,
    private translate: TranslateService,
    private toastService: ToastService,
    private uploadService: UploadService,
    private modalService: ModalService
  ) {
  }

  /**
   * Opens the selected page as like a modal window
   */
  public openModal() {
    this.modalService.presentModal(TermsOfUsePage);
  }

  /**
   * Handles clicks on every ion-item
   */
  public onClick() {
    this.menuController.close();
  }

  /**
   * Updates user data related to page right before showing it
   */
  public async onWillOpen() {
    this.user = await this.userService.getUser(true).toPromise();
    this.userStorageData = await this.storageService.getUserDataFromStorage();
  }

  /**
   * Handles clicks on close icon
   */
  public onCloseClick() {
    this.menuController.close();
  }

  /**
   * Handles user clicking on the avatar image
   */
  public onOpenFile(): void {
    this.chooser.getFile()
      // android/ios
      .then(this.onFileOpenDevice)
      .catch((error: any) => {
        if (error === 'cordova_not_available') {
          const html5File: HTMLElement = document.getElementById('html5File');
          html5File.click();
        }
      });
  }

  /**
   * Handles selection of file (browser)
   * @param {any} event
   */
  public onFileOpenBrowser(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      const formData = new FormData();
      formData.append('file', files[0]);
      this.userService.uploadAvatar(formData).subscribe(
        (response) => this.onSuccess(response),
        (error) => this.onFailure(error));
    }
  }

  /**
   * @todo test on emulator
   * Handles selection of file (device)
   * @param {ChooserResult} file
   */
  private onFileOpenDevice(chooserResult: ChooserResult) {
    if (chooserResult) {
      this.file.resolveLocalFilesystemUrl(chooserResult.uri).then(async (file: any) => {
        const formData: FormData = await this.uploadService.getImageFormData(file);
        this.userService.uploadAvatar(formData).subscribe(
          (response) => this.onSuccess(response),
          (error) => this.onFailure(error));
      }).catch((error) => this.onFailure(error));
    }
  }

  /**
   * Handles if request is success
   * @param {any} response
   */
  private onSuccess(response: any): void {
    this.onWillOpen();
  }

  /**
   * Handles if request is failed
   * @param {any} error
   */
  private onFailure(error: any): void {
    this.translate.get('ERROR_UPLOADING_AVATAR').subscribe((translationText) => {
      this.toastService.presentErrorToast(translationText);
    });
  }
}
