import { calcAngleByDeltas, getRandomId } from 'app/core/services/system/utilities/help.methods';

import { ExtensionTypes } from '../models/extension-types/extension.types';
import { LineTypes } from 'app/core/drawing/models/line-types/line.types';
import { fabric } from 'fabric-with-gestures';

export function initBaseTriangleExtension(): any {
    if (fabric.BaseTriangle === undefined) {
        fabric.BaseTriangle = fabric.util.createClass(fabric.Triangle, {

            type: ExtensionTypes.TRIANGLE_EXTENSION,

            initialize(options: any = {}) {
                this.callSuper('initialize', options);

                if (!this.id) {
                    // extends the custom object with additional property
                    this.set('id', ExtensionTypes.TRIANGLE_EXTENSION + '_' + getRandomId());
                }

                if (!this.subType) {
                    this.subType = ExtensionTypes.TRIANGLE_EXTENSION;
                }

                this.selectable = false;
                this.fill = this.fill || 'black';
                this.originX = 'center';
                this.originY = 'center';
            },

            toObject() {
                return fabric.util.object.extend(this.callSuper('toObject'), {
                    subType: ExtensionTypes.TRIANGLE_EXTENSION,
                    id: this.id,
                    referenceId: this.referenceId,
                    referenceGroupId: this.referenceGroupId,
                    loaded: true,
                    deleted: this.deleted,
                    updated: this.updated,
                    shouldBringToFront: this.shouldBringToFront
                });
            },
        });

        fabric.BaseTriangle.fromObject = (object, callback) => {
            callback(new fabric.BaseTriangle(object));
        };
    }
}

export function repositionTriangle(triangle: any, startCircle: any, endCircle: any, offset = 9) {
    const deltaX = endCircle.left - startCircle.left;
    const deltaY = endCircle.top - startCircle.top;
    const deg = calcAngleByDeltas(deltaX, deltaY);
    triangle.set({
        angle: deg + 90,
        left: endCircle.left + Math.cos((Math.PI / 180.0) * deg) * offset,
        top: endCircle.top + Math.sin((Math.PI / 180.0) * deg) * offset,
    });
}