import { Injectable } from '@angular/core';
import { Tactic } from '../model/tactic.model';
import { AdapterInterface } from '../../system/http-base/adapter.interface';

/**
 * Creates Adapter service for decoupling REST API objects with the internal entities
 * @class TacticAdapter
 */
@Injectable({
  providedIn: 'root',
})
export class TacticAdapter implements AdapterInterface<Tactic> {
  /**
   * Creates a new Tactic object with data comes from the REST API
   * @param {any} item
   * @returns {Tactic}
   */
  adapt(item: any): Tactic {
    const tactic = new Tactic();
    tactic.uid = item.uid;
    tactic.appUid = item.app_uid;
    tactic.created = item.created;
    tactic.createdBy = item.created_by;
    tactic.createdUtc = item.created_utc;
    tactic.deleted = item.deleted;
    tactic.modifiedUtc = item.modified_utc;
    tactic.name = item.name;
    tactic.type = item.tactic_type;
    tactic.tacticsFolderUid = item.tactics_folder_uid;
    tactic.uid = item.uid;
    tactic.linkThumb = item.x_thumb_url;
    tactic.link = item.x_link_url;
    tactic.linkMp4 = item.x_link_mp4_url;
    tactic.data = item.data;
    tactic.tacticTypes = item.tactic_type;

    return tactic;
  }
}
