import { fabric } from 'fabric-with-gestures';
import { initBaseCurveExtension } from './curved-line-extension/base.curved.line.extension';
import { initBaseFreeLineExtension } from './free-line-extension/base.free.line.extension';
import { initBaseGroupExtension } from './base-group-extension/base.group.extension';
import { initBaseLineExtension } from './line-extension/line.extension';
import { initBaseTriangleExtension } from './triangle-extension/triangle.extension';
import { initCircleExtension } from './circle-extension/circle.extension';
import { initCircleShapeExtension } from './shapes-extensions/circle-shape-extension/circle-shape-extension';
import { initExtraCircleExtension } from './extra-circle-extension/extra.circle.extension';
import { initFreeLineExtension } from './free-line-extension/free-line.extension';
import { initGroupExtension } from './group-extension/group.extension';
import { initGroupShapeExtension } from './group-shape-extension/group.shape.extension';
import { initPathExtension } from './path-extension/path.extension';
import { initPlayerExtension } from './player-extension/player.extension';
import { initRectHiddenShapeExtension } from './shapes-extensions/rect-shape-hidden-extension/rect.shape.hidden.extension';
import { initRectShapeExtension } from './shapes-extensions/rect-shape-extension/rect-shape-extension';
import { initSvgExtension } from './svg-extension/svg.extension';
import { initTextExtension } from './text-extension/text.extension';
import { initWavyLineExtension } from './wavy-line-extension/wavy.line.extension';

/**
 * Registers all custom fabric js extensions
 */
export function initFabricExtensions(): void {
  fabric.Object.prototype.objectCaching = false;

  initPlayerExtension();
  initPathExtension();
  initBaseCurveExtension();
  initBaseLineExtension();
  initCircleExtension();
  initWavyLineExtension();
  initSvgExtension();
  initGroupExtension();
  initTextExtension();
  initFreeLineExtension();
  initBaseTriangleExtension();
  initFreeLineExtension();
  initCircleShapeExtension();
  initRectShapeExtension();
  initExtraCircleExtension();
  initGroupShapeExtension();
  initBaseGroupExtension();
  initRectHiddenShapeExtension();
  initBaseFreeLineExtension();
}
