import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';
import { Auth } from '../../http-base/auth';

/**
 * Manages JWT (token) (setting, getting) using IndexedDB
 * @class IonicAuthStorageService
 */
@Injectable({
  providedIn: 'root',
})
export class IonicAuthStorageService implements Auth {
  constructor(
    private storage: Storage,
    private jwtHelperService: JwtHelperService
  ) { }

  /**
   * Sets token to the ionic storage
   * @param {string} tokenValue
   * @returns {Promise<any>}
   */
  setToken(tokenValue: string): Promise<any> {
    return this.storage.set(Auth.TOKEN_NAME, tokenValue);
  }

  /**
   * Gets token from the ionic storage
   * @returns {Promise<string>}
   */
  getToken(): Promise<string> {
    return this.storage.get(Auth.TOKEN_NAME);
  }

  /**
   * Removes token from the ionic storage
   * @returns {Promise<string>}
   */
  deleteToken(): Promise<any> {
    return this.storage.remove(Auth.TOKEN_NAME);
  }

  /**
   * Checks validity of token expiration
   * @returns {Promise<boolean>}
   */
  public async isTokenExpired(): Promise<boolean> {
    const token = await this.getToken();

    if (!token) {
      return true;
    }

    return this.jwtHelperService.isTokenExpired(token);
  }

  /**
   * Checks is token exist at all
   * @returns {Promise<boolean>}
   */
  public async doesTokenExist(): Promise<boolean> {
    const token: string = await this.getToken();

    return token.length > 0;
  }
}
