import { AppConfig } from 'app/config/app.config';
import { CircleTypes } from 'app/core/drawing/models/circle-types/circle.types';
import { ExtensionTypes } from '../../models/extension-types/extension.types';
import { LineTypes } from 'app/core/drawing/models/line-types/line.types';
import { ThicknessTypes } from 'app/core/drawing/models/thickness-types/thickness.line';
import { ThinLine } from 'app/core/drawing/models/thickness-line/thin-line/thin.line';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initCircleShapeExtension(): any {
    if (fabric.CircleShape === undefined) {
        fabric.CircleShape = fabric.util.createClass(fabric.Ellipse, {

            type: ExtensionTypes.CIRCLE_SHAPE_EXTENSION,

            initialize(options: any = {}) {
                options.strokeWidth = options.strokeWidth || new ThinLine().width;
                options.selectable = true;
                options.objectCaching = false;
                options.dirty = true;

                this.callSuper('initialize', options);

                if (!this.id) {
                    // extends the custom object with additional property
                    this.set('id', ExtensionTypes.CIRCLE_SHAPE_EXTENSION + '_' + getRandomId());
                }

                this.strokeUniform = true;

                if (!this.actionType) {
                    this.actionType = CircleTypes.CIRCLE;
                }

                if (!this.actionLineType) {
                    this.actionLineType = LineTypes.SOLID_LINE;
                }

                if (!this.actionTicknessType) {
                    this.actionTicknessType = ThicknessTypes.THIN_LINE;
                }

                if (!this.subType) {
                    this.subType = ExtensionTypes.CIRCLE_SHAPE_EXTENSION;
                }

                if (!this.actionSize) {
                    this.actionSize = AppConfig.Canvas.minScaleSize;
                }
            },

            toObject() {
                return fabric.util.object.extend(this.callSuper('toObject'), {
                    actionSize: this.actionSize,
                    subType: ExtensionTypes.CIRCLE_SHAPE_EXTENSION,
                    actionType: this.actionType || CircleTypes.CIRCLE,
                    actionTicknessType: this.actionTicknessType || ThicknessTypes.THIN_LINE,
                    actionLineType: this.actionLineType || LineTypes.SOLID_LINE,
                    id: this.id,
                    loaded: true,
                    deleted: this.deleted,
                    updated: this.updated,
                    shouldBringToFront: this.shouldBringToFront
                });
            },
        });

        fabric.CircleShape.fromObject = (object, callback) => {
            return fabric.Object._fromObject(ExtensionTypes.CIRCLE_SHAPE_EXTENSION, object, callback);
        };
    }
}