export class ExtendFigure {
    public referenceGroupId;
    public referenceId;
    public id;
    public color1: string = '';
    public color2: string = '';
    public textColor: string = '';
    public imageURL: string;
    public actionType: string;
    public top: number;
    public left: number;
    public angle: number = 0;
    public text: string = '';
    public actionSize: number = 1;
    public isLocked: boolean = false;
    public selectAfterCreate: boolean = false;
    public isNotCenterText: boolean = false;
    public flipY: boolean = false;
    public flipX: boolean = false;
    public changeColor:boolean = false;
}
