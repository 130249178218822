import { Injectable } from '@angular/core';
import { AppConfig } from 'app/config/app.config';

/**
 * Provides exposing of system configuration loaded from config file.
 * @class HttpBaseService
 */
@Injectable({
  providedIn: 'root',
})
export class HttpBaseService {
  /**
   * Consist REST API's address of the backend server.
   */
  protected baseUrl = AppConfig.Easy2CoachApi.baseUrl;

  constructor() {}
}
