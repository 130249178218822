import { AppConfig } from 'app/config/app.config';
import { Injectable } from '@angular/core';
import { fabric } from 'fabric-with-gestures';
import {ExtensionTypes} from "../../fabric/extensions/models/extension-types/extension.types";

@Injectable({
  providedIn: 'root'
})
export class DrawTextActionsService {

  constructor() {
  }

  public drawText(canvas: any): void {

    const center = { x: 1280/2, y: 380 };

    const rect = new fabric.BaseText('Text', {
      fontSize: AppConfig.Canvas.textFontSize,
      fontFamily: AppConfig.Canvas.fontFamily,
      left: center.x,
      top: center.y,
      selectable: true,
      lockUniScaling: true,
      borderColor: AppConfig.Canvas.borderColor,
      cornerColor: AppConfig.Canvas.cornerColor,
    });

    if (!localStorage.getItem('object_actionSize')) {
        localStorage.setItem('object_actionSize', String(AppConfig.Canvas.iconDefaultSize));
    }
    rect.actionSize = parseInt(localStorage.getItem('object_actionSize'));
    rect.actionName = 'Text';

    const scale = rect.actionSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;

    rect.set({
          scaleX: scale,
          scaleY: scale
    });

    rect.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
    });

    canvas.add(rect);
    canvas.renderAll();
  }
}
