<ion-menu swipeGesture="false" side="end" menuId="custom" contentId="main" class="brand-menu"
	(ionWillOpen)="onWillOpen()">
	<ion-header class="menu-section ion-no-border ion-padding">
		<ion-item lines="full" class="ion-no-padding">
			<ion-avatar *ngIf="userStorageData" slot="start">
				<img (click)="onOpenFile()" src="{{userStorageData.avatarImage}}" />
				<input type="file" class="ion-hide" id="html5File" (change)="onFileOpenBrowser($event)">
			</ion-avatar>
			<ion-label *ngIf="user">
				<div>{{user.firstName}} {{user.lastName}}</div>
				<div class="caption">{{user.email}}</div>
			</ion-label>
			<ion-button fill="clear" (click)="onCloseClick()">
				<ion-icon class="ion-no-margin" name="close-outline" slot="start"></ion-icon>
			</ion-button>
		</ion-item>
	</ion-header>
	<ion-content class="menu-section ion-padding">
		<ion-item mode="md" lines="none" routerLink="/my-folders" routerDirection="forward" (click)="onClick()">
			<ion-icon src="assets/icons/e2c-folder.svg" slot="start"></ion-icon>
			{{ 'MY_FOLDERS' | translate }}
		</ion-item>
		<ion-item mode="md" lines="none" routerLink="/settings" routerDirection="forward" (click)="onClick()">
			<ion-icon src="assets/icons/e2c-settings.svg" slot="start"></ion-icon>
			{{ 'SETTINGS' | translate }}
		</ion-item>
		<!--
		<ion-item mode="md" lines="none" routerLink="/change-password" routerDirection="forward" (click)="onClick()">
			<ion-icon name="lock-closed-outline" slot="start"></ion-icon>
			{{ 'CHANGE_PASSWORD' | translate }}
		</ion-item>
		-->
		<!--
		<ion-item mode="md" lines="none" routerLink="/premium-required" routerDirection="forward" (click)="onClick()">
			<ion-icon src="assets/icons/e2c-star.svg" slot="start" class="premium"></ion-icon>
			{{ 'SUBSCRIBTION' | translate }}
		</ion-item>
		-->
	</ion-content>
	<ion-footer class="menu-section ion-no-border ion-padding">
		<ion-item mode="md" lines="none"  routerDirection="forward"
			(click)="onClick();openModal()">
			{{ 'TERMS_MAIN_MENU' | translate }}</ion-item>
		<ion-item mode="md" lines="none" routerLink="/{{appRoutes.LOGOUT}}" routerDirection="back" (click)="onClick()">
			{{ 'LOGOUT' | translate }}
		</ion-item>
	</ion-footer>
</ion-menu>
