import { ArrowLineThick } from 'app/core/drawing/models/arrow-line/arrow-line-thick/arrow.line.thick';
import { ArrowTypes } from 'app/core/drawing/models/arrow-types/arrowtypes';
import { ExtensionTypes } from '../models/extension-types/extension.types';
import { LineTypes } from 'app/core/drawing/models/line-types/line.types';
import { ThicknessTypes } from 'app/core/drawing/models/thickness-types/thickness.line';
import { ThinLine } from 'app/core/drawing/models/thickness-line/thin-line/thin.line';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initBaseLineExtension(): any {
  if (fabric.BaseLine === undefined) {
    fabric.BaseLine = fabric.util.createClass(fabric.Line, {

      type: ExtensionTypes.LINE_EXTENSION,

      initialize(coords = null, options: any = {}) {
        options.strokeWidth = options.strokeWidth || new ThinLine().width;
        options.stroke = options.stroke || 'black';
        options.selectable = true;
        options.lockScalingX = true;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.lockScalingY = true;
        options.lockUniScaling = true;
        options.lockRotation = true;
        options.hasBorders = false;
        options.hasControls = false;
        options.originX = 'center';
        options.originY = 'center';
        options.objectCaching = false;
        options.padding = 10;

        this.callSuper('initialize', coords, options);

        // Set default options
        this.set({
          hasBorders: false,
          hasControls: false,
        });


        if (!this.id) {
          // extends the custom object with additional property
          this.set('id', ExtensionTypes.LINE_EXTENSION + '_' + getRandomId());
        }

        if (!this.actionType) {
          this.actionType = LineTypes.SOLID_LINE;
        }

        if (!this.actionLineType) {
          this.actionLineType = LineTypes.SOLID_LINE;
        }

        if (!this.arrowType) {
          this.arrowType = new ArrowLineThick();
        }

        if (!this.actionTicknessType) {
          this.actionTicknessType = ThicknessTypes.THIN_LINE;
        }

        if (!this.subType) {
          this.subType = ExtensionTypes.LINE_EXTENSION;
        }
      },

      _render: function (ctx) {
        this.callSuper('_render', ctx);

        if (!this.doubleLine) {

          if (this.arrowType.type === ArrowTypes.TINY_ARROW || this.arrowType.type === ArrowTypes.TICK_ARROW) {
            this.drawArrow(ctx, this.arrowType.x, this.arrowType.y);
          }

          if (this.arrowType.type === ArrowTypes.ONLY_POINT) {
            this.drawPoint(ctx, this.arrowType.x);
          }
        }
      },

      drawArrow: function (ctx, x, y) {
        ctx.save();
        const xDiff = this.x2 - this.x1;
        const yDiff = this.y2 - this.y1;
        const angle = Math.atan2(yDiff, xDiff);
        ctx.translate(xDiff / 2, yDiff / 2);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(-x, y);
        ctx.lineTo(-x, -y);
        ctx.closePath();
        ctx.fillStyle = this.stroke;
        ctx.fill();
        ctx.restore();
      },

      drawPoint: function (ctx, radius) {
        ctx.save();
        const xDiff = this.x2 - this.x1;
        const yDiff = this.y2 - this.y1;
        ctx.translate(xDiff / 2, yDiff / 2);
        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, 2 * Math.PI, true);
        ctx.fillStyle = this.stroke;
        ctx.fill();
        ctx.restore();
      },

      point: function (x, y) {
        return {
          x: x,
          y: y
        };
      },

      wavy: function (from, to, endPoint, ctx) {
        var cx = 0,
          cy = 0,
          fx = from.x,
          fy = from.y,
          tx = to.x,
          ty = to.y,
          i = 0,
          step = 4,
          waveOffsetLength = 0,

          ang = Math.atan2(ty - fy, tx - fx),
          distance = Math.sqrt((fx - tx) * (fx - tx) + (fy - ty) * (fy - ty)),
          amplitude = -10,
          f = Math.PI * distance / 30;

        for (i; i <= distance; i += step) {
          waveOffsetLength = Math.sin((i / distance) * f) * amplitude;
          cx = from.x + Math.cos(ang) * i + Math.cos(ang - Math.PI / 2) * waveOffsetLength;
          cy = from.y + Math.sin(ang) * i + Math.sin(ang - Math.PI / 2) * waveOffsetLength;
          i > 0 ? ctx.lineTo(cx, cy) : ctx.moveTo(cx, cy);
        }
        ctx.lineTo(to.x, to.y);
        ctx.lineTo(endPoint.x, endPoint.y);
      },

      pointOnLine: function (point1, point2, dist) {
        var len = Math.sqrt(((point2.x - point1.x) * (point2.x - point1.x)) + ((point2.y - point1.y) * (point2.y - point1.y)));
        var t = (dist) / len;
        var x3 = ((1 - t) * point1.x) + (t * point2.x),
          y3 = ((1 - t) * point1.y) + (t * point2.y);
        return new fabric.Point(x3, y3);
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          x1: this.x1,
          y1: this.y1,
          x2: this.x2,
          y2: this.y2,
          arrowType: this.arrowType,
          doubleLine: this.doubleLine,
          mainDoubleLine: this.mainDoubleLine,
          referenceGroupId: this.referenceGroupId,
          actionTicknessType: this.actionTicknessType || ThicknessTypes.THIN_LINE,
          actionType: this.actionType || LineTypes.SOLID_LINE,
          actionLineType: this.actionLineType || LineTypes.SOLID_LINE,
          subType: ExtensionTypes.LINE_EXTENSION,
          id: this.id,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated,
          shouldBringToFront: this.shouldBringToFront
        });
      },
    });

    fabric.BaseLine.fromObject = (object, callback) => {
      const { x1, y1, x2, y2 } = object;
      callback(new fabric.BaseLine([x1, y1, x2, y2], object));
    };


  }
}
