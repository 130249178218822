import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

/**
 * Determines type of the platform where application serve
 */
@Injectable({
  providedIn: 'root'
})
export class PlatformDetectorService {

  /**
   * Uses native ionic platform class to determine type of it
   * @param {Platform} platform
   */
  constructor(public platform: Platform) { }

  /**
   * Returns true if current platform is a device
   */
  public isApp(): boolean {
    return !document.URL.startsWith('http') && !this.isMobileBrowser();
  }

  /**
   * Returns true if current platform is a native browser otherwise false
   */
  isMobileBrowser(): boolean {
    return this.platform.is('mobileweb');
  }

  /**
   * Returns true if current platform is a web browser otherwise false
   */
  isWebBrowser(): boolean {
    return document.URL.startsWith('http') && !this.platform.is('mobileweb');
  }
}
