import { Injectable } from '@angular/core';
import { fabric } from 'fabric-with-gestures';
import {AppConfig} from "../../../../../../config/app.config";
import {getReferenceCircleGroupId, getReferenceGroupId} from "../../../../../services/system/utilities/help.methods";
import {ExtensionTypes} from "../../fabric/extensions/models/extension-types/extension.types";

@Injectable({
  providedIn: 'root'
})
export class DrawRectActionsService {

  constructor() {
  }

  public drawRect(canvas: any, points?: any): void {

    if(!points) {
        points = [];
        const center = { x: 1280/2, y: 380 };
        points[0] = {x: AppConfig.Canvas.canvasWidth/2, y: 380};
        points[1] = {x: AppConfig.Canvas.canvasWidth/2, y: 380+100};
        points[2] = {x: AppConfig.Canvas.canvasWidth/2+100, y: 380+100};
        points[3] = {x: AppConfig.Canvas.canvasWidth/2+100, y: 380};
    }
    const polygon = new fabric.RectShape(points, {
        fill: 'rgba(0,0,0,0)',
        objectCaching: false,
        transparentCorners: false,
        originX: 'center',
        originY: 'center'
    });
      canvas.add(polygon);

      function polygonPositionHandler(dim, finalMatrix, fabricObject) {
          var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
              y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);

          if(!fabricObject.canvas) fabricObject.canvas = canvas;
          return fabric.util.transformPoint(
              { x: x, y: y },
              fabric.util.multiplyTransformMatrices(
                  fabricObject.canvas.viewportTransform,
                  fabricObject.calcTransformMatrix()
              )
          );
      }

      function actionHandler(eventData, transform, x, y) {
          var polygon = transform.target,
              currentControl = polygon.controls[polygon.__corner],
              mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
              polygonBaseSize = polygon._getNonTransformedDimensions(),
              size = polygon._getTransformedDimensions(0, 0),
              finalPointPosition = {
                  x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
                  y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
              };
          polygon.points[currentControl.pointIndex] = finalPointPosition;
          return true;
      }

      function anchorWrapper(anchorIndex, fn) {
          return function(eventData, transform, x, y) {
              var fabricObject = transform.target,
                  absolutePoint = fabric.util.transformPoint({
                      x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
                      y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
                  }, fabricObject.calcTransformMatrix()),
                  actionPerformed = fn(eventData, transform, x, y),
                  newDim = fabricObject._setPositionDimensions({}),
                  polygonBaseSize = fabricObject._getNonTransformedDimensions(),
                  newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
                  newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
              fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
              return actionPerformed;
          }
      }

      canvas.setActiveObject(polygon);

      var lastControl = polygon.points.length - 1;
      polygon.cornerStyle = 'circle';
      polygon.cornerColor = '#666';
      polygon.controls = polygon.points.reduce(function(acc, point, index) {
          acc['p' + index] = new fabric.Control({
              positionHandler: polygonPositionHandler,
              actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
              actionName: 'modifyPolygon',
              pointIndex: index
          });
          return acc;
      }, { });

      canvas.requestRenderAll();
  }
}
