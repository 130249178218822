import { ExtensionTypes } from '../../models/extension-types/extension.types';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initRectHiddenShapeExtension(): any {
  if (fabric.RectShapeHidden === undefined) {
    fabric.RectShapeHidden = fabric.util.createClass(fabric.Rect, {

      type: ExtensionTypes.RECT_SHAPE_HIDDEN_EXTENSION,

      initialize(options: any = {}) {

        this.callSuper('initialize', options);

        if (!this.id) {
          // extends the custom object with additional property
          this.set('id', ExtensionTypes.RECT_SHAPE_HIDDEN_EXTENSION + '_' + getRandomId());
        }

        if (!this.subType) {
          this.subType = ExtensionTypes.RECT_SHAPE_HIDDEN_EXTENSION;
        }

        this.selectable = false;
        this.visible = false;

      },
      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          referenceGroupId: this.referenceGroupId,
          id: this.id,
          subType: ExtensionTypes.RECT_SHAPE_HIDDEN_EXTENSION,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated
        });
      },
    });

    fabric.RectShapeHidden.fromObject = (object, callback) => {
      return fabric.Object._fromObject(ExtensionTypes.RECT_SHAPE_HIDDEN_EXTENSION, object, callback);
    };
  }
}