import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { RouterService } from './core/services/system/router/router.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SyncFactoryService } from './core/services/sync/factory/sync-factory.service';
import { SyncStagesTypes } from './core/services/sync/stages/sync.stages.types';
import { TranslationService } from './core/services/system/translation/translation.service';

/**
 * Consist bootstrap component of the app
 * @class AppComponent
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translationService: TranslationService,
    // this injection is required to store prev route
    private routerService: RouterService,
    private syncFactoryService: SyncFactoryService
  ) {
    this.initializeApp();
  }

  /**
   * Calls initialization of the app which includes
   * (hiding of splash screen, loading of language)
   * System info : Returns a promise when the platform is ready and native functionality
     can be called. If the app is running from within a web browser, 
     then the promise will resolve when the DOM is ready.
   */
  async initializeApp(): Promise<void> {
    await this.platform.ready();
    await this.translationService.initializeLanguage();

    const syncService = await this.syncFactoryService.getSyncService();

    if (syncService) {
      await syncService.startFullSync(SyncStagesTypes.init);
    }

    this.statusBar.styleDefault();
    this.splashScreen.hide();
  }
}
