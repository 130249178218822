import { AppConfig } from 'app/config/app.config';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserStorageData } from './model/user.storage.data.model';

/**
 * Manages storing user data into to the storage
 * @export
 * @class StorageService
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {

  /**
   * Stores key of the user is using for getting nad setting his config data through ionic storage
   */
  private readonly USER_SYNC_KEY = 'syncId';

  /**
   * Stores key of the user is using for getting nad setting his config data through ionic storage
   */
  private readonly USER_STORAGE_KEY = 'userData';

  /**
   * Stores key of the user language
   */
  private readonly USER_STORAGE_LANGUAGE_KEY = 'userLanguage';

  /**
   * Manages storing of user data into Storage using Storage service (by default ionic use IndexedDB as a storage)
   * @param {Storage} storage
   */
  constructor(private storage: Storage) { }

  /**
   * Sets user data in ionic storage
   * @param {any} user
   */
  public async setUserDataToStorage(user: UserStorageData, saveChanges?: boolean): Promise<UserStorageData> {
    const userStorageData = await this.storage.set(this.USER_STORAGE_KEY, user);

    if (saveChanges) {
      localStorage.removeItem('unSavedChanges');
    }

    return userStorageData;
  }

  /**
   * Gets use stored data from ionic storage
   * @param {any} user
   * @returns {Promise<any>}
   */
  public async getUserDataFromStorage(): Promise<UserStorageData> {
    let userStorageData = await this.storage.get(this.USER_STORAGE_KEY);

    if (!userStorageData) {
      userStorageData = new UserStorageData();
    }

    return userStorageData;
  }

  /**
   * Gets new incremented flag for synchronization
   * @param {any} user
   * @returns {Promise<any>}
   */
  public async getNewSyncId(): Promise<number> {

    let syncId = await this.storage.get(this.USER_SYNC_KEY) as number;
    syncId += 1;
    await this.storage.set(this.USER_SYNC_KEY, syncId);

    return syncId;
  }

  /**
   * Gets user storede language from ionic storage
   * @returns {Promise<string>}
   */
  async getUserLanguageFromStorage(): Promise<string> {
    return await this.storage.get(this.USER_STORAGE_LANGUAGE_KEY);
  }

  /**
   * Sets user language in ionic storage
   * @param {string} language
   * @returns {Promise<string>}
   */
  public async setUserLanguageToStorage(language: string): Promise<string> {
    return await this.storage.set(this.USER_STORAGE_LANGUAGE_KEY, language);
  }

  /**
   * Deletes all stored user's data from the storage
   */
  public async deleteUserData(): Promise<void> {
    await this.storage.remove(this.USER_STORAGE_LANGUAGE_KEY);
    await this.storage.remove(this.USER_STORAGE_KEY);
  }
}
