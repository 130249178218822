import { FolderInterface } from './folder.interface';
import { FolderTypes } from 'app/core/services/folder/model/folder.types';

/**
 * Implements all folder fields related with (creation, modification, deletion)
 * @class Folder
 */
export class Folder implements FolderInterface {
  appUid: number = 0;
  created: number;
  createdBy: number;
  createdUtc: number;
  deleted: number = 0;
  name: string;
  parentUid: number = 0;
  modifiedUtc: number;
  uid: number;
  type: FolderTypes = FolderTypes.PRIVATE;
  tactics: [];
}
