export enum PlayerTypes {
    FAV_PLAYER = 'FAV_PLAYER',
    PLAYER = 'PLAYER',
    PLAYER_RUNNING = 'PLAYER_RUNNING',
    PLAYER_PASSING = 'PLAYER_PASSING',
    PLAYER_SHOOTING = 'PLAYER_SHOOTING',
    PLAYER_DRIBBLING = 'PLAYER_DRIBBLING',
    PLAYER_COORDINATION = 'PLAYER_COORDINATION',
    PLAYER_STRETCHING = 'PLAYER_STRETCHING',
    TOP_VIEW_PLAYER = 'TOP_VIEW_PLAYER',
    CIRCLE_NUMBER = 'CIRCLE_NUMBER',
    TRIANGLE_NUMBER = 'TRIANGLE_NUMBER',
    COACH = 'COACH',
    GOALKEEPER = 'GOALKEEPER',
    EQUIPMENT_COLORS = 'EQUIPMENT_COLORS'
}
