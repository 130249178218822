import { TacticInterface } from './tactic.interface';

/**
 * Implements all tactic fields related with (creation, modification, deletion)
 * @class Tactic
 */
export class Tactic implements TacticInterface {
  uid: number;
  modified: number;
  modifiedBy: number;
  createdBy: number;
  createdUtc: number;
  modifiedUtc: number;
  tacticsFolderUid: number;
  tacticTypes: number;
  data: string;
  appUid: number;
  created: number ;
  deleted: number;
  name: string;
  type: number;
  image: string;
  link: string;
  linkThumb: string;
  linkMp4: string;
}
