import { CanvasActionsInterface } from 'app/core/drawing/interfaces/canvas.actions.interface';
import { CanvasEventTypes } from '../../events/model/canvas/canvas.event.types';
import { DrawSubjectService } from '../../../draw.subject.service';
import { FrameEventTypes } from '../../events/model/frame/frame.event.types';
import { FrameSubjectService } from '../../../frame.subject.service';
import { Injectable } from '@angular/core';
import { LoadTypes } from 'app/core/drawing/models/load-types/load.types';
import { StorageService } from 'app/core/services/system/storage/storage/storage.service';
import { fabric } from 'fabric-with-gestures';

/**
 * Represents extended functionality related to canvas/fabricjs
 * @class CanvasActionsService
 * @implements {CanvasActionsInterface}
 */
@Injectable({
  providedIn: 'root'
})
export class CanvasActionsService implements CanvasActionsInterface {

  constructor(
    private drawSubjectService: DrawSubjectService,
    private storageService: StorageService,
    private frameSubjectService: FrameSubjectService) {
  }

  /**
   * Triggers init over subject object
   * @param {any} subject
   * @param {CanvasEventTypes} event
   */
  public triggerEvent(subject: any, event: CanvasEventTypes): void {
    subject.next(event);
  }

  /**
   * Exports all stored data of the canvas (to json), which includes canvas and all stored object inside of it
   * @returns {string}
   */
  public export(canvasWrapper: any): string {
    const canvasObjects = canvasWrapper.canvas;

    return JSON.stringify([canvasObjects, { canvasProperties: canvasWrapper.canvasProperties }]);
  }

  /**
   * Imports json file to the canvas (canvas, all objects)
   * @param {string} canvasData
   * @param {boolean} [shouldSetCanvasProperties=true]
   */
  public import(
    canvasData: any, canvasWrapper: any,
    frameIndex: number, loadType: LoadTypes = LoadTypes.LOAD,
    callback: any, shouldVisible: boolean, frameEventType: FrameEventTypes): void {

    if (!canvasData) {
      return;
    }

    let canvasWrapperTemp;
    if (Array.isArray(canvasData) && canvasData.length === 2) {
      canvasWrapperTemp = canvasData;
    } else {
      canvasWrapperTemp = JSON.parse(canvasData);

      if (Array.isArray(canvasWrapperTemp) && canvasWrapperTemp.length === 1) {
        canvasWrapperTemp = JSON.parse(canvasWrapperTemp[0]);
      }
    }

    canvasWrapper.canvas.loadFromJSON(JSON.stringify(canvasWrapperTemp[0]), () => {
      // load custom stored properties
      canvasWrapper.canvasProperties = canvasWrapperTemp[1].canvasProperties;
      // set extend properties to the fabricjs interface
      if (canvasWrapper.canvasProperties && canvasWrapper.canvasProperties.viewportTransform) {
        // canvasWrapper.canvas.setViewportTransform(canvasWrapper.canvasProperties.viewportTransform);
      }

      if (callback) {
        callback(this.getAllObjects(canvasWrapper), canvasWrapper.canvas, canvasWrapper.canvasProperties);
      }

      this.frameSubjectService.frameSubject.next(
        {
          canvas: canvasWrapper.canvas, frameIndex,
          loadType, shouldVisible, frameEventType
        });

      this.drawSubjectService.subjectNative.next({ canvasWrapper, event: CanvasEventTypes.FULLY_LOADED });
    });
  }

  /**
   * Returns all canvas objects
   * @param {any} canvasWrapper
   */
  public getAllObjects(canvasWrapper: any): any {
    return canvasWrapper.canvas.getObjects();
  }

  /**
   * Creates multisection by passed objects
   * @param {any} canvasWrapper
   * @param {any[]} objects
   */
  public createMultiSelection(canvasWrapper: any, objects: any[]): void {
    canvasWrapper.canvas.discardActiveObject();
    const selection = new fabric.ActiveSelection(objects, {
      canvas: canvasWrapper.canvas
    });

    canvasWrapper.canvas.setActiveObject(selection);
    canvasWrapper.canvas.renderAll();
  }

  /**
   * @todo adds to the interface
   *
   * @param {*} canvas
   * @returns {boolean}
   * @memberof CanvasActionsService
   */
  public isMultiSelection(canvas: any): boolean {
    const selectedObjects = {};
    const activeObject = canvas.getActiveObject();

    if (activeObject && activeObject._objects && !activeObject.isGroup) {
      activeObject._objects.forEach(object => {
        if (object.referenceGroupId && !selectedObjects[object.referenceGroupId]) {
          selectedObjects[object.referenceGroupId] = 1;
        } else if (object.referenceGroupId && selectedObjects[object.referenceGroupId]) {
          selectedObjects[object.referenceGroupId] += 1;
        } else {
          selectedObjects[object.id] = 1;
        }
      });
    }

    return Object.keys(selectedObjects).length > 1;
  }

  /**
   * Hooks to specific fabricjs events and emits to all subscribers of the subjectDraw
   */
  public onEvents(canvasWrapper: any): void {
    canvasWrapper.canvas.on(CanvasEventTypes.DROP, () => {
      localStorage.setItem('unSavedChanges', 'true');

      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.DROP);
    });

    canvasWrapper.canvas.on(CanvasEventTypes.OBJECT_REMOVED, (e) => {
      localStorage.setItem('unSavedChanges', 'true');

      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.OBJECT_REMOVED);

      // stop removing of player object, if operation is change of color
      if (e.target.changeColor) {
        return;
      }

      this.drawSubjectService.subjectDraw.next({ event: CanvasEventTypes.OBJECT_REMOVED_WITH_OBJECT, object: e.target });
    });

    document.addEventListener(CanvasEventTypes.OBJECTS_ADDED_WITH_OBJECT, (e: CustomEvent) => {
      this.drawSubjectService.subjectDraw.next({ event: CanvasEventTypes.OBJECTS_ADDED_WITH_OBJECT, object: e.detail });
    });

    canvasWrapper.canvas.on(CanvasEventTypes.AFTER_RENDER, () => {
      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.AFTER_RENDER);
    });

    canvasWrapper.canvas.on(CanvasEventTypes.MOUSE_UP, () => {
      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.MOUSE_UP);
    });

    canvasWrapper.canvas.on(CanvasEventTypes.OBJECT_ROTATE, (e) => {
      localStorage.setItem('unSavedChanges', 'true');

      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.OBJECT_ROTATE);
      this.drawSubjectService.subjectDraw.next({ event: CanvasEventTypes.OBJECT_UPDATED_WITH_OBJECT, object: e.target });
    });

    canvasWrapper.canvas.on(CanvasEventTypes.OBJECT_ADDED, (e) => {
      // if object already is loaded we stop execution of 'object:added' event
      if (e.target.loaded) {
        return;
      }

      localStorage.setItem('unSavedChanges', 'true');

      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.OBJECT_ADDED);

      // stop adding of player object, if operation is change of color
      if (e.target.changeColor) {
        this.drawSubjectService.subjectDraw.next({ event: CanvasEventTypes.OBJECT_UPDATED_WITH_OBJECT, object: e.target });
        return;
      }

      // if (
      //   e.target.subType === ExtensionTypes.GROUP_PLAYER_EXTENSION ||
      //   e.target.type === ExtensionTypes.TEXT_EXTENSION ||
      //   e.target.type === ExtensionTypes.LINE_EXTENSION ||
      //   e.target.type === ExtensionTypes.CURVE_LINE_EXTENSION ||
      //   e.target.type === ExtensionTypes.RECT_SHAPE_EXTENSION ||
      //   e.target.type === ExtensionTypes.CIRCLE_SHAPE_EXTENSION ||
      //   e.target.type === ExtensionTypes.WAVE_LINE_EXTENSION ||
      //   e.target.type === ExtensionTypes.TRIANGLE_EXTENSION ||
      //   e.target.type === ExtensionTypes.FREE_LINE_EXTENSION ||
      //   e.target.subType === ExtensionTypes.GROUP_SHAPE_EXTENSION) {
      //   this.drawSubjectService.subjectDraw.next({ event: CanvasEventTypes.OBJECT_ADDED_WITH_OBJECT, object: e.target });
      // }
    });

    canvasWrapper.canvas.on(CanvasEventTypes.MOUSE_DOWN, () => {
      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.MOUSE_DOWN);
    });

    canvasWrapper.canvas.on(CanvasEventTypes.SELECTION_CREATED, (e) => {
      if (this.isMultiSelection(canvasWrapper.canvas)) {
        this.drawSubjectService.subjectDraw.next(CanvasEventTypes.MULTI_SELECTION_CREATED);
      } else {
        this.drawSubjectService.subjectDraw.next(CanvasEventTypes.SELECTION_UPDATED);
      }
    });

    canvasWrapper.canvas.on(CanvasEventTypes.SELECTION_CLEAR, (e) => {
      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.SELECTION_CLEAR);
    });

    canvasWrapper.canvas.on(CanvasEventTypes.SELECTION_UPDATED, (e) => {
      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.SELECTION_UPDATED);
    });

    canvasWrapper.canvas.on(CanvasEventTypes.OBJECT_SELECTED, () => {
      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.OBJECT_SELECTED);
    });

    canvasWrapper.canvas.on(CanvasEventTypes.OBJECT_MODIFIED, (e) => {
      localStorage.setItem('unSavedChanges', 'true');

      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.OBJECT_MODIFIED);

      this.drawSubjectService.subjectDraw.next({ event: CanvasEventTypes.OBJECT_UPDATED_WITH_OBJECT, object: e.target });

    });

    canvasWrapper.canvas.on(CanvasEventTypes.OBJECT_MOVED, (e) => {
      localStorage.setItem('unSavedChanges', 'true');

      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.OBJECT_MOVED);

      if (!e.target._objects) {
        this.drawSubjectService.subjectDraw.next({ event: CanvasEventTypes.OBJECT_UPDATED_WITH_OBJECT, object: e.target });

        // moved in multiselection mode
      } else if (e.target._objects) {
        e.target._objects.forEach(selectedObject => {
          this.drawSubjectService.subjectDraw.next({ event: CanvasEventTypes.OBJECT_UPDATED_WITH_OBJECT, object: selectedObject });
        });
      }
    });

    canvasWrapper.canvas.on(CanvasEventTypes.OBJECT_SCALED, (e) => {
      localStorage.setItem('unSavedChanges', 'true');

      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.OBJECT_SCALED);
      this.drawSubjectService.subjectDraw.next({ event: CanvasEventTypes.OBJECT_UPDATED_WITH_OBJECT, object: e.target });
    });
  }
}
