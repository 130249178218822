import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { AppRoutes } from 'app/shared/app.routes ';
import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { RouterSubjectService } from './router.subject.service';
import { SyncSubjectService } from '../../sync/sync.subject.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { filter } from 'rxjs/operators';

/**
 * Provides interface to navigating to the stored routes
 * @class RouterService
 */
@Injectable({
  providedIn: 'root'
})
export class RouterService {

  /**
   * Stores previous requested route
   * @type {string}
   */
  private previousUrl: string = undefined;

  /**
   * Stores the current requested route
   * @type {string}
   */
  private currentUrl: string = undefined;

  /**
   * Uses Router service to hook on particular events and to navigates to different routes
   * @param {Router} router
   */
  constructor(
    location: PlatformLocation,
    private router: Router,
    private syncSubjectService: SyncSubjectService,
    private routerSubjectService: RouterSubjectService) {

    this.currentUrl = this.router.url;

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });

    this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          if (event.url !== this.routerSubjectService.lastRoutePath) {
            this.routerSubjectService.lastRoutePath = event.url;
            this.routerSubjectService.routerSubject.next(event);
          }
        }
      });
  }

  /**
   * Returns the stored previous route
   * @returns {string}
   */
  public getPreviousUrl(): string {
    return this.previousUrl.replace('/', '');
  }

  /**
   * Navigates to the previous stored route
   */
  public goBack() {
    this.router.navigate([this.previousUrl]);
  }

  /**
   * Navigates to home page route
   */
  public goHome() {
    this.router.navigate(['/']);
  }

  /**
   * Navigates to the passed routeName
   * @param {string} routeName
   */
  public go(routeName: AppRoutes, extras: any = {}, params?: any) {
    // if (routeName === AppRoutes.MY_FOLDERS) {
    //    this.syncSubjectService.forceSubjectSynchronization.next();
    // }

    let parameters = [];

    if ((params || params === 0) && !Array.isArray(params)) {
      parameters.push(params);
    } else if (params) {
      parameters = params;
    }

    if (params && Object.keys(params).length === 1) {
      const key = Object.keys(params)[0];
      if (key && !params[key]) {
        parameters = [];
      }
    }

    this.router.navigate([routeName, ...parameters], extras);
  }
}
