import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FrameSubjectService {

  /**
   * Uses for emitting when some action is happened render, drop etc.
   * @type {Subject<any>}
   */
  public frameSubject: Subject<any>;

  constructor(
  ) {
    this.frameSubject = new Subject();
  }
}
