import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

import { Injectable } from '@angular/core';
import { ToastService } from 'app/core/services/general/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

/**
 * Handles all http exceptions triggered inside the application, shows generall message when the route name and method matched
 */
@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptorService implements HttpInterceptor {

  private whiteListRequests = Array<any>();

  /**
   * Stores subscription 
   * @type {Subscription}
   */
  private subscription: Subscription;

  constructor(private toastService: ToastService, private translate: TranslateService) {
    this.whiteListRequests = [
      { method: 'PUT', path: '/tactics/tactic' },
      { method: 'POST', path: '/tactics/tactic' },
      { method: 'GET', path: '/misc/languages' },
      { method: 'POST', path: 'tactics/user/login' },
      { method: 'POST', path: 'tactics/user' },
      { method: 'GET', path: 'details/full' },
      { method: 'GET', path: '/templates' }
    ];
  }

  /**
   * Returns predefined request defined for caching
   * @param {HttpRequest<any>} request
   * @returns {CacheRequest}
   */
  private getWhiteListRequest(request: HttpRequest<any>): boolean {
    const targetRequest = this.whiteListRequests.find(
      (item) => {
        return item.method === request.method && request.url.endsWith(item.path);
      });

    return targetRequest;
  }

  /**
   * Identifies and handles Server/Network request as showing loading toast
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.getWhiteListRequest(request)) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.subscription = this.translate.get('IN_PROGRESS').subscribe((translationText: string) => {
        this.toastService.presentSuccessToast(translationText, null, 20000);
      });
    }

    return next.handle(request).pipe(
      tap(event => {
        this.toastService.dismiss();
      })
    );
  }
}