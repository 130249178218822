import { AppConfig } from 'app/config/app.config';
import { ExtensionTypes } from '../models/extension-types/extension.types';
import { TextTypes } from 'app/core/drawing/models/text-types/text.types';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initTextExtension(): any {
  if (fabric.BaseText === undefined) {
    fabric.BaseText = fabric.util.createClass(fabric.IText, {
      type: ExtensionTypes.TEXT_EXTENSION,

      initialize(object, options: any = {}) {
        this.callSuper('initialize', object, options);

        this.set({ originX: 'center', originY: 'center' });

        if (!this.id) {
          // extends the custom object with additional property
          this.set('id', ExtensionTypes.TEXT_EXTENSION + '_' + getRandomId());
        }

        if (!this.actionType) {
          this.actionType = TextTypes.TEXT;
        }

        if (!this.actionSize) {
          this.actionSize = AppConfig.Canvas.minScaleSize;
        }

        if (!this.actionTextBold) {
          this.actionTextBold = false;
        }

        if (!this.actionTextUnderlined) {
          this.actionTextUnderlined = false;
        }

        if (!this.actionTextItalic) {
          this.actionTextItalic = false;
        }
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          actionTextBold: this.actionTextBold,
          actionTextItalic: this.actionTextItalic,
          actionTextUnderlined: this.actionTextUnderlined,
          actionName: this.actionName || this.text,
          actionSize: this.actionSize,
          actionType: this.actionType || TextTypes.TEXT,
          id: this.id,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated,
          shouldBringToFront: this.shouldBringToFront
        });
      },
    });

    fabric.BaseText.fromObject = (object, callback) => {
      const { text } = object;
      callback(new fabric.BaseText(text, object));
    };
  }
}
