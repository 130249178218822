import { fabric } from 'fabric-with-gestures';

export function initSvgExtension(): any {
    if (!fabric.Object.prototype.getPathLength) {
        fabric.Object.prototype.getPathLength = function (from: number, to: number, cpx: number, cpy: number, x: number, y: number) {

            // output the curve in SVG bezier syntax
            const svgQuadratic = `M${from} ${to} Q ${cpx} ${cpy}, ${x} ${y}`;

            // create a new <path> element
            const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            // add the curve
            path.setAttribute('d', svgQuadratic);

            return path.getTotalLength();
        };
    }
}
