import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DrawingFabricService } from 'app/core/drawing/services/fabric/fabric/drawing.fabric.service';
import { HttpBaseService } from '../system/http-base/http-base.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tactic } from './model/tactic.model';
import { TacticAdapter } from './adapters/tactic.adapter';
import { TacticDetailsAdapter } from './adapters/tactic.details.adapter';
import { TacticImageType } from './model/tactric.image.type';
import { TacticInterface } from './model/tactic.interface';
import { UploadService } from './../general/upload/upload.service';

/**
 * Provides managing of tactics (creation, update, obtaining of predefined tactics) data through REST API
 * @class TacticService
 * @extends {HttpBaseService}
 */
@Injectable({
  providedIn: 'root',
})
export class TacticService extends HttpBaseService {
  /**
   * Provides main path for this particular service
   * @private
   * @type {string}
   */
  private url: string;
  /**
   * Extends HttpBaseService which is responsible for communication with the REST API
   * @param {HttpClient} http
   * @param {TacticAdapter} tacticAdapter
   * @param {TacticDetailsAdapter} tacticDetailsAdapter
   * @param {UploadService} uploadService
   */
  constructor(
    private http: HttpClient,
    private tacticAdapter: TacticAdapter,
    private tacticDetailsAdapter: TacticDetailsAdapter,
    private uploadService: UploadService,
    private drawingFabricService: DrawingFabricService
  ) {
    super();
    this.url = `${this.baseUrl}/tactics`;
  }

  /**
   * Get request gets all details for all tactics
   * @returns {Observable<Tactic[]>}
   */
  public getAllTactic(): Observable<Tactic[]> {
    return new Observable<Tactic[]>((observer) => {
      this.http
        .get(`${this.url}/tactics`).subscribe((data: Array<any>) => {
          let tactics = data.map((item) => this.tacticAdapter.adapt(item));
          tactics = tactics.filter((tactic) => tactic.deleted === 0);

          observer.next(tactics);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Returns differences between parameter provided
   * tactics and the backend tactics
   * @returns {Observable<Tactic[]>}
   */
  public getTactics(localTactics?: any): Observable<Tactic[]> {
    return new Observable<Tactic[]>((observer) => {
      this.http
        .post(`${this.url}/tactics`, localTactics).subscribe((data: Array<any>) => {
          let remoteTactics = data.map((item) => this.tacticAdapter.adapt(item));
          remoteTactics = remoteTactics.filter((tactic) => tactic.deleted === 0);

          observer.next(remoteTactics);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Get request gets a tactic without the json
   * @param {number} id
   * @returns {Observable<Tactic>}
   */
  public getTactic(id: number): Observable<Tactic> {
    return new Observable<Tactic>((observer) => {
      this.http
        .get(`${this.url}/tactic/${id}/details/list`).subscribe((data) => {
          observer.next(this.tacticAdapter.adapt(data));
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Get gets tactics with json data
   * @param {number} id
   * @returns {Observable<Tactic>}
   */
  public getDetailsTactic(id: number): Observable<Tactic> {
    return new Observable<Tactic>((observer) => {
      this.http
        .get(`${this.url}/tactic/${id}/details/full`).subscribe((data) => {
          observer.next(this.tacticDetailsAdapter.adapt(data));
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Get gets tactics with json data
   * @param {number} id
   * @param token
   * @returns {Observable<Tactic>}
   */
  public getDetailsTacticByToken(id: number, token: string): Observable<Tactic> {
    return new Observable<Tactic>((observer) => {

      const httpOptions = {
        headers: new HttpHeaders({
          // 'Content-Type':  'application/json',
          Authorization: `Bearer ${token}`,
        })
      };

      this.http
        .get(`${this.url}/tacticPlayer/${id}/details/full`, httpOptions).subscribe((data) => {
          observer.next(this.tacticDetailsAdapter.adapt(data));
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Put request updates tempt tactic files to update a tactic
   * @param {Tactic} tactic
   * @returns {Observable<any>}
   */
  public async updateTactic(tactic: Tactic): Promise<any> {
    await this.executeUpdateTacticSteps(tactic);
  }

  /**
   * Post request creates a new tactic from tactic temp
   * @param {Tactic} tactic
   * @returns {Observable<any>}
   */
  public async addTactic(tactic: Tactic): Promise<TacticInterface> {
    return await this.executeAddTacticSteps(tactic);
  }

  /**
   * Put request deletes tempt tactic files to update a tactic
   * @param {Tactic} tactic
   * @returns {Observable<any>}
   */
  public async deleteTactic(tactic: Tactic): Promise<any> {
    tactic.deleted = 1;
    await this.executeUpdateTacticSteps(tactic);
  }

  /**
   * Put request updates an existing tactic with a temp tactic
   * @param {number} id
   * @returns {Observable<any>}
   */
  private finishUpdateTactic(id: number): Observable<any> {
    return new Observable<any>((observer) => {
      this.http
        .put(`${this.url}/finish/tactic/${id}`, null).subscribe((data) => {
          observer.next(data);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Post request creates new temp tactic to prepare create new tactic
   * @param {Tactic} tactic
   * @returns {Observable<number>}
   */
  private addTempTactic(tactic: TacticInterface): Observable<number> {
    return new Observable<number>((observer) => {
      this.http
        .post(`${this.url}/tactic`, {
          name: tactic.name,
          deleted: tactic.deleted,
          app_uid: 0,
          data: tactic.data,
          tactic_types: tactic.tacticTypes,
          tactics_folder_uid: tactic.tacticsFolderUid,
        }).subscribe((data: number) => {
          observer.next(data);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Post request creates new temp tactic to prepare create new tactic
   * @param {Tactic} tactic
   * @returns {Observable<number>}
   */
  private updateTempTactic(tactic: Tactic): Observable<number> {
    return new Observable<number>((observer) => {
      this.http
        .put(`${this.url}/tactic`, {
          server_uid: tactic.uid,
          name: tactic.name,
          deleted: tactic.deleted,
          app_uid: 0,
          data: tactic.data,
          tactic_types: tactic.tacticTypes,
          tactics_folder_uid: tactic.tacticsFolderUid,
        }).subscribe((data: number) => {
          observer.next(data);
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Adds image to selected tactic by type (image/thumb)
   * @param {FormData} formData
   * @param {number} id
   * @param {TacticImageType} type
   * @returns {Observable<any>}
   */
  private addImageToTactic(formData: FormData, id: number, type: TacticImageType): Observable<any> {
    if (!formData || !id) {
      return null;
    }

    return new Observable<any>((observer) => {
      this.http
        .post(`${this.url}/tactic/${id}/${type}`, formData).subscribe(() => {
          observer.next();
          observer.complete();
        });
    });
  }

  /**
   * Post request creates a new tactic from tactic temp
   * @param {number} id
   * @returns {Observable<number>}
   */
  private finishCreateTactic(id: number): Observable<TacticInterface> {
    return new Observable<TacticInterface>((observer) => {
      this.http
        .post(`${this.url}/finish/tactic/${id}`, null).subscribe((data: any) => {
          observer.next(this.tacticAdapter.adapt(data.tactic));
          observer.complete();
        }, (error) => { observer.error(error); });
    });
  }

  /**
   * Executes a series of steps to finish creation of tactic
   * @param {number} id
   * @returns {Observable<number>}
   */
  private async executeAddTacticSteps(tempTactic: TacticInterface): Promise<TacticInterface> {
    const tempTacticUid = await this.addTempTactic(tempTactic).toPromise();

    const formDataLink = await this.uploadService.getFormDataBy(tempTactic.name, tempTactic.image || tempTactic.link);

    const formDataThumb = await this.uploadService
      .getFormDataBy(tempTactic.name,
        (tempTactic.image && await this.drawingFabricService.resizeImage(tempTactic.image)) || tempTactic.linkThumb);

    await this.addImageToTactic(formDataLink, tempTacticUid, TacticImageType.IMAGE).toPromise();
    await this.addImageToTactic(formDataThumb, tempTacticUid, TacticImageType.THUMB).toPromise();

    return await this.finishCreateTactic(tempTacticUid).toPromise();
  }

  /**
   * Executes a series of steps to finish updating of tactic
   * @param {number} id
   * @returns {Observable<number>}
   */
  private async executeUpdateTacticSteps(tempTactic: Tactic): Promise<any> {
    const tempTacticUid = await this.updateTempTactic(tempTactic).toPromise();

    const formDataLink = await this.uploadService.getFormDataBy(tempTactic.name, tempTactic.image || tempTactic.link);

    const formDataThumb = await this.uploadService.
      getFormDataBy(tempTactic.name,
        (tempTactic.image && await this.drawingFabricService.resizeImage(tempTactic.image)) || tempTactic.linkThumb);

    await this.addImageToTactic(formDataLink, tempTacticUid, TacticImageType.IMAGE).toPromise();
    await this.addImageToTactic(formDataThumb, tempTacticUid, TacticImageType.THUMB).toPromise();
    await this.finishUpdateTactic(tempTacticUid).toPromise();
  }
}
