import { AppConfig } from 'app/config/app.config';
import { CanvasEventTypes } from '../../events/model/canvas/canvas.event.types';
import { CanvasOptionsInterface } from 'app/core/drawing/interfaces/canvas.options.interface';
import { DrawSubjectService } from '../../../draw.subject.service';
import { Injectable } from '@angular/core';
import { fabric } from 'fabric-with-gestures';

/**
 * Represents interface for basic/system operations related with the canvas
 * @class CanvasOptionsService
 * @implements {CanvasOptionsInterface}
 */
@Injectable({
  providedIn: 'root'
})
export class CanvasOptionsService implements CanvasOptionsInterface {

  constructor(private drawSubjectService: DrawSubjectService) {
  }

  /**
   * Returns entire canvas as a image compressed with specified quality parameter
   * @param {number} [quality]
   * @returns {string}
   */
  public getSnapShotByQuality(quality: number, canvasWrapper: any, bbox: any = {}): string {
    const obj: any = {};
    obj.quality = quality;

    const clientHeight = AppConfig.Image.ExportSize.height;
    const clientWidth = AppConfig.Image.ExportSize.width;
    this.resizeCanvas(canvasWrapper, clientWidth, clientHeight);

    if (bbox.hasOwnProperty('left')) {
      obj.left = bbox.left;
      obj.top = bbox.top;
      obj.width = bbox.width;
      obj.height = bbox.height;
    }

    const img = canvasWrapper.canvas.toDataURL(obj);

    this.resizeCanvas(canvasWrapper);

    return img;
  }

  /**
   * Creates temp canvas load current base64 generated from the original canvas
   * returns new resized base64 image
   * @param {string} base64Str
   * @param {number} [maxWidth]
   * @param {number} [maxHeight]
   * @returns {Promise<string>}
   */
  public resizeImage(base64Str: string, maxWidth: number, maxHeight: number): Promise<string> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());

        canvas.remove();
      }
    });
  }

  /**
   * Resizes canvas based on the current screen size
   */
  public resizeCanvas(canvasWrapper: any, rWidth?: any, rHeight?: any): void {

    const clientHeight = rHeight || document.body.clientHeight;
    let clientWidth = rWidth || document.body.clientWidth;

    const canvasWidth = canvasWrapper.canvas.getWidth();
    const canvasHeight = canvasWrapper.canvas.getHeight();
    const ratio = canvasWidth / canvasHeight;
    const maxHeight = (clientHeight - canvasWrapper.canvasOffset);

    // bypass fitting to the screen if we pass explicitly width
    if (!rWidth && (clientWidth / ratio >= maxHeight)) {
      clientWidth = ratio * maxHeight;
    }

    const scale = clientWidth / canvasWidth;
    const zoom = canvasWrapper.canvas.getZoom() * scale;
    const height = clientWidth / ratio;

    canvasWrapper.canvas.setDimensions({ width: clientWidth, height });
    canvasWrapper.canvasProperties.maxViewPortZoom = zoom;
    canvasWrapper.canvas.setZoom(zoom);
  }

  /**
   * Returns entire canvas as a image
   * @returns {string}
   */
  getSnapShot(canvasWrapper: any): string {
    return canvasWrapper.canvas.toDataURL();
  }

  setCanvasSize(canvasWrapper, canvasSizeObject) {
    canvasWrapper.canvas.setWidth(canvasSizeObject.width);
    canvasWrapper.canvas.setHeight(canvasSizeObject.height);
  }

  public setCanvasZoom(canvasWrapper, canvasScale, canvasOriginalWidth, canvasOriginalHeight) {
    const canvasWidth = canvasOriginalWidth * canvasScale;
    const canvasHeight = canvasOriginalHeight * canvasScale;

    canvasWrapper.canvas.setWidth(canvasWidth);
    canvasWrapper.canvas.setHeight(canvasHeight);
  }

  /**
   * Sets background of the canvas (svg from the config file)
   */
  public setBackgroundImage(url: string, canvasWrapper: any, shouldResizeCanvas: boolean): void {
    canvasWrapper.canvasProperties.backgroundPath = url;

    fabric.loadSVGFromURL(url, (objects, options) => {
      const groupSvg = fabric.util.groupSVGElements(objects, options);
      groupSvg.scaleToHeight(canvasWrapper.canvas.getHeight());

      const ratio = groupSvg.width / groupSvg.height;
      let clientWidth = document.body.clientWidth;
      let clientHeight = document.body.clientHeight;

      // force loading canvas to be in defined resultion
      clientWidth = AppConfig.Canvas.canvasWidth;
      clientHeight = AppConfig.Canvas.canvasWidth / ratio;

      if (shouldResizeCanvas) {
        canvasWrapper.canvas.setDimensions({ width: clientWidth, height: clientWidth / ratio });
        canvasWrapper.canvas.setBackgroundImage(groupSvg, canvasWrapper.canvas.renderAll.bind(canvasWrapper.canvas), {
          scaleX: canvasWrapper.canvas.width / groupSvg.width,
          scaleY: canvasWrapper.canvas.height / groupSvg.height
        });
        this.resizeCanvas(canvasWrapper);
        // updating background with other image

        canvasWrapper.canvas.set('zoomDefault', canvasWrapper.canvas.getZoom());
        canvasWrapper.canvas.set('widthDefault', canvasWrapper.canvas.width);
      } else {
        canvasWrapper.canvas.setBackgroundImage(groupSvg, canvasWrapper.canvas.renderAll.bind(canvasWrapper.canvas), {
          scaleX: canvasWrapper.canvas.backgroundImage.scaleX,
          scaleY: canvasWrapper.canvas.backgroundImage.scaleY,
        });
      }

      canvasWrapper.canvas.setActiveObject(groupSvg);
      groupSvg.set({ hasControls: false, hasBorders: false });
      canvasWrapper.canvas.renderAll();

      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.BACK_UPDATED);
    });
  }

  /**
   * Sets zoom to the entire canvas
   * @param {number} [zoomValue]
   */
  public setZoom(zoomValue: number, canvasWrapper: any): void {
    const delta = zoomValue;

    let zoom = canvasWrapper.canvas.getZoom();
    zoom = zoom - delta / 100;

    if (zoom > 8) {
      zoom = 8;
    }

    if (zoom < canvasWrapper.canvas.get('zoomDefault')) {
      zoom = canvasWrapper.canvas.get('zoomDefault');
    }

    canvasWrapper.canvas.zoomToPoint({ x: canvasWrapper.canvas.width / 2, y: canvasWrapper.canvas.height / 2 }, zoomValue);
    canvasWrapper.canvas.renderAll();
  }

  /**
   * Sets zoom to fit the entire canvas size
   * @param {any} canvasWrapper
   */
  public setZoomToFit(canvasWrapper: any): void {
    if (canvasWrapper.canvas.get('zoomDefault') && canvasWrapper.canvas.get('widthDefault')) {
      let zoom = canvasWrapper.canvas.get('zoomDefault') / canvasWrapper.canvas.get('widthDefault');
      zoom *= canvasWrapper.canvas.width;

      canvasWrapper.canvas.zoomToPoint({
        x: canvasWrapper.canvas.width / 2,
        y: canvasWrapper.canvas.height / 2
      }, zoom);

      canvasWrapper.canvas.setViewportTransform
        ([zoom, 0, 0, zoom, 0, 0]);
    }
  }
}
