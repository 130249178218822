import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { of, from, Observable } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { StorageService } from 'app/core/services/system/storage/storage/storage.service';
import CacheRequest from './model/cache.request';
import CacheResponse from './model/cache.response';
import { UserStorageData } from 'app/core/services/system/storage/storage/model/user.storage.data.model';

/**
 * Stores responses for predefined requests
 * Handles requests giving them cached version of the response
 * @class CacheInterceptorService
 */
@Injectable()
export class CacheInterceptorService implements HttpInterceptor {
  /**
   * Stores all defined requests that must be cached
   */
  private whiteListRequests = Array<CacheRequest>();

  /**
   * Uses StorageService to manage updating and removing of user's stored data
   * @param {StorageService} storageService
   */
  constructor(private storageService: StorageService) {
    this.whiteListRequests = [
      { method: 'GET', path: 'tactics/user', cachePeriod: 10 * 60000 } as CacheRequest
    ];
  }

  /**
   * Returns true if stored data is old (cachePeriod is reached) otherwise returns false
   * @param {UserStorageData} userStorageData
   * @returns {boolean}
   */
  private isDataExpired(timeStamp: number, request: HttpRequest<any>): boolean {
    const targetWhiteListRequest = this.getWhiteListRequest(request);

    if (targetWhiteListRequest) {
      return timeStamp + targetWhiteListRequest.cachePeriod < Date.now();
    }

    return true;
  }

  /**
   * Returns predefined request defined for caching
   * @param {HttpRequest<any>} request
   * @returns {CacheRequest}
   */
  private getWhiteListRequest(request: HttpRequest<any>): CacheRequest {
    const targetWhiteListRequest = this.whiteListRequests.find(
      (item) => {
        return item.method === request.method && request.url.endsWith(item.path);
      });

    return targetWhiteListRequest;
  }

  /**
   * Identifies and handles cached version of response and the actual data coming from the REST API
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET' && request.method !== 'OPTIONS') {
      return next.handle(request);
    }

    return from(this.storageService.getUserDataFromStorage()).pipe(
      switchMap((userStorageData: UserStorageData) => {
        if (!userStorageData) {
          userStorageData = new UserStorageData();
        }
        // if we find cached version of the request return only body of the response
        if (userStorageData.cachedInterceptorResponses) {
          const cachedResponse: CacheResponse = userStorageData.cachedInterceptorResponses[request.url];
          const showCachedVersion = Boolean(request.params.get('showCachedVersion'));
          const isDataNotExpired = cachedResponse && !this.isDataExpired(cachedResponse.timeStamp, request);
          if (showCachedVersion && isDataNotExpired) {
            return of(new HttpResponse({ body: cachedResponse.value }));
          }
        }

        return next.handle(request).pipe(
          tap(event => {
            if (event instanceof HttpResponse) {
              const targetWhiteListRequest = this.getWhiteListRequest(request);

              // if requested url (method and path) is described in the white list we save it in the storage
              if (targetWhiteListRequest) {
                userStorageData.cachedInterceptorResponses[request.url] = { value: event.body, timeStamp: Date.now() } as CacheResponse;
                this.storageService.setUserDataToStorage(userStorageData);
              }
            }
          })
        );
      })
    );
  }
}