import { ArrowLineThick } from 'app/core/drawing/models/arrow-line/arrow-line-thick/arrow.line.thick';
import {ArrowTypes} from "../../../../../models/arrow-types/arrowtypes";
import { CurveLineTypes } from 'app/core/drawing/models/curve-line-types/curve.line.types';
import { ExtensionTypes } from 'app/core/drawing/services/fabric/fabric/extensions/models/extension-types/extension.types';
import { LineTypes } from 'app/core/drawing/models/line-types/line.types';
import { ThicknessTypes } from 'app/core/drawing/models/thickness-types/thickness.line';
import { ThinLine } from 'app/core/drawing/models/thickness-line/thin-line/thin.line';
import { WaveLineTypes } from 'app/core/drawing/models/wave-line-types/wave.line.types';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initBaseCurveExtension(): any {
  if (fabric.BaseCurveLine === undefined) {
    fabric.BaseCurveLine = fabric.util.createClass(fabric.Line, {

      type: ExtensionTypes.CURVE_LINE_EXTENSION,

      initialize(coords = null, options: any = {}, noVisible?: boolean) {
        options.strokeWidth = options.strokeWidth || new ThinLine().width;
        options.stroke = options.stroke || 'black';
        options.selectable = true;
        options.lockScalingX = true;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.lockScalingY = true;
        options.lockUniScaling = true;
        options.lockRotation = true;
        options.objectCaching = false;
        options.dirty = true;
        options.padding =10;

        this.callSuper('initialize', coords, options);

        this.set({
              hasBorders: false,
              hasControls: false,
        });

        if(!this.cpx && !this.cpy){
            this.cpx = (this.x1 + this.x2)/2;
            this.cpy = (this.y1 + this.y2)/2;
        }

        if (!this.id) {
          this.set('id', ExtensionTypes.CURVE_LINE_EXTENSION + '_' + getRandomId());
        }

        if (!this.actionType) {
          this.actionType = CurveLineTypes.CURVE_LINE;
        }

        if (!this.actionLineType) {
          this.actionLineType = LineTypes.SOLID_LINE;
        }

        if (!this.arrowType) {
          this.arrowType = new ArrowLineThick();
        }

        if (!this.actionTicknessType) {
          this.actionTicknessType = ThicknessTypes.THIN_LINE;
        }

        if (!this.subType) {
          this.subType = ExtensionTypes.CURVE_LINE_EXTENSION;
        }

        this.waveType = options.waveType;

        if (noVisible !== undefined) {
          this.noVisible = true;
          this.visible = false;
        }
      },

        _render: function(ctx) {

            if (this.arrowType.type === ArrowTypes.TINY_ARROW || this.arrowType.type === ArrowTypes.TICK_ARROW) {
                this.drawArrow(ctx, this.arrowType.x, this.arrowType.y);
            }

            if (this.arrowType.type === ArrowTypes.ONLY_POINT) {
                this.drawPoint(ctx, this.arrowType.x);
            }

            if(this.actionLineType === LineTypes.SOLID_LINE ||
                this.actionLineType === LineTypes.DASHED_TINY ||
                this.actionLineType === LineTypes.DASHED_WAIDER) {
                this.quadraticCurve(ctx);
            }

            if(this.actionLineType === WaveLineTypes.WAVE_LINE || this.actionLineType === WaveLineTypes.WAVE_NARROW_LINE) {
                this.drawWaveLine(ctx);
            }
        },

        drawArrow: function(ctx,x,y) {
            ctx.save();
            const xDiff = this.x2 - this.x1;
            const yDiff = this.y2 - this.y1;
            const angle = Math.atan2((this.y2 - this.cpy), (this.x2 - this.cpx));
            ctx.translate(xDiff / 2, yDiff / 2);
            ctx.rotate(angle);
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(-x, y);
            ctx.lineTo(-x, -y);
            ctx.closePath();
            ctx.fillStyle = this.stroke;
            ctx.fill();
            ctx.restore();
        },

        drawPoint: function(ctx, radius) {
            ctx.save();
            const xDiff = this.x2 - this.x1;
            const yDiff = this.y2 - this.y1;
            ctx.translate(xDiff / 2, yDiff / 2);
            ctx.beginPath();
            ctx.arc(0, 0, radius, 0, 2 * Math.PI, true);
            ctx.fillStyle = this.stroke;
            ctx.fill();
            ctx.restore();
        },

        quadraticCurve: function(ctx){
            var p = this.calcLinePoints();

            const xC = (this.x2 + this.x1)/2;
            const yC = (this.y2 + this.y1)/2;
            var cpx = this.cpx - xC,
                cpy = this.cpy - yC;

            ctx.save();
            ctx.beginPath();
            if(this.strokeDashArray) ctx.setLineDash(this.strokeDashArray);
            ctx.moveTo(p.x1, p.y1);
            ctx.quadraticCurveTo(cpx, cpy, p.x2, p.y2);
            ctx.lineWidth = this.strokeWidth;
            ctx.fillStyle = this.stroke;
            ctx.stroke();
            this._renderStroke(ctx);
            ctx.restore();
        },
        drawWaveLine: function(ctx) {
            var p = this.calcLinePoints(), freq;

            if( this.actionLineType === WaveLineTypes.WAVE_LINE ) freq = 40;
            if( this.actionLineType === WaveLineTypes.WAVE_NARROW_LINE ) freq = 20;

            const xC = (this.x2 + this.x1)/2;
            const yC = (this.y2 + this.y1)/2;
            var dist = Math.sqrt( Math.pow((this.x1-this.x2), 2) + Math.pow((this.y1-this.y2), 2) );

            var cpx = this.cpx - xC,
                cpy = this.cpy - yC;

            var coord1 = this.getBezierXY(0, p.x1, p.y1, cpx, cpy, p.x2, p.y2);
            var point = this.pointOnLine(this.point(p.x2, p.y2), this.point(cpx, cpy), 5);

            var a = Math.atan2((this.cpy - this.y1), (this.cpx - this.x1));
            var dist = 0;
            var lastPoint = coord1;
            for(var i=1;i<1000;i++) {
                var coord1 = this.getBezierXY(i / 1000, p.x1, p.y1, cpx, cpy, point.x, point.y);
                dist = Math.sqrt( Math.pow((lastPoint.x-coord1.x), 2) + Math.pow((lastPoint.y-coord1.y), 2) );

                if(dist>=freq){
                    var a = Math.atan2((coord1.y - lastPoint.y), (coord1.x - lastPoint.x));
                    this.drawSine(ctx, a, lastPoint.x, lastPoint.y, freq );
                    lastPoint = coord1;
                }
            }

            ctx.moveTo(lastPoint.x, lastPoint.y);
            ctx.lineTo(point.x, point.y);
            ctx.lineTo(p.x2, p.y2);

            ctx.lineWidth = this.strokeWidth;
            ctx.fillStyle = this.stroke;
            ctx.stroke();

            this._renderStroke(ctx);
        },

        drawSine: function(ctx,angle, tx, ty, freq) {
            var width = freq;
            var amplitude = 5;
            var frequency = 1;
            var step = 1;
            var i;

            ctx.save();
            ctx.translate(tx, ty);
            ctx.rotate(angle);
            ctx.beginPath();
            ctx.moveTo(0, 0);
            var c = width / Math.PI / (frequency * 2);

            for (i = 0; i <= width; i += step) {
                var x = amplitude * Math.sin(i / c);
                ctx.lineTo(i,  x);
            }
            ctx.lineWidth = this.strokeWidth;
            ctx.fillStyle = this.stroke;
            ctx.stroke();

            ctx.restore();


        },
        gradient: function(a, b) {
          return (b.y-a.y)/(b.x-a.x);
        },

        getBezierXY: function(t, sx, sy, cp1x, cp1y, ex, ey) {
            return {
                x: (1-t) * (1-t) * sx + 2 * (1-t) * t * cp1x + t * t * ex,
                y: (1-t) * (1-t) * sy + 2 * (1-t) * t * cp1y + t * t * ey
            };
        },

        point: function(x, y) {
            return {
                x: x,
                y: y
            };
        },

        pointOnLine: function(point1, point2, dist) {
            var len = Math.sqrt(((point2.x - point1.x) * (point2.x - point1.x)) + ((point2.y - point1.y) * (point2.y - point1.y)));
            var t = (dist) / len;
            var x3 = ((1 - t) * point1.x) + (t * point2.x),
                y3 = ((1 - t) * point1.y) + (t * point2.y);
            return new fabric.Point(x3, y3);
        },

      toObject() {

          const xC = (this.x2 + this.x1)/2;
          const yC = (this.y2 + this.y1)/2;
          var cpx = this.cpx - xC,
              cpy = this.cpy - yC;

          return fabric.util.object.extend(this.callSuper('toObject'), {
              arrowType: this.arrowType,
              waveType: this.waveType,
              referenceGroupId: this.referenceGroupId,
              actionTicknessType: this.actionTicknessType || ThicknessTypes.THIN_LINE,
              actionType: this.actionType || CurveLineTypes.CURVE_LINE,
              actionLineType: this.actionLineType || LineTypes.SOLID_LINE,
              subType: ExtensionTypes.CURVE_LINE_EXTENSION,
              cpx: cpx,
              cpy: cpy,
              id: this.id,
              loaded: true,
              deleted: this.deleted,
              updated: this.updated,
              shouldBringToFront: this.shouldBringToFront
          });
      },
    });

    fabric.BaseCurveLine.fromObject = (object: any, callback: any) => {
      const { x1, y1, x2, y2 } = object;
      callback(new fabric.BaseCurveLine([x1, y1, x2, y2], object));
    };
  }
}