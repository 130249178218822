import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterSubjectService {

  /**
   * Uses for emitting all user actions related with browser back forward
   * @type {Subject<any>}
   */
  public routerSubject: Subject<any>;

  public lastRoutePath: string;

  constructor(
  ) {
    this.routerSubject = new Subject();
  }
}
