import { Injectable } from '@angular/core';
import { IonicAuthStorageService } from '../../system/storage/auth/ionic.storage.auth.service';
import { PlatformDetectorService } from '../../system/platform/platform-detector.service';
import { SyncAppService } from './../types/app/sync-app.service';
import { SyncBrowserService } from '../types/browser/sync-browser.service';
import { SyncServiceInterface } from '../interfaces/sync.service.interface';

@Injectable({
  providedIn: 'root'
})
export class SyncFactoryService {

  constructor(
    private ionicAuthStorageService: IonicAuthStorageService,
    private platformDetectorService: PlatformDetectorService,
    private syncAppService: SyncAppService,
    private syncBrowserService: SyncBrowserService
  ) { }

  public async getSyncService(): Promise<SyncServiceInterface> {
    const isTokenExpired = await this.ionicAuthStorageService.isTokenExpired();

    if (isTokenExpired) {
      return;
    }

    if (this.platformDetectorService.isApp()) {
      return this.syncAppService;
    } else {
      return this.syncBrowserService;
    }
  }
}
