import { getRelatedDoubleLineObjects, moveLine } from '../../fabric/extensions/double-line-extension/double.line.extension';

import { AppConfig } from 'app/config/app.config';
import { CanvasEventTypes } from 'app/core/drawing/services/fabric/events/model/canvas/canvas.event.types';
import { DrawBaseFreeLineActionsService } from './../../object/line-actions/draw-base-freeline-actions.service';
import { DrawLinesActionsService } from 'app/core/drawing/services/fabric/object/line-actions/draw-line-actions.service';
import { DrawPlayerActionsService } from '../../object/line-actions/draw-player-actions.service';
import { DrawSubjectService } from '../../../draw.subject.service';
import { DrawTacticActionsService } from './../../object/line-actions/draw-tactic-actions.service';
import { ExtendFigure } from '../../object/line-actions/model/figure-model/figure.model';
import { ExtensionTypes } from '../../fabric/extensions/models/extension-types/extension.types';
import { Figure } from 'app/core/drawing/models/figure/figure.model';
import { FrameEventTypes } from '../model/frame/frame.event.types';
import { FrameSubjectService } from '../../../frame.subject.service';
import { Injectable } from '@angular/core';
import { LoadTypes } from 'app/core/drawing/models/load-types/load.types';
import { MiscTypes } from 'app/core/drawing/models/misc-types/misc.types';
import { PlatformDetectorService } from 'app/core/services/system/platform/platform-detector.service';
import { PlayerTypes } from 'app/core/drawing/models/player-types/player.types';
import { StorageService } from 'app/core/services/system/storage/storage/storage.service';
import { Subscription } from 'rxjs';
import { TextTypes } from 'app/core/drawing/models/text-types/text.types';
import { calcAngle } from 'app/core/services/system/utilities/help.methods';
import { fabric } from 'fabric-with-gestures';
import { setPathDimension } from '../../fabric/extensions/path-extension/path.extension';
import { updateExtraPointCircle } from '../../fabric/extensions/extra-circle-extension/extra.circle.extension';

//import { ExtensionTypes } from 'app/core/drawing/services/fabric/fabric/extensions/models/extension-types/extension.types';

/**
 * @todo separate events from initial positioning of related objects method initObjectsEvents
 * (now we have duplication of code and multi tasks on that method)
 * Represents interface for handling the canvas events
 * @class EventsHandlerService
 */
@Injectable({
  providedIn: 'root'
})
export class EventsHandlerService {

  /**
   * Stores subscription to drawingService subjectDraw
   * @type {Subscription}
   */
  private subscription: Subscription;

  /**
   * Stores subscription to drawingService subjectDraw
   * @type {Subscription}
   */
  private subscriptionInit: Subscription;

  /**
   * Stores current selected frame
   * @type {number}
   */
  public currentFrameIndex: number;

  /**
   * Stores instance of canvas which is current loaded frame
   * @type {any}
   */
  public canvas: any;

  private straightLine: any;

  /**
   * Stores drawing mode state
   * @type {boolean}
   */
  private drawingMode: boolean = false;
  private drawlineType: number;

  //private isMultiSelectionActive: boolean = false

  constructor(
    private storageService: StorageService,
    private drawSubjectService: DrawSubjectService,
    private frameSubjectService: FrameSubjectService,
    private drawPlayerActionsService: DrawPlayerActionsService,
    private drawLinesActionsService: DrawLinesActionsService,
    private drawBaseFreeLineActionsService: DrawBaseFreeLineActionsService,
    private drawTacticActionsService: DrawTacticActionsService,
    private platformDetetectorService: PlatformDetectorService,
  ) {
    this.subscribeToLoadFrame();
    this.subscribeToDrawingEvents();
  }

  /**
   * Maps fabricjs events to the appropriate methods/handlers
   * @param {any} canvasWrapper
   */
  public onCanvasEvents(canvasWrapper: any): void {
    this.canvas = canvasWrapper.canvas;

    // varibles using for prevention object to get out of canvas when using scalling
    var pointerX, pointerY;

    canvasWrapper.canvas.on(CanvasEventTypes.MOUSE_UP, (e: any) => {
      this.onMouseUp(canvasWrapper.canvas, e);
      this.onRecalculateBoundingBox(canvasWrapper.canvas, e);
      this.onDrawLineEnd(canvasWrapper.canvas);
    }, false);

    canvasWrapper.canvas.on(CanvasEventTypes.MOUSE_DOWN, (e: any) => {
      this.createObjectSelection(canvasWrapper.canvas, e);
      this.onMouseDown(canvasWrapper.canvas, e);
      this.onDrawLineStart(canvasWrapper.canvas, e);
    }, false);

    // prevent object to get out of canvas when using moving
    this.canvas.on(CanvasEventTypes.MOVING_OBJECT, (e: any) => {

      const objects = this.canvas.getObjects();

      this.canvas.getActiveObjects().forEach((object) => {
        const { type, referenceId } = object;

        if (type === ExtensionTypes.GROUP_PLAYER_EXTENSION || type === ExtensionTypes.GROUP_SHAPE_EXTENSION) {

          const targetObject = objects.find((element: any) => {
            return element.id === referenceId;
          });
          targetObject.path[1][3] = e.target.left + (e.target.width / 2 + object.left);
          targetObject.path[1][4] = e.target.top + (e.target.height / 2 + object.top);
          targetObject.set({ objectCaching: false });
        }
      });
    });

    canvasWrapper.canvas.on(CanvasEventTypes.MOUSE_MOVE, (e: any) => {
      this.onDrawLineMoving(canvasWrapper.canvas, e);
    }, false);

    canvasWrapper.canvas.on(CanvasEventTypes.DROP, this.onDrop.bind(this, canvasWrapper.canvas), false);
    canvasWrapper.canvas.on(CanvasEventTypes.SELECTION_CREATED, this.onSelectionCreated.bind(this, canvasWrapper.canvas), false);
    canvasWrapper.canvas.on(CanvasEventTypes.BEFORE_SELECTION_CLEARED, this.onBeforeSelectionClear.bind(this, canvasWrapper.canvas), false);
    canvasWrapper.canvas.on(CanvasEventTypes.SCALING_OBJECT, this.onObjectScaling.bind(this, canvasWrapper.canvas), false);
    canvasWrapper.canvas.on(CanvasEventTypes.SELECTION_UPDATED, this.onObjectSelected.bind(this, canvasWrapper.canvas), false);
    canvasWrapper.canvas.on(CanvasEventTypes.SELECTION_CREATED, this.onObjectSelected.bind(this, canvasWrapper.canvas), false);
  }

  private onDrawLineEnd(canvas: any): void {
    if (this.drawingMode) {
      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.FREE_LINE_END_DRAWING);
    }
    if (this.straightLine) {
      var dist = Math.sqrt(Math.pow((this.straightLine.x1 - this.straightLine.x2), 2) + Math.pow((this.straightLine.y1 - this.straightLine.y2), 2));
    }

    if (this.straightLine && dist > 5) {
      canvas.remove(this.straightLine);
      const options = { x1: this.straightLine.x1, y1: this.straightLine.y1, x2: this.straightLine.x2, y2: this.straightLine.y2 };

      if (this.drawlineType === 1)
        this.drawLinesActionsService.drawStraightLine(canvas, false, options);

      if (this.drawlineType === 2)
        this.drawLinesActionsService.drawCurvedLine(canvas, options);

      this.straightLine = false;
      this.drawingMode = false;
      canvas.requestRenderAll();
      canvas.selection = true;
      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.STRAIGHT_LINE_END_DRAWING);
      this.drawSubjectService.subjectDraw.next(CanvasEventTypes.CURVE_LINE_END_DRAWING);
    }
  }

  private onDrawLineStart(canvas: any, e: any): void {
    if (this.drawingMode && this.drawlineType !== 3) {
      var pointer = canvas.getPointer(e.e);
      var points = [pointer.x, pointer.y, pointer.x, pointer.y];
      this.straightLine = new fabric.BaseLine(points, {
        strokeWidth: 2,
        stroke: 'black',
        objectCaching: false,
        selectable: false
      })
      canvas.add(this.straightLine);
      canvas.selection = false;
    }
  }

  private onDrawLineMoving(canvas: any, e: any) {
    if (this.straightLine) {
      var pointer = canvas.getPointer(e.e);
      this.straightLine.set({
        x2: pointer.x,
        y2: pointer.y
      });
      canvas.renderAll();
    }
  }

  private subscribeToDrawingEvents(): void {
    if (this.subscriptionInit) {
      this.subscriptionInit.unsubscribe();
    }

    this.subscriptionInit = this.drawSubjectService.subjectDraw.subscribe((event: CanvasEventTypes) => {

      if (event === CanvasEventTypes.STRAIGHT_LINE_START_DRAWING) {
        this.drawingMode = true;
        this.drawlineType = 1;
      }

      if (event === CanvasEventTypes.CURVE_LINE_START_DRAWING) {
        this.drawingMode = true;
        this.drawlineType = 2;
      }

      if (event === CanvasEventTypes.FREE_LINE_START_DRAWING) {
        this.drawingMode = true;
        this.drawlineType = 3;
        this.canvas.set({ isDrawingMode: true, selection: false });
        this.canvas.freeDrawingBrush = new fabric.FreeDrawingDashedLine(this.canvas);
      }

      if (event === CanvasEventTypes.CURVE_LINE_END_DRAWING || event === CanvasEventTypes.STRAIGHT_LINE_END_DRAWING) {
        this.drawingMode = false;
      }

      if (event === CanvasEventTypes.FREE_LINE_END_DRAWING) {
        this.canvas.isDrawingMode = false;
        this.canvas.selection = true;

        if (this.drawingMode && this.drawlineType === 3) {
          this.drawBaseFreeLineActionsService.drawBaseFreeLine(this.canvas);
        }

        this.drawingMode = false;
      }
    });
  }

  /**
   * Force changing of the selection are color
   * @param {any} canvas
   * @param {any} e
   */
  public onObjectSelected(canvas: any, e: any): void {
    this.canvas.getActiveObject().set(
      {
        borderColor: AppConfig.Canvas.borderColor,
        cornerColor: AppConfig.Canvas.cornerColor
      });

    this.canvas.renderAll();
  }

  /**
   * Handles scaling of objects and stores the actual scale into the storage
   * @param {any} canvas
   * @param {any} e
   */
  public onObjectScaling(canvas: any, e: any): void {

    if (e.target) {
      e.target.lockScalingFlip = true;
    }
    if (
      (e.target.subType === ExtensionTypes.TRIANGLE_EXTENSION ||
        e.target.subType === ExtensionTypes.RECT_SHAPE_EXTENSION ||
        e.target.subType === ExtensionTypes.CIRCLE_SHAPE_EXTENSION ||
        !e.target.subType) && (e.target.actionType !== TextTypes.TEXT)
    ) {
      return;
    }

    const scaledObject = e.target;

    const maxScale = AppConfig.Canvas.maxScaleSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;
    const minScale = AppConfig.Canvas.minScaleSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;


    scaledObject.actionSize = Math.round((scaledObject.scaleX - 0.005 - AppConfig.Canvas.scaleObjectSizeStep) / AppConfig.Canvas.iconSizeMultiple);


    if (scaledObject.scaleX >= maxScale) {
      scaledObject.actionSize = AppConfig.Canvas.maxScaleSize;
      scaledObject.set({
        scaleX: maxScale,
        scaleY: maxScale
      });
    }

    if (scaledObject.scaleX < minScale) {
      scaledObject.actionSize = AppConfig.Canvas.minScaleSize;
      scaledObject.set({
        scaleX: minScale,
        scaleY: minScale
      });
    }

    // @todo sets 'object_actionSize' into const key into storage service
    localStorage.setItem('object_actionSize', String(scaledObject.actionSize));
  }

  /**
   * Adds a new object, hides control points of prev. active objects except the new one
   * @param {any} canvas
   * @param {any} e
   */
  public onObjectAdded(canvas: any, e: any): void {
    if (!e.target) {
      return;
    }

    const activeObject = e.target;

    const objects = canvas.getObjects();

    // hides if there is any visible active control points from other objects
    objects.filter(object =>
      object.referenceCircleGroupId && object.referenceGroupId !== activeObject.referenceGroupId
    ).forEach(object => {
      object.set({ visible: false });
    });
  }

  /**
   * Overrides default multiselection functionality of canvas,
   * because there is a problem with selecting of complex objects
   * @param {any} canvas
   * @param {any} e
   */
  public onSelectionCreated(canvas: any, e: any): void {

    // checks is actually used instrument is a multiselector
    if (e && e.selected && e.selected.length > 0 && e.e && (e.e.type === CanvasEventTypes.MOUSE_UP_SELECTION || e.e.isTrusted)) {

      const objects = canvas.getObjects();
      const relatedObjects = {};
      const relatedObjectsArray = [];

      if (e.selected.length === 1 &&
        e.selected[0].type !== ExtensionTypes.WAVE_LINE_EXTENSION &&
        e.selected[0].type !== ExtensionTypes.CURVE_LINE_EXTENSION &&
        e.selected[0].type !== ExtensionTypes.LINE_EXTENSION
      ) return;

      e.selected.forEach(object => {

        // all complex objects (line, double line, curved line)
        if (object.referenceGroupId) {
          objects
            .filter(relatedObject => relatedObject.referenceGroupId === object.referenceGroupId &&
              (
                object.type === ExtensionTypes.LINE_EXTENSION ||
                object.type === ExtensionTypes.CURVE_LINE_EXTENSION ||
                object.type === ExtensionTypes.WAVE_LINE_EXTENSION
              )
            )
            .forEach(filteredObject => {

              if (!relatedObjects[filteredObject.id]) {
                relatedObjects[filteredObject.id] = filteredObject;
              }
            });

          objects
            .filter(relatedObject => relatedObject.referenceGroupId === object.referenceGroupId &&
              (relatedObject.subType === ExtensionTypes.GROUP_PLAYER_EXTENSION ||
                relatedObject.subType === ExtensionTypes.GROUP_SHAPE_EXTENSION
              )
              &&
              (object.subType === ExtensionTypes.GROUP_PLAYER_EXTENSION ||
                object.subType === ExtensionTypes.GROUP_SHAPE_EXTENSION)
            )
            .forEach(filteredObject => {
              if (!relatedObjects[filteredObject.id]) {
                relatedObjects[filteredObject.id] = filteredObject;
              }
            });
        } else {
          // all simple objects
          relatedObjectsArray.push(object);
        }
      });

      // converts associative array to simple array
      for (const key in relatedObjects) {
        if (relatedObjects[key]) {
          relatedObjectsArray.push(relatedObjects[key]);
        }
      }

      canvas.discardActiveObject();

      // creation of custom selection
      const selection = new fabric.ActiveSelection(relatedObjectsArray, {
        canvas,
      });

      canvas.setActiveObject(selection);

      this.canvas.getActiveObject().set({
        borderColor: AppConfig.Canvas.borderColor,
        cornerColor: AppConfig.Canvas.cornerColor,
        hasBorders: false,
        hasControls: false
      });


      if ((e.e && e.e.type === CanvasEventTypes.MOUSE_UP_SELECTION) || (this.platformDetetectorService.isMobileBrowser() || this.platformDetetectorService.isApp())) {

        this.canvas.getActiveObject().set({
          hasBorders: true
        });
      }

      canvas.renderAll();
      //canvas.perPixelTargetFind = false;
    }
  }

  /**
   * Handles showing of control points for the current object
   * @param {any} canvas
   * @param {any} e
   */
  private onMouseDown(canvas: any, e: any): void {

    const objects = canvas.getObjects();

    objects.filter(object =>
      object.referenceCircleGroupId
    ).forEach(object => {
      object.set({ visible: false });
    });

    if (!e.target) {

      return;
    }

    const activeObject = e.target;

    objects.filter(
      object => object.referenceGroupId === activeObject.referenceGroupId && object.referenceCircleGroupId
    ).forEach(object => {
      object.set({ visible: true });
    });
  }

  /**
   * Handles showing of control points for the current object
   * @param {any} canvas
   * @param {any} e
   */
  private onBeforeSelectionClear(canvas: any, e: any): void {
    if (!e.e || !e.target) {
      return;
    }

    canvas.getObjects().filter(
      object => object.referenceCircleGroupId
    ).forEach(object => {
      //object.set({ visible: false });
    });

    canvas.perPixelTargetFind = true;
  }

  /**
   * Handles double click over canvas object, triggers multiselection on related object of the current one
   * @param {any} canvas
   * @param {any} e
   * @returns
   * @memberof EventsHandlerService
   */
  private createObjectSelection(canvas: any, e: any) {

    if (!e.target || (e.target && !e.target.referenceGroupId)) {
      return;
    }
    const activeObject = e.target;
    const objects = canvas.getObjects();
    const relatedObjects = objects.filter(
      object => object.referenceGroupId === activeObject.referenceGroupId //&& object.referenceCircleGroupId
    );

    var exit = false;
    relatedObjects.forEach((object) => {
      if (object.subType === ExtensionTypes.START_CIRCLE_EXTENSION ||
        object.subType === ExtensionTypes.END_CIRCLE_EXTENSION ||
        object.subType === ExtensionTypes.START_PATH_CIRCLE_EXTENSION ||
        object.subType === ExtensionTypes.END_PATH_CIRCLE_EXTENSION ||
        object.subType === ExtensionTypes.MIDDLE_PATH_CIRCLE_EXTENSION ||
        object.subType === ExtensionTypes.CIRCLE_SHAPE_EXTENSION
      ) {
        if (!object.visible) exit = true;
      }
    });

    if (activeObject.subType === ExtensionTypes.RECT_SHAPE_EXTENSION) return;

    if (exit || (
      activeObject.subType !== ExtensionTypes.CURVE_LINE_EXTENSION &&
      activeObject.subType !== ExtensionTypes.LINE_EXTENSION &&
      activeObject.subType !== ExtensionTypes.RECT_SHAPE_EXTENSION &&
      activeObject.subType !== ExtensionTypes.WAVE_LINE_EXTENSION
    )) {
      if (this.canvas.getActiveObject())
        this.canvas.getActiveObject().setControlsVisibility({
          borderColor: AppConfig.Canvas.borderColor,
          cornerColor: AppConfig.Canvas.cornerColor,
          tl: true,
          tr: true,
          br: true,
          bl: true,
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          mtr: true,
          hasBorders: false
        });

      return;
    }


    canvas.discardActiveObject();
    const selection = new fabric.ActiveSelection(relatedObjects, {
      canvas,
    });

    canvas.setActiveObject(selection);

    // if this is a complex object,  by default we hides all control bounding box points
    if (activeObject.referenceGroupId) {
      this.canvas.getActiveObject().setControlsVisibility({
        borderColor: AppConfig.Canvas.borderColor,
        cornerColor: AppConfig.Canvas.cornerColor,
        tl: false,
        tr: false,
        br: false,
        bl: false,
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        mtr: false
      });

      this.canvas.getActiveObject().set({ hasBorders: true });
    }
    canvas.renderAll();
    //canvas.perPixelTargetFind = false;
    //this.isMultiSelectionActive = true;
  }

  /**
   * Recalculates bounding box for all objects on canvas
   * @param {any} canvas
   * @param {any} e
   */
  private onRecalculateBoundingBox(canvas: any, e: any): void {
    // prevents if events is triggreede by multiselection,
    // because breaks _setPositionDimensions of the path
    if (e.target) {
      canvas.getObjects()
        .forEach((object) => {


          if (object.subType === ExtensionTypes.PATH_EXTENSION) {
            // @todo this cause strange behaviour when select rectangle with curved line
            fabric.Polyline.prototype._setPositionDimensions.call(object, {});

          } else {
            //if (object.subType) object.pathOffset = null;
          }

          if (object.subType) object.setCoords();
        });

      canvas.renderAll();
    }
  }

  /**
   * Handles mouse up trigger reinitialization of events and object's positions
   * reposition is used only after selection mode (double click)
   * @param {any} canvas
   * @param {any} e
   * @memberof EventsHandlerService
   */
  private onMouseUp(canvas: any, e: any) {
    if (e.target && e.target._objects) {
      const objects = e.target._objects;

      // @todo add main (group id) additional property to the these points, to make more easily filter desired objects
      const targetActiveObject = objects.find(object =>
        object.subType === ExtensionTypes.END_CIRCLE_EXTENSION
        || object.subType === ExtensionTypes.END_PATH_CIRCLE_EXTENSION
        || object.subType === ExtensionTypes.END_LINE_DOUBLE_CIRCLE_EXTENSION
        || object.subType === ExtensionTypes.CIRCLE_EXTENSION
      );

      if (targetActiveObject) {
        canvas.discardActiveObject();
        canvas.setActiveObject(targetActiveObject);
        canvas.perPixelTargetFind = true;

        this.initObjectsEvents();
      }
    }
  }

  /**
   * Handles dropping of objects into the canvas
   * @param {any} canvas
   * @param {any} e
   * @returns {void}
   */
  private async onDrop(canvas: any, e: any) {
    const draggedElement = document.getElementsByClassName(AppConfig.System.cssClasses.canvasItemDragClassName)[0];

    if (!draggedElement) {
      return false;
    }

    const object = JSON.parse(draggedElement.getAttribute('data')) as Figure;

    const pointer = canvas.getPointer(e.e);

    if (!localStorage.getItem('object_actionSize')) {
      localStorage.setItem('object_actionSize', String(AppConfig.Canvas.iconDefaultSize));
    }

    var offsetx = 0, offsety = 0;
    if (document.body.clientWidth > AppConfig.Canvas.maxWidthScreenGestureEvent && object.offsetCoordinates.offsetX) {
      offsetx = (38 - object.offsetCoordinates.offsetX);
      offsety = (38 - object.offsetCoordinates.offsetY) - 16;
    }

    const extendFigure = new ExtendFigure();
    extendFigure.imageURL = object.url;
    extendFigure.actionType = object.type;
    extendFigure.left = pointer.x + offsetx;
    extendFigure.top = pointer.y + offsety;
    extendFigure.angle = 0;
    extendFigure.color1 = '';
    extendFigure.text = '';
    extendFigure.actionSize = parseInt(localStorage.getItem('object_actionSize'));
    extendFigure.selectAfterCreate = false;

    if (object.type in PlayerTypes || object.type in MiscTypes) {

      const userStorageData = await this.storageService.getUserDataFromStorage();

      let skip = false;

      if (object.type in PlayerTypes && userStorageData.featuredPlayers && userStorageData.featuredPlayers.length > 0) {
        userStorageData.featuredPlayers.forEach((player) => {
          if (player.url === extendFigure.imageURL) {
            skip = true;
          }
        });
      }

      if (!skip && object.type in PlayerTypes && !(object.type === PlayerTypes.EQUIPMENT_COLORS)) {
        const obj: any = {};
        obj.url = extendFigure.imageURL;
        userStorageData.featuredPlayers = [obj].concat(userStorageData.featuredPlayers);
        await this.storageService.setUserDataToStorage(userStorageData);
      }

      this.drawPlayerActionsService.drawPlayer(canvas, extendFigure);
    }

    canvas.discardActiveObject();
    canvas.renderAll();

    return true;
  }


  /**
   * Handles canvas events, updates current frame in storage
   * @param {number} frameIndex
   */
  private subscribeToLoadFrame(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.frameSubjectService.frameSubject.subscribe(async (object: any) => {
      if (!object.canvas) {
        return;
      }

      this.currentFrameIndex = object.frameIndex || 0;
      this.canvas = object.canvas;

      // reinit events after loading frame from json data
      this.initObjectsEvents();

      this.updateObjectsLayerOrderByFrame();

      // updating of paths and visibility is apply only for frame index
      // more than 0 and loading type to be different from play,
      // because when play animation is in progress no one of paths must be shown

      if (this.currentFrameIndex && object.loadType) {
        await this.updateObjectsByFrame(this.currentFrameIndex, object);
      }
    });
  }
  /**
   * Executes after we loading a frame,
   * re-hooks all events as using id between the related objects
   * @returns {<void>}
   * @memberof EventsHandlerService
   */
  public initObjectsEvents(): void {
    const objects = this.canvas.getObjects();

    for (const currentObject of objects) {
      const { subType, referenceId } = currentObject;

      switch (subType) {
        case ExtensionTypes.START_CIRCLE_EXTENSION: {
          if (referenceId) {
            const targetObject = objects.find((element: any) => {
              return element.id === referenceId;
            });

            // additional object (triangle, position angle depend of line and control poinst)
            const extraTargetObject = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.TRIANGLE_EXTENSION;
            });

            // initialize position of point object
            targetObject.set({
              x1: currentObject.left, y1: currentObject.top,
            });

            currentObject.on(CanvasEventTypes.MOVING, (e: any) => {
              const { left, top, } = e.target;
              targetObject.set({
                x1: left, y1: top,
              });

              if (extraTargetObject) {
                const angle = calcAngle(targetObject.x1, targetObject.y1, targetObject.x2, targetObject.y2);
                extraTargetObject.set({ angle });
              }
            });
          }

          break;
        }

        case ExtensionTypes.END_CIRCLE_EXTENSION: {
          if (referenceId) {
            const targetObject = objects.find((element: any) => {
              return element.id === referenceId;
            });

            // additional object (triangle, position angle depend of line and control poinst)
            const targetTriangleObject = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.TRIANGLE_EXTENSION;
            });

            // end point of the path which is circle object (it is not selectable)
            const targetExtraPointObject = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.EXTRA_END_POINT_CIRCLE_EXTENSION;
            });

            // @todo duplicate of code
            targetObject.set({
              x2: currentObject.left, y2: currentObject.top,
            });

            currentObject.on(CanvasEventTypes.MOVING, (e: any) => {
              const { left, top } = e.target;
              targetObject.set({
                x2: left, y2: top,
              });

              if (targetTriangleObject) {
                targetTriangleObject.set({
                  left, top
                });

                const angle = calcAngle(targetObject.x1, targetObject.y1, targetObject.x2, targetObject.y2);
                targetTriangleObject.set({ angle });
              }

              if (targetExtraPointObject) {
                updateExtraPointCircle(targetExtraPointObject, { left, top });
              }
            });
          }

          break;
        }

        // control point of curve to path
        case ExtensionTypes.MIDDLE_PATH_CIRCLE_EXTENSION: {
          if (referenceId) {
            const targetObject = objects.find((element: any) => {
              return element.id === referenceId;
            });

            const targetExtraObject = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.TRIANGLE_EXTENSION;
            });

            const targetEndPointObject = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.END_PATH_CIRCLE_EXTENSION;
            });

            const targetMidPointObject = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.MIDDLE_PATH_CIRCLE_EXTENSION;
            });

            // @todo duplicate of code
            if (targetObject.path) {
              targetObject.path[1][1] = currentObject.left;
              targetObject.path[1][2] = currentObject.top;
            }

            targetObject.set({
              cpx: targetMidPointObject.left,
              cpy: targetMidPointObject.top,
            });

            targetObject.set({ objectCaching: false, dirty: true });

            currentObject.on(CanvasEventTypes.MOVING, (e) => {
              const { left, top } = e.target;
              e.target.set({ moved: true });

              if (targetObject.path) {
                targetObject.path[1][1] = left;
                targetObject.path[1][2] = top;
              }
              targetObject.set({
                cpx: left,
                cpy: top,
              });

              if (targetExtraObject) {
                const angle =
                  calcAngle(
                    currentObject.left,
                    currentObject.top,
                    targetEndPointObject.left,
                    targetEndPointObject.top);
                targetExtraObject.set({ angle });
              }
            });
          }

          break;
        }

        // endpoint of path can be player ot standart circle object
        case ExtensionTypes.GROUP_PLAYER_EXTENSION: case ExtensionTypes.GROUP_SHAPE_EXTENSION: {
          if (referenceId) {
            const targetObject = objects.find((element: any) => {
              return element.id === referenceId;
            });

            currentObject.on(CanvasEventTypes.MOVING, (e) => {
              const { left, top } = e.target;

              targetObject.path[1][3] = left;
              targetObject.path[1][4] = top;

              targetObject.set({ objectCaching: false });
            });
          }

          break;
        }

        // @todo dublication of code
        case ExtensionTypes.END_PATH_CIRCLE_EXTENSION: {
          if (referenceId) {
            const targetObject = objects.find((element: any) => {
              return element.id === referenceId;
            });

            // additional object (triangle, position angle depend of line and control poinst)
            const targetTriangleObject = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.TRIANGLE_EXTENSION;
            });

            // start point of the path which is circle object
            const targetMiddlePointObject = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.MIDDLE_PATH_CIRCLE_EXTENSION;
            });

            // end point of the path which is circle object (it is not selectable)
            const targetExtraPointObject = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.EXTRA_END_POINT_CIRCLE_EXTENSION;
            });

            // @todo duplicate of code
            if (targetObject.path) {
              targetObject.path[1][3] = currentObject.left;
              targetObject.path[1][4] = currentObject.top;
            }
            targetObject.set({
              x2: currentObject.left,
              y2: currentObject.top,
            });
            targetObject.setCoords();
            targetObject.set({ objectCaching: false });

            currentObject.on(CanvasEventTypes.MOVING, (e) => {
              const { left, top } = e.target;

              if (targetObject.path) {
                targetObject.path[1][3] = left;
                targetObject.path[1][4] = top;
              }
              targetObject.set({
                x2: left,
                y2: top,
              });
              targetObject.set({ objectCaching: false });

              if (targetTriangleObject) {
                targetTriangleObject.set({
                  left, top
                });

                const angle =
                  calcAngle(
                    currentObject.left,
                    currentObject.top,
                    targetMiddlePointObject.left,
                    targetMiddlePointObject.top) + 180;
                targetTriangleObject.set({ angle });
              }

              if (targetExtraPointObject) {
                updateExtraPointCircle(targetExtraPointObject, { left, top });
              }
            });
          }

          break;
        }

        // start point of path
        case ExtensionTypes.START_PATH_CIRCLE_EXTENSION: {
          if (referenceId) {
            const targetObject = objects.find((element: any) => {
              return element.id === referenceId;
            });

            if (targetObject.path) {
              targetObject.path[0][1] = currentObject.left;
              targetObject.path[0][2] = currentObject.top;
            }
            targetObject.set({
              x1: currentObject.left,
              y1: currentObject.top,
            });

            targetObject.setCoords();

            currentObject.on(CanvasEventTypes.MOVING, (e) => {
              const { left, top } = e.target;

              if (targetObject.path) {
                targetObject.path[0][1] = left;
                targetObject.path[0][2] = top;
              }
              targetObject.set({
                x1: left,
                y1: top,
              });
              targetObject.set({ objectCaching: false });
            });
          }

          break;
        }

        // start point of double line
        case ExtensionTypes.START_LINE_DOUBLE_CIRCLE_EXTENSION: {
          if (referenceId) {

            const circleTwo = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.END_LINE_DOUBLE_CIRCLE_EXTENSION;
            });

            const { rect, triangle, line1, line2 } = getRelatedDoubleLineObjects(currentObject, referenceId, objects);

            currentObject.on(CanvasEventTypes.MOVING, () => {
              moveLine(currentObject, circleTwo, rect, triangle, line1, line2, this.canvas);
            });

          }
          break;
        }

        // end point of double line
        case ExtensionTypes.END_LINE_DOUBLE_CIRCLE_EXTENSION: {
          if (referenceId) {

            const circleOne = objects.find((element: any) => {
              return element.referenceId === referenceId && element.subType === ExtensionTypes.START_LINE_DOUBLE_CIRCLE_EXTENSION;
            });

            const { rect, triangle, line1, line2 } = getRelatedDoubleLineObjects(currentObject, referenceId, objects);

            currentObject.on(CanvasEventTypes.MOVING, () => {
              moveLine(circleOne, currentObject, rect, triangle, line1, line2, this.canvas);
            });

          }
          break;
        }

        default:
          break;
      }
    }
  }

  /**
   * Sets position start position to current object as getting data from the previous frame (from storage)
   * The idea is to have new position from the previous frame (start position)
   * Executes every time when switch between frames
   * @param {number} frameIndex
   * @param {boolean} visibility
   * @returns {Promise<void>}
   */
  private async updateObjectsByFrame(
    frameIndex: number, options: any): Promise<void> {

    const objects = this.canvas.getObjects();
    const userStorageData = await this.storageService.getUserDataFromStorage();
    const frames = userStorageData.tempTacticAnimation.data;
    const prevFrameObjects = JSON.parse(frames[frameIndex > 1 ? frameIndex - 1 : 0])[0].objects;

    let visibility =
      (options.loadType === LoadTypes.PLAY && frames.length - 1 === frameIndex) ||
      (options.loadType === LoadTypes.LOAD && frameIndex > 0);

    if (options.shouldVisible !== undefined) {
      visibility = options.shouldVisible;
    }

    for (const currentObject of objects) {

      const { subType, referenceId } = currentObject;
      switch (subType) {

        case ExtensionTypes.GROUP_PLAYER_EXTENSION: case ExtensionTypes.GROUP_SHAPE_EXTENSION: {
          if (referenceId) {
            const targetPathObject = objects.find((element: any) => {
              return element.id === referenceId;
            });

            if (!targetPathObject) {
              break;
            }

            // sets as start point of path prev player object
            const targetObject = prevFrameObjects.find((object: any) => object.id === currentObject.id);

            if (targetObject) {
              visibility = targetObject.visible;
            }

            if (targetObject) {
              targetPathObject.path[0][1] = targetObject.left;
              targetPathObject.path[0][2] = targetObject.top;

              setPathDimension(targetPathObject, visibility);
              this.canvas.renderAll();
            }
          }

          break;
        }

        case ExtensionTypes.MIDDLE_PATH_CIRCLE_EXTENSION: {
          if (referenceId) {
            const targetPathObject = objects.find((element: any) => {
              return element.id === referenceId;
            });

            const currentObjectPrevFrame = prevFrameObjects.find((object: any) => object.id === currentObject.id);

            const targetObject = prevFrameObjects
              .find((object: any) => object.referenceId === referenceId &&
                (object.subType === ExtensionTypes.GROUP_PLAYER_EXTENSION ||
                  object.subType === ExtensionTypes.GROUP_SHAPE_EXTENSION));

            if (!currentObjectPrevFrame || !targetObject) {
              break;
            }

            // if current control point is not moved we set point's position to the player of the prev. frame
            // to achieve straight line
            if (!currentObject.moved || options.frameEventType === FrameEventTypes.ADD_FRAME) {
              // sets middle control point to the player position
              currentObject.set(
                { top: targetObject.top, left: targetObject.left, dirty: true }
              );

              // @todo separate this code from here into the new case of switch statement
              targetPathObject.path[1][1] = targetObject.left;
              targetPathObject.path[1][2] = targetObject.top;

              targetPathObject.set({ x1: targetObject.left, y1: targetObject.top, objectCaching: false });
            }

            visibility = targetObject.visible;

            currentObject.set(
              // hide path circle until last frame is shown or explicitly is set to be hidden
              { visible: visibility }
            );
            currentObject.hasControls = false;

            // use to have working events on control point
            currentObject.setCoords();
          }

          break;
        }

        default:
          break;
      }
    }
  }

  updateObjectsLayerOrderByFrame() {
    const objects = this.canvas.getObjects();

    this.canvas.getObjects().forEach((object: any) => {

      if (object.shouldBringToFront === true) {
        if (object.referenceGroupId) {
          this.setReferencedObjectsToSelection(object);
          this.canvas.getActiveObject().bringToFront();

        } else {
          object.bringToFront();
        }

      } else if (object.shouldBringToFront === false) {
        if (object.referenceGroupId) {
          this.setReferencedObjectsToSelection(object);
          this.canvas.getActiveObject().sendToBack();
        } else {
          object.sendToBack();
        }
      }
    });

    this.canvas.discardActiveObject();
    this.canvas.renderAll();
  }

  private setReferencedObjectsToSelection(object) {
    const objects = this.canvas.getObjects();

    const targetObjects = objects
      .filter((targetObject: any) => targetObject.referenceGroupId === object.referenceGroupId);


    this.canvas.discardActiveObject();
    const selection = new fabric.ActiveSelection(targetObjects, {
      canvas: this.canvas
    });

    this.canvas.setActiveObject(selection);
  }
}
