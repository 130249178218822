import { Observable } from 'rxjs';

/**
 * Provides general interface of using different storage providers(localStorage, Ionic native storage).
 * @class Auth
 */
export abstract class Auth {
  static readonly TOKEN_NAME = 'token';
  abstract setToken(tokenValue: string): Promise<any>;
  abstract getToken(): Promise<any>;
  abstract isTokenExpired(): Promise<any>;
}
