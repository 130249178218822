import { calcAngle, getReferenceCircleGroupId, getReferenceGroupId } from 'app/core/services/system/utilities/help.methods';

import { AppConfig } from 'app/config/app.config';
import { CanvasEventTypes } from '../../events/model/canvas/canvas.event.types';
import { ExtensionTypes } from '../../fabric/extensions/models/extension-types/extension.types';
import { Injectable } from '@angular/core';
import { fabric } from 'fabric-with-gestures';

@Injectable({
  providedIn: 'root'
})
export class DrawBaseFreeLineActionsService {

  public drawBaseFreeLine(
    canvas: any,
    options?: any): any {

    var points = [];
    for (var i = 0; i < canvas.getObjects().length; i++) {
      if(canvas.getObjects()[i].path && !canvas.getObjects()[i].subType) {
          for (var j = 0; j < canvas.getObjects()[i].path.length; j++) {

              points.push(new fabric.Point(canvas.getObjects()[i].path[j][1], canvas.getObjects()[i].path[j][2]));

          }
          canvas.remove(canvas.getObjects()[i]);
      }
    }

    const polygon = new fabric.BaseFreeLine(points, options);
    canvas.add(polygon);
  }

}
