import { ExtensionTypes } from '../models/extension-types/extension.types';
import { fabric } from 'fabric-with-gestures';

export function initBaseGroupExtension(): any {
  if (fabric.BaseGroup === undefined) {
    fabric.BaseGroup = fabric.util.createClass(fabric.Group, {

      type: ExtensionTypes.BASE_GROUP_EXTENSION,
      initialize(objects, options) {
        this.callSuper('initialize', objects, options);

        this.set({ selectable: false });
      }
    });

    fabric.BaseGroup.fromObject = (object, callback) => {
      let _enlivenedObjects;
      // Creates corresponding fabric instances from their object representations
      fabric.util.enlivenObjects(object.objects, (enlivenedObjects) => {
        delete object.objects;
        _enlivenedObjects = enlivenedObjects;
        callback(new fabric.BaseGroup(_enlivenedObjects, object));
      });
    };
  }
}