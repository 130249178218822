import { AppConfig } from 'app/config/app.config';
import { ExtensionTypes } from 'app/core/drawing/services/fabric/fabric/extensions/models/extension-types/extension.types';
import { fabric } from 'fabric-with-gestures';
import { getRandomId } from 'app/core/services/system/utilities/help.methods';

export function initCircleExtension(): any {
  if (fabric.BaseCircle === undefined) {
    fabric.BaseCircle = fabric.util.createClass(fabric.Circle, {
      type: ExtensionTypes.CIRCLE_EXTENSION,

      initialize(options: any = {}, noVisible?: boolean) {
        this.callSuper('initialize', options);

        this.set({
          hasControls: false,
          hasBorders: false,
          selectable: true,
          radius: AppConfig.Canvas.radius,
          strokeWidth: options.strokeWidth || AppConfig.Canvas.strokeRadius,
          fill: options.fill || '#fff',
          stroke: options.stroke || '#666',
          originY: 'center',
          originX: 'center',
          opacity: 0.5
        });

        // increase size of the cotrol points, if device is mobile to be more easily controlling of end points
        if (document.body.clientWidth <= AppConfig.Canvas.maxWidthRadiusMobile) {
          this.set({
            radius: AppConfig.Canvas.radiusMobile
          });
        }

        if (!this.id) {
          this.set('id', ExtensionTypes.CIRCLE_EXTENSION + '_' + getRandomId());
        }

        if (noVisible !== undefined) {
          this.noVisible = true;
          this.visible = false;
        }
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          noVisible: this.noVisible,
          id: this.id,
          referenceGroupId: this.referenceGroupId,
          referenceCircleGroupId: this.referenceCircleGroupId,
          referenceId: this.referenceId,
          referenceIdTwo: this.referenceIdTwo,
          referenceIdThree: this.referenceIdThree,
          subType: this.subType || ExtensionTypes.CIRCLE_EXTENSION,
          visibleControlPointsIndex: this.visibleControlPointsIndex || 0,
          name: this.name,
          moved: this.moved,
          loaded: true,
          deleted: this.deleted,
          updated: this.updated
        });
      },
    });

    fabric.BaseCircle.fromObject = (object, callback) => {
      callback(new fabric.BaseCircle(object, object.noVisible));
    };
  }
}