import { Injectable } from '@angular/core';
import { fabric } from 'fabric-with-gestures';
import {AppConfig} from "../../../../../../config/app.config";
import { DrawRectActionsService } from '../line-actions/draw-rect-actions.service';

@Injectable({
  providedIn: 'root'
})
export class DrawEllipseActionsService {

  constructor(private drawRectActionsService: DrawRectActionsService) {
  }

  public drawEllipse(canvas: any): void {

      const center = { x: AppConfig.Canvas.canvasWidth/2, y: 380 };

      const ellipse = new fabric.CircleShape({
          left: center.x,
          top: center.y,
          rx: 100,
          ry: 50,
          angle: 0,
          fill: '',
          stroke: 'black',
          cornerSize: 8,
          hasBorders: false,
          padding: 0,
          originX: 'center',
          originY: 'center',
          cornerColor: AppConfig.Canvas.cornerColor
      });

      canvas.add(ellipse);
      canvas.renderAll();
  }
}
