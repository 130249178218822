import { AdapterInterface } from '../../system/http-base/adapter.interface';
import { Injectable } from '@angular/core';
import { Tactic } from '../model/tactic.model';
import { TacticItem } from '../../build/model/tactic.item';
import { UploadService } from '../../general/upload/upload.service';

/**
 * Creates TacticToTacticItemAdapter service for decoupling REST API objects with the internal entities
 * @class TacticAdapter
 */
@Injectable({
  providedIn: 'root',
})
export class TacticToTacticItemAdapter implements AdapterInterface<Promise<TacticItem>> {
  constructor(private uploadService: UploadService) { }
  /**
   * Creates a new TacticItem object with data comes from the REST API model
   * @param {Tactic} tactic
   * @returns {TacticItem}
   */
  async adapt(tactic: Tactic): Promise<TacticItem> {
    if (!tactic) {
      return null;
    }

    const tacticItem = new TacticItem();
    tacticItem.name = tactic.name;
    tacticItem.uid = tactic.uid;
    tacticItem.folderUid = tactic.tacticsFolderUid;
    tacticItem.type = tactic.type;
    tacticItem.created = new Date(tactic.createdUtc * 1000);
    tacticItem.modified = tactic.modifiedUtc;
    tacticItem.linkThumb = tactic.linkThumb;
    tacticItem.link = tactic.link;
    tacticItem.linkMp4 = tactic.linkMp4;
    // @todo this will be used only for mobile
    // tacticItem.linkData = await this.uploadService.getFileToBase64(tactic.link);
    // tacticItem.thumbData = await this.uploadService.getFileToBase64(tactic.linkThumb);

    return tacticItem;
  }
}
