<ion-header mode="md" class="ion-no-border">
	<ion-toolbar>
		<ion-buttons *ngIf="!isModal" slot="start">
			<ion-back-button (click)="onCancel()" defaultHref="/{{appRoutes.MY_FOLDERS}}" text=""
				icon="arrow-back-outline"></ion-back-button>
		</ion-buttons>
		<ion-title>{{ 'TERMS_TITLE' | translate }}</ion-title>
		<ion-buttons slot="end">
			<ion-button *ngIf="isModal" (click)="dismissModal()">
				<ion-icon name="close-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-grid class="ion-padding">
		<ion-row>
			<ion-col>
				<p>
					{{ 'TERMS_1_TEXT' | translate }}
				</p>
				<p>
					{{ 'TERMS_2_TEXT' | translate }}
				</p>
				<p>
					{{ 'TERMS_3_TEXT' | translate }}
				</p>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>