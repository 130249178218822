import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appDoubleTapPrevent]",
})
export class DoubleTapPreventDirective {
  private lastTouchEnd = 0;

  @HostListener("touchend", ["$event"])
  clickEvent(event) {
    let now = new Date().getTime();

    if (now - this.lastTouchEnd <= 300) {
      event.preventDefault();
    }

    this.lastTouchEnd = now;
  }
}
