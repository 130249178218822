import { Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { UserRegisterAdapter } from './user.register.adapter';
import { AdapterInterface } from '../../system/http-base/adapter.interface';

/**
 * Creates Adapter service for decoupling REST API objects with the internal entities
 * @class UserAdapter
 */
@Injectable({
  providedIn: 'root',
})
export class UserAdapter implements AdapterInterface<User> {
  constructor(private userRegisterAdapter: UserRegisterAdapter) {}

  /**
   * Creates a new User object with data comes from the REST API
   * @param {any} item
   * @returns {User}
   */
  adapt(item: any): User {
    const user: User = this.userRegisterAdapter.adapt(item);
    let userDetails = new User();
    userDetails.userUid = item.user.user_uid;
    userDetails = Object.assign(user, userDetails);

    return userDetails;
  }
}
