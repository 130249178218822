import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpBaseService } from '../../system/http-base/http-base.service';
import { LanguageAdapter } from './adapters/language.adapter';
import { Languages } from './model/lang.models';

/**
 * Provides obtaining all languages templates
 * @class LanguageService
 * @extends {HttpBaseService}
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService extends HttpBaseService {
  /**
   * Creates an instance of LanguageService.
   * Extends HttpBaseService which is responsible for communication with the REST API
   * @param {HttpClient} http
   * @param {LanguageAdapter} languageAdapter
   */
  constructor(
    private http: HttpClient,
    private languageAdapter: LanguageAdapter
  ) {
    super();
  }

  /**
   * Gets all defined languages from the Rest api
   * @returns {Observable<Languages[]>}
   */
  getLanguages(): Observable<Languages[]> {
    return this.http
      .get(`${this.baseUrl}/misc/languages`)
      .pipe(
        map((data: any[]) =>
          data.map((item) => this.languageAdapter.adapt(item))
        )
      );
  }

  /**
   * Returns target language by code
   * @param {string} code
   * @returns {Promise<Languages>}
   */
  public async getLanguageByCode(code: string): Promise<Languages> {
    const languages: Languages[] = await this.getLanguages().toPromise();
    const targetLanguage: Languages = languages.filter(language => language.code === code)[0];

    return targetLanguage;
  }

  /**
   * Returns target language by uid
   * @param {number} uid
   * @returns {Promise<Languages>}
   */
  public async getLanguageByUid(uid: number): Promise<Languages> {
    const languages: Languages[] = await this.getLanguages().toPromise();
    const targetLanguage: Languages = languages.filter(language => language.uid === uid)[0];

    return targetLanguage;
  }
}
