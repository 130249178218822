import { WaveLineInterface } from '../wave.line.interface';
import { WaveLineSmoothingTypes } from 'app/core/drawing/models/wave-line-smoothing-types/wave.line.smoothing.types';
import { WaveLineTypes } from 'app/core/drawing/models/wave-line-types/wave.line.types';
import { WaveTypeLineInterface } from '../wave.line.type.interface';

export class WaveLineLeve1 implements WaveLineInterface {
    frequency;
    flattening = 8;
    actionLineType;
    smoothingType = WaveLineSmoothingTypes.SMOOTHNESS_LEVEL1;
}