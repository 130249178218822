/**
 * Represents screen representation model for folder
 * @class FolderItem
 */
export class FolderItem {
  public name: string;
  public imagesCount: number;
  public animationCount: number;
  public folderCount: number;
  public folderUids: number[];
  public imagesUids: number[];
  public uid: number;
  public modified: number;
  public parentUid: number;
  public thumbs: string[] = [];
  public syncId: number = 0;
  public parentSyncId: number = 0;
  public deleted: number = 0;
  public created: number;
}