import { AdapterInterface } from '../../system/http-base/adapter.interface';
import { Folder } from '../model/folder.model';
import { FolderItem } from '../../build/model/folder.item';
import { Injectable } from '@angular/core';

/**
 * Creates Adapter service for decoupling REST API objects with the internal entities
 * @class FolderToFolderItemAdapter
 */
@Injectable({
  providedIn: 'root',
})
export class FolderToFolderItemAdapter implements AdapterInterface<FolderItem> {
  /**
   * Creates a new FolderItem object with data comes from the REST API
   * @param {Folder} folder
   * @returns {FolderItem}
   */
  adapt(folder: Folder): FolderItem {
    const folderItem = new FolderItem();
    folderItem.name = folder.name;
    folderItem.uid = folder.uid;
    folderItem.parentUid = folder.parentUid;
    folderItem.created = folder.created;
    folderItem.deleted = folder.deleted;
    folderItem.modified = folder.modifiedUtc;

    return folderItem;
  }
}
