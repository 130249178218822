import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SyncSubjectService {
    /**
     * Uses for emitting when sync is finished
     * @type {Subject<any>}
     */
    public subjectSynchronization: Subject<any>;

    /**
     * Uses to force sync process externally
     * @type {Subject<any>}
     */
    public forceSubjectSynchronization: Subject<any>;

    constructor(
    ) {
        this.subjectSynchronization = new Subject();
        this.forceSubjectSynchronization = new Subject();
    }
}
