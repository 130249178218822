import { FolderItem } from 'app/core/services/build/model/folder.item';
import { TacticItem } from 'app/core/services/build/model/tactic.item';
import { TacticTypes } from 'app/core/services/tactic/model/tactic.types';

/**
 * Users's structure stored inside ionic storage
 * @class UserStorageData
 */
export class UserStorageData {

  public premiumUser: boolean;
  public languageCode: string;
  public cachedInterceptorResponses: any = [];
  public featuredPlayers: any = [];
  public tacticsData: TacticItem[] = [];
  public foldersData: FolderItem[] = [];
  public tacticsDataTemplate: TacticItem[];
  public foldersDataTemplate: FolderItem[];
  public tempTactic: TacticItem = new TacticItem();

  public tempTacticAnimation: TacticItem = new TacticItem(TacticTypes.ANIMATION);

  public tempTacticImage: TacticItem = new TacticItem(TacticTypes.IMAGE);

  private avatarImageMember: string;

  public get avatarImage(): string {
    return this.avatarImageMember;
  }

  public set avatarImage(hash: string) {
    // if we reading default avatar stored in assets, hash becomes with prefix text/html
    // but this prefix is not correct for showing the image
    this.avatarImageMember = hash.replace('text/html', 'image/png');
  }

  public syncId: number = 0;
}
