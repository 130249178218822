import { AppConfig } from 'app/config/app.config';
import { ExtendFigure } from './model/figure-model/figure.model';
import { Injectable } from '@angular/core';
import { fabric } from 'fabric-with-gestures';
import { makeCurvedLineWithPoints } from '../../fabric/extensions/curved-line-extension/curved.line.extension';

@Injectable({
  providedIn: 'root'
})
export class DrawPlayerActionsService {
  public drawPlayer(canvas: any, extendFigure: ExtendFigure, forceColor?: boolean, callback?: any): any {

    if (localStorage.getItem(extendFigure.actionType) && !forceColor) {
      const colors = JSON.parse(localStorage.getItem(extendFigure.actionType));
      extendFigure.color1 = colors.color1;
      extendFigure.color2 = colors.color2;
    }

    fabric.loadSVGFromURL(extendFigure.imageURL, (objects: any, options: any) => {

      objects.forEach((object) => {

        if ((typeof object.fill === 'string' || object.fill instanceof String) && (object.fill.toLowerCase() === '#ff862a'
          || object.fill.toLowerCase() === '#f9ff00'
          || object.fill.toLowerCase() === '#c40000'
          || object.fill.toLowerCase() === '#004200')
          && extendFigure.color1.length > 2) {
          object.fill = extendFigure.color1;
        }
        if ((typeof object.fill === 'string' || object.fill instanceof String) && (object.fill.toLowerCase() === '#3cb9ce'
          || object.fill.toLowerCase() === '#ffffa1'
          || object.fill.toLowerCase() === '#a9a9aa'
          || object.fill.toLowerCase() === '#f4f4f4'
          || object.fill.toLowerCase() === '#eac55c'
          || object.fill.toLowerCase() === '#000001')
          && extendFigure.color2.length > 2) {
          object.fill = extendFigure.color2;
        }
      });


      const svg = fabric.util.groupSVGElements(objects, options);
      svg.left = extendFigure.left;
      svg.top = extendFigure.top;
      svg.originX = 'center';
      svg.originY = 'center';
      svg.scale(10);

      svg.cloneAsImage(img => {
        const player = new fabric.Player(img._element);
        player.set({
          left: extendFigure.left,
          top: extendFigure.top,
          originX: 'center',
          originY: 'center',
          color1: extendFigure.color1,
          color2: extendFigure.color2,
          objectCaching: false
        });

        const text = new fabric.BaseText(extendFigure.text,
          {
            fontSize: AppConfig.Canvas.textFontSize,
            fontFamily: AppConfig.Canvas.fontFamily,
            originX: 'center',
            originY: 'bottom',
            top: extendFigure.top - player.getScaledHeight() / 1.4,
            left: extendFigure.left
          });

        if (extendFigure.textColor) {
          text.fill = extendFigure.textColor;
        }

        const group = new fabric.CustomGroup([player, text, svg],
          {
            left: extendFigure.left,
            top: extendFigure.top,
            originX: 'center',
            originY: 'center',
            lockUniScaling: true,
            borderColor: AppConfig.Canvas.borderColor,
            cornerColor: AppConfig.Canvas.cornerColor,
          });

        if (extendFigure.id) {
          group.id = extendFigure.id;
        }

        if (extendFigure.referenceId) {
          group.referenceId = extendFigure.referenceId;
        }

        if (extendFigure.referenceGroupId) {
          group.referenceGroupId = extendFigure.referenceGroupId;
        }

        group.actionType = extendFigure.actionType;
        group.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
        });

        group.actionSize = extendFigure.actionSize;
        group.imageURL = extendFigure.imageURL;
        group.angle = extendFigure.angle;
        group.actionName = extendFigure.text;
        group.isLocked = extendFigure.isLocked;
        group.color1 = extendFigure.color1;
        group.color2 = extendFigure.color2;

        const scale = extendFigure.actionSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;

        group.set({
          scaleX: scale,
          scaleY: scale
        });

        makeCurvedLineWithPoints(canvas, {}, group, false);
        group.flipX = extendFigure.flipX;
        group.flipY = extendFigure.flipY;
        if (extendFigure.flipX) {
          group.flipX = true;
        }

        if (callback) {
          callback(group);
        }

        if (extendFigure.selectAfterCreate) {
          canvas.setActiveObject(group);
        }
      });
    });
  }

  /**
   * @todo separate logic of drawPlayer, updatePlayer into a new one helper method
   * @param {any} canvas
   * @param {ExtendFigure} extendFigure
   * @param {any} [callback]
   */
  public updatePlayer(canvas: any, extendFigure: ExtendFigure, callback?: any): void {
    if (localStorage.getItem(extendFigure.actionType)) {
      const colors = JSON.parse(localStorage.getItem(extendFigure.actionType));
      extendFigure.color1 = colors.color1;
      extendFigure.color2 = colors.color2;
    }

    fabric.loadSVGFromURL(extendFigure.imageURL, (objects: any, options: any) => {

      objects.forEach((object) => {
        if ((object.fill.toLowerCase() === '#ff862a'
          || object.fill.toLowerCase() === '#f9ff00'
          || object.fill.toLowerCase() === '#c40000'
          || object.fill.toLowerCase() === '#004200')
          && extendFigure.color1.length > 2) {
          object.fill = extendFigure.color1;
        }
        if ((object.fill.toLowerCase() === '#3cb9ce'
          || object.fill.toLowerCase() === '#ffffa1'
          || object.fill.toLowerCase() === '#a9a9aa'
          || object.fill.toLowerCase() === '#f4f4f4'
          || object.fill.toLowerCase() === '#eac55c'
          || object.fill.toLowerCase() === '#000001')
          && extendFigure.color2.length > 2) {
          object.fill = extendFigure.color2;
        }
      });

      const svg = fabric.util.groupSVGElements(objects, options);
      svg.left = extendFigure.left;
      svg.top = extendFigure.top;
      svg.originX = 'center';
      svg.originY = 'center';
      svg.scale(10);

      svg.cloneAsImage(img => {
        const player = new fabric.Player(img._element);
        player.set({
          left: extendFigure.left,
          top: extendFigure.top,
          originX: 'center',
          originY: 'center',
          color1: extendFigure.color1,
          color2: extendFigure.color2
        });

        const text = new fabric.BaseText(extendFigure.text,
          {
            fontSize: AppConfig.Canvas.textFontSize,
            fontFamily: AppConfig.Canvas.fontFamily,
            originX: 'center',
            originY: 'bottom',
            top: extendFigure.top - player.getScaledHeight() / 1.4,
            left: extendFigure.left
          });

        const group = new fabric.CustomGroup([player, text, svg],
          {
            left: extendFigure.left,
            top: extendFigure.top,
            originX: 'center',
            originY: 'center',
            lockUniScaling: true,
            borderColor: AppConfig.Canvas.borderColor,
            cornerColor: AppConfig.Canvas.cornerColor,
            changeColor: extendFigure.changeColor
          });
        canvas.add(group);

        if (extendFigure.id) {
          group.id = extendFigure.id;
        }

        if (extendFigure.referenceId) {
          group.referenceId = extendFigure.referenceId;
        }

        if (extendFigure.referenceGroupId) {
          group.referenceGroupId = extendFigure.referenceGroupId;
        }

        group.actionType = extendFigure.actionType;
        group.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
        });

        group.actionSize = extendFigure.actionSize;
        group.imageURL = extendFigure.imageURL;
        group.angle = extendFigure.angle;
        group.actionName = extendFigure.text;
        group.isLocked = extendFigure.isLocked;
        group.color1 = extendFigure.color1;
        group.color2 = extendFigure.color2;

        const scale = extendFigure.actionSize * AppConfig.Canvas.iconSizeMultiple + AppConfig.Canvas.scaleObjectSizeStep + 0.005;

        group.set({
          scaleX: scale,
          scaleY: scale
        });

        if (extendFigure.flipX) {
          group.flipX = true;
        }

        if (extendFigure.selectAfterCreate) {
          canvas.setActiveObject(group);
        }

        if (callback) {
          callback();
        }
      });
    });
  }
}
