import { FolderItem } from 'app/core/services/build/model/folder.item';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { StorageService } from 'app/core/services/system/storage/storage/storage.service';
import { SyncSubjectService } from '../../../sync.subject.service';
import { Tactic } from 'app/core/services/tactic/model/tactic.model';
import { TacticItem } from 'app/core/services/build/model/tactic.item';
import { TacticItemToTacticAdapter } from 'app/core/services/tactic/adapters/item.tactic.adapter';
import { TacticService } from 'app/core/services/tactic/tactic.service';
import { TacticToTacticItemAdapter } from 'app/core/services/tactic/adapters/tactic.item.adapter';
import { TranslateService } from '@ngx-translate/core';
import { UploadService } from 'app/core/services/general/upload/upload.service';
import { UserStorageData } from 'app/core/services/system/storage/storage/model/user.storage.data.model';

/**
 * Provides sync process rely to tactics
 * @class SyncAppTacticService
 */
@Injectable({
  providedIn: 'root'
})
export class SyncAppTacticService {

  /**
   * Uses extra services to provide synchronization from and to server
   * @param {StorageService} storageService
   * @param {LoadingController} loadingController
   * @param {TranslateService} translateService
   * @param {SyncSubjectService} syncSubjectService
   * @param {UploadService} uploadService
   * @param {TacticService} tacticService
   * @param {TacticItemToTacticAdapter} tacticItemToTacticAdapter
   * @param {TacticToTacticItemAdapter} tacticToTacticItemAdapter
   */
  constructor(
    protected storageService: StorageService,
    protected loadingController: LoadingController,
    protected translateService: TranslateService,
    protected syncSubjectService: SyncSubjectService,
    private uploadService: UploadService,
    private tacticService: TacticService,
    private tacticItemToTacticAdapter: TacticItemToTacticAdapter,
    private tacticToTacticItemAdapter: TacticToTacticItemAdapter) { }

  /**
   * Updating/Adding different version of tactics from backend to the app
   */
  public async syncTacticsCloudToApp(): Promise<void> {
    const existingLocalTactics = [];
    let remoteTactics: Tactic[];

    const userStorageData = await this.storageService.getUserDataFromStorage();

    this.getAllLocalExistingTactics(userStorageData)
      .forEach((tacticItem: TacticItem) => {
        existingLocalTactics.push([tacticItem.uid, tacticItem.modified]);
      });

    if (existingLocalTactics.length === 0) {
      remoteTactics = await this.tacticService.getAllTactic().toPromise();
    } else {
      remoteTactics = await this.tacticService.getTactics(existingLocalTactics).toPromise();
    }

    for (const remoteTactic of remoteTactics) {
      const targetLocalTactic = userStorageData.tacticsData.find((localTactic => localTactic.uid === remoteTactic.uid));

      if (!targetLocalTactic) {
        const tempTactic = await this.setResoursesToTempTactic(remoteTactic);
        const localTactic = await this.tacticToTacticItemAdapter.adapt(remoteTactic);

        localTactic.linkData = tempTactic.linkData;
        localTactic.thumbData = tempTactic.thumbData;
        userStorageData.tacticsData.push(localTactic);

      } else {
        const tempTactic = await this.setResoursesToTempTactic(remoteTactic);

        targetLocalTactic.linkData = tempTactic.linkData;
        targetLocalTactic.thumbData = tempTactic.thumbData;
        targetLocalTactic.modified = remoteTactic.modifiedUtc;
        targetLocalTactic.name = remoteTactic.name;
      }
    }

    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Updating/Adding different version of tactics from app to the backend
   */
  public async syncTacticsAppToCloud(): Promise<void> {
    const existingLocalTactics = [];
    let remoteTactics: Tactic[];

    const userStorageData = await this.storageService.getUserDataFromStorage();

    this.getAllLocalExistingTactics(userStorageData).forEach((localTacticItem: TacticItem) => {
      existingLocalTactics.push([localTacticItem.uid, localTacticItem.modified]);
    });

    if (existingLocalTactics.length === 0) {
      remoteTactics = await this.tacticService.getAllTactic().toPromise();
    } else {
      remoteTactics = await this.tacticService.getTactics(existingLocalTactics).toPromise();
    }

    for (const remoteTactic of remoteTactics) {
      const localTactic = userStorageData.tacticsData.find((tactic => tactic.uid === remoteTactic.uid));
      if (localTactic) {
        const tactic = this.tacticItemToTacticAdapter.adapt(localTactic);
        await this.tacticService.updateTactic(tactic);
      }
    }

    for (const localTacticItem of this.getAllLocalNotSyncedTactics(userStorageData)) {
      let parentFolder = userStorageData.foldersData.find((folderItem: FolderItem) => folderItem.uid === localTacticItem.folderUid);

      if (!parentFolder) {
        parentFolder = userStorageData.foldersData.find((folderItem: FolderItem) => folderItem.syncId === localTacticItem.parentSyncId);
      }

      localTacticItem.folderUid = parentFolder && parentFolder.uid;
      const tactic = this.tacticItemToTacticAdapter.adapt(localTacticItem);
      const { uid, modifiedUtc } = await this.tacticService.addTactic(tactic);

      localTacticItem.syncId = 0;
      localTacticItem.parentSyncId = 0;
      localTacticItem.uid = uid;
      localTacticItem.modified = modifiedUtc;
    }

    await this.storageService.setUserDataToStorage(userStorageData);
  }

  /**
   * Returns all not tactics that have uid
   * @param {UserStorageData} userStorageData
   * @returns {TacticItem[]}
   */
  private getAllLocalExistingTactics(userStorageData: UserStorageData): TacticItem[] {
    return userStorageData.tacticsData.filter((tacticItem: TacticItem) => tacticItem.uid > 0);
  }

  /**
   * Stores temp tactic data to the storage
   * @returns {Promise<TacticItem>}
   */
  private async setResoursesToTempTactic(remoteTactic: Tactic): Promise<TacticItem> {
    const userStorageData = await this.storageService.getUserDataFromStorage();

    const tempTactic = new TacticItem();
    tempTactic.linkData = await this.uploadService.getFileToBase64(remoteTactic.link);
    tempTactic.thumbData = await this.uploadService.getFileToBase64(remoteTactic.linkThumb);
    tempTactic.link = remoteTactic.link;
    tempTactic.linkThumb = remoteTactic.linkThumb;

    userStorageData.tempTactic = tempTactic;

    await this.storageService.setUserDataToStorage(userStorageData);

    return tempTactic;
  }

  /**
   * Returns all not synced tactics (without uid)
   * @param {UserStorageData} userStorageData
   * @returns {TacticItem[]}
   */
  private getAllLocalNotSyncedTactics(userStorageData: UserStorageData): TacticItem[] {
    return userStorageData.tacticsData.filter((tacticItem: TacticItem) => tacticItem.syncId > 0 && !tacticItem.uid);
  }
}
