import { ModuleWithProviders, NgModule } from '@angular/core';

import { SyncSubjectService } from 'app/core/services/sync/sync.subject.service';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [
  ],
})
export class SyncModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SyncModule,
      providers: [SyncSubjectService]
    };
  }
}
